import * as turf from "@turf/turf"
import { Position, round } from "@turf/turf"
import { NetworkGeometry } from "../../specs/Networks"
import { Feature, Point } from "geojson"
import { featureCollection } from "@turf/helpers"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { createPointFeature } from "./FeaturesUtils"
import { GEOJSON_COORD_PRECISION } from "../../constants/config"

export class DynamicStops {
  private draw!: MapboxDraw
  private addedFeatures: { [key in string]: Feature<Point>[] } = {}

  constructor(draw: MapboxDraw) {
    this.draw = draw
  }

  addStopsOnNetwork(
    features: NetworkGeometry[],
    distance: number,
    selectedNetworkType
  ) {
    if (features.length == 0) {
      return
    }
    this.cleanAddedStops(features)
    features.forEach((feature) => {
      const coords = feature.geometry.coordinates as Position[]
      const pointsOnLine = this.calculatePointsOnLine(coords, distance)
      this.addPointsToMap(String(feature.id), pointsOnLine, selectedNetworkType)
    })
  }

  cleanAddedStops(features: NetworkGeometry[]) {
    features.forEach((feature: any) => {
      this.draw.delete(
        (this.addedFeatures[feature.id] || []).map((d: any) => d.id)
      )
      this.addedFeatures[feature.id] = []
    })
  }

  saveStops(features: NetworkGeometry[]) {
    features.forEach((feature: any) => {
      delete this.addedFeatures[feature.id]
    })
  }

  private calculatePointsOnLine(
    lineCoordinates: turf.helpers.Position[],
    distance: number
  ) {
    const lineString = turf.lineString(lineCoordinates)
    const lineDistance = turf.length(lineString, { units: "meters" })
    const { approxDistance, numberOfStops } = this.calculateApproxDistance(
      lineDistance,
      distance
    )

    const pointsOnLine: [number, number][] = []
    for (let i = 1; i <= numberOfStops; i++) {
      if (approxDistance > 0) {
        const point = turf.along(lineString, approxDistance * i, {
          units: "meters",
        })
        pointsOnLine.push(point.geometry.coordinates as [number, number])
      }
    }

    return pointsOnLine
  }

  private addPointsToMap(
    featureId: string,
    coords: [number, number][],
    selectedNetworkType
  ) {
    const features: Feature<Point>[] = []
    coords.forEach((coord) => {
      const feature = createPointFeature(
        [
          round(coord[0], GEOJSON_COORD_PRECISION),
          round(coord[1], GEOJSON_COORD_PRECISION),
        ],
        this.draw.getAll().features as NetworkGeometry[],
        selectedNetworkType
      )

      if (feature) {
        features.push(feature as Feature<Point>)
      }
    })
    this.draw.add(featureCollection(features))
    const existingPoints = this.addedFeatures[featureId] || []
    this.addedFeatures[featureId] = existingPoints.concat(features)
  }

  private calculateApproxDistance(
    lineDistance: number,
    stopsDistance: number
  ): {
    approxDistance: number
    numberOfStops: number
  } {
    const numberOfStops = Math.floor(lineDistance / stopsDistance)
    const delta = Math.floor(lineDistance % stopsDistance)
    const negativeDelta = Math.abs(
      lineDistance - stopsDistance * (numberOfStops + 1)
    )
    const approxDistance =
      delta > negativeDelta
        ? stopsDistance - negativeDelta / numberOfStops
        : stopsDistance + delta / numberOfStops

    return { approxDistance, numberOfStops }
  }

  getStopsListForFeatures(features: NetworkGeometry[]) {
    let addedFeatures: Feature<Point>[] = []
    features.forEach((feature: any) => {
      addedFeatures = addedFeatures.concat(this.addedFeatures[feature.id] || [])
    })
    return addedFeatures
  }
}
