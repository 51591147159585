export function isUndo(e) {
  return e.ctrlKey && e.key === "z"
}

export function isRedo(e) {
  return e.ctrlKey && e.key === "y"
}

export function isDelete(e) {
  return e.keyCode == 46 || e.keyCode == 8
}

export function isEsc(e) {
  return e.keyCode == 27
}

export function isEnter(e) {
  return e.keyCode == 13
}

export function undo() {
  const undoButton: any = document.getElementsByClassName("undo-button")[0]
  if (undoButton) {
    undoButton.click()
  }
}

export function redo() {
  const undoButton: any = document.getElementsByClassName("redo-button")[0]
  if (undoButton) {
    undoButton.click()
  }
}

export function extractUid(uid: string) {
  const split = uid.split("-")
  return [split[0], split[1]].join("-")
}
