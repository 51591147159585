import React, { Component } from "react"
import intl from "react-intl-universal"

class NotFoundPage extends Component {
  render() {
    return (
      <div className="mx-auto p-4 bg-neutral-800 text-white text-center justify-center items-center h-screen">
        <div className="max-w-lg mx-auto p-8 text-white">
          <img
            src="/404.png"
            alt="404 Not Found"
            className="mx-auto"
            style={{ maxWidth: "300px" }}
          />
          <h1 className="text-4xl font-bold">{intl.get("page.heading")}</h1>
          <p className="text-white-600">{intl.get("page.warning_msg")}</p>
        </div>
      </div>
    )
  }
}

export default NotFoundPage
