import { Route } from "react-router-dom"
import React, { ReactElement } from "react"
import Login from "../pages/Login"
import SignUp from "../pages/SignUp"
import NotFoundPage from "../pages/NotFoundPage"
import Landing from "../pages/Landing"
import { Paths } from "./paths"

export const PublicRoutes: ReactElement = (
  <>
    <Route path={Paths.root} element={<Landing />}></Route>
    <Route path={Paths.login} element={<Login />}></Route>
    <Route path={Paths.signUp} element={<SignUp />}></Route>
    <Route path={Paths.notFound} element={<NotFoundPage />}></Route>
  </>
)
