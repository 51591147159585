export function clone(obj) {
  return Object.create(
    Object.getPrototypeOf(obj),
    Object.getOwnPropertyDescriptors(obj)
  )
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
