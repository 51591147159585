import MapboxDraw, { DrawFeature } from "@mapbox/mapbox-gl-draw"
import {
  addPointTovertices,
  createSnapList,
  snap,
} from "../../utils/mapbox/Snapping"
import {
  clone,
  coordAll,
  lineSplit,
  lineString,
  point,
  Position,
  round,
} from "@turf/turf"
import {
  CRUDStack,
  ExtendExistingModeOptions,
  ExtendExistingModeState,
  IExtendExistingMode,
  MapEvents,
  MapEventStack,
  SelectStack,
} from "../../specs/Mapbox"
import { GeoJSON } from "geojson"
import { NetworkGeometry } from "../../specs/Networks"
import {
  GEOJSON_COORD_PRECISION,
  MAPBOX_ACCESS_TOKEN,
} from "../../constants/config"
import axios from "axios"
import { isEsc, isRedo, isUndo, redo, undo } from "../../utils/mapbox/Commons"
import {
  allExistingFeatures,
  calculateTime,
  checkDisconnectedFeatures,
  createLineStringFeature,
  createPointFeature,
  getAllLineStringFeaturesEditMode,
  getDisconnectedFeatures,
  routeSelectionValue,
} from "../../utils/mapbox/FeaturesUtils"
import {
  addModeDisplyFeatures,
  clickActiveFeature,
  dragFeature,
  dragVertex,
  editModeDisplyFeatures,
  editModeRouteSelection,
  insideMouseMove,
  newLine,
  onFeature,
  onVertex,
  openContextMenu,
  popup,
  stopDragging,
} from "./CommonOperationMode"

const { doubleClickZoom, createVertex, createSupplementaryPoints } =
  MapboxDraw.lib
const Constants = MapboxDraw.constants
const CommonSelectors = MapboxDraw.lib.CommonSelectors
const { isActiveFeature, isInactiveFeature, isOfMetaType, noTarget } =
  CommonSelectors
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT)
const isVertex = isOfMetaType(Constants.meta.VERTEX)
const deletePaths: string[] = []
let drawnFeatures: NetworkGeometry[] = []
let draw: MapboxDraw
let endPoints: NetworkGeometry[] = []
let newlyDrawnFeatures: NetworkGeometry[] = []
export const newlyDrawnLineFeatures: string[] = []
let allFeatures: NetworkGeometry[] = []
let isDrawingInprogress = false
let getAllExistingLineStringFeatures: NetworkGeometry[] = []
export function updateDrawnFeatures() {
  newlyDrawnFeatures = draw
    .getAll()
    .features.filter((feature) => feature.geometry.type === "Point")
    .map((d) => d as NetworkGeometry)
}

const ExtendExistingMode: IExtendExistingMode = {
  async fireUpdate(
    state: ExtendExistingModeState,
    oldState?: NetworkGeometry[],
    newState?: NetworkGeometry[]
  ) {
    const feature = state.featureOrg as NetworkGeometry
    if (feature.geometry.type === "LineString") {
      const allFeatures = getAllLineStringFeaturesEditMode
        .map((d) => d as NetworkGeometry)
        .filter((f) => f.properties.line !== feature.properties.line)
      await checkDisconnectedFeatures(
        draw,
        allFeatures,
        state.actionMode,
        state.selectedNetworkType,
        this.map
      )
    }
    if (!oldState) {
      oldState = [state.featureOrg as NetworkGeometry]
    }
    if (!newState) {
      newState = this.getSelected().map((f) => f.toGeoJSON() as NetworkGeometry)
    } else {
      const getSelectedFeatures = this.getSelected().map(
        (f) => f.toGeoJSON() as NetworkGeometry
      )
      getSelectedFeatures.forEach((f) => {
        if (newState && f.id === newState[0].id) {
          newState[0].properties = f.properties
        }
      })
    }
    const stackItem: CRUDStack = {
      stackState: [
        {
          action: "update",
          oldState: oldState,
          newState: newState,
        },
      ],
      operation: "updated_coordinates",
      mode: "extend_existing_mode",
    }
    this.map.fire(MapEvents.CRUD, {
      stackItem,
    })
  },
  onSetup(options: ExtendExistingModeOptions): ExtendExistingModeState {
    if (options.init) {
      draw = this["_ctx"]["api"]
      drawnFeatures = draw
        .getAll()
        .features.filter((feature) => feature.geometry.type === "Point")
        .map((d) => d as NetworkGeometry)
    }
    const line = newLine.call(this)

    const selectedFeatures = this.getSelected()
    this.clearSelectedFeatures()
    doubleClickZoom.disable(this)

    const featureId = options.featureId
    let feature: DrawFeature | undefined = undefined
    const featureIds: string[] = []
    if (featureId) {
      feature = this.getFeature(featureId)
      featureIds.push(featureId)
    }

    newlyDrawnFeatures = draw
      .getAll()
      .features.filter((feature) => feature.geometry.type === "Point")
      .map((d) => d as NetworkGeometry)
    getAllExistingLineStringFeatures = draw
      .getAll()
      .features.filter((feature) => feature.geometry.type === "LineString")
      .map((d) => d as NetworkGeometry)

    allFeatures = drawnFeatures.concat(newlyDrawnFeatures)

    const { snapList, vertices } = createSnapList(this.map, allFeatures, line)
    const state: ExtendExistingModeState = {
      map: this.map,
      line,
      currentVertexPosition: 0,
      vertices,
      snapList,
      selectedFeatures,
      direction: "forward", // expected by DrawLineString
      startCords: [],
      endCords: [],
      completeLineCoordinates: [],
      options: {
        ...this["_ctx"]["options"],
        guides: false,
        snap: true,
        snapOptions: {
          snapPx: 15,
          snapToMidPoints: false,
          snapVertexPriorityDistance: 0,
        },
      },
      snappedLat: 0,
      snappedLng: 0,
      lastVertex: [],
      onCancel: () => {},
      selectedNetworkType: options.selectedNetworkType,
      network: options.selectedNetworkType.baselineProperties.Network,
      stop: options.selectedNetworkType.baselineProperties.Stops,
      isRouteSelected: false,
      actionMode: options.actionMode,
      feature: feature,
      featureIds: featureIds,
      featureOrg: feature ? feature.toGeoJSON() : undefined,
      dragMoving: false,
      canDragMove: false,
      selectedCoordPaths: [],
      featuresAtPoint: [],
      routeFeatures: [],
      routeId: undefined,
    }

    const moveendCallback = () => {
      const { snapList, vertices } = createSnapList(this.map, allFeatures, line)
      state.vertices = vertices
      state.snapList = snapList
    }

    // for removing listener later on close
    state["moveendCallback"] = moveendCallback

    const optionsChangedCallBAck = (options) => {
      state.options = options
    }
    // for removing listener later on close
    state["optionsChangedCallBAck"] = optionsChangedCallBAck

    this.map.on("moveend", moveendCallback)
    this.map.on("draw.snap.options_changed", optionsChangedCallBAck)
    return state
  },

  onClick(state: ExtendExistingModeState, e: any) {
    const isRightClick = e.originalEvent.button === 2
    const isLeftClick = e.originalEvent.button === 0
    const isShiftClick = CommonSelectors.isShiftDown(e)
    const { lng, lat } = snap(state, e)
    const isMatching = endPoints.some((point) => {
      return (
        point[0] === round(lng, GEOJSON_COORD_PRECISION) &&
        point[1] === round(lat, GEOJSON_COORD_PRECISION)
      )
    })
    if (state.currentVertexPosition == 0) {
      state.line = newLine.call(this)
      state.completeLineCoordinates = []
    }

    if (e.featureTarget == undefined && !isMatching && !isDrawingInprogress) {
      popup.remove()
      state.feature = undefined
      state.featureIds = []
      this.clearSelectedFeatures()
      isDrawingInprogress = false
      state.line = newLine.call(this)
      state.currentVertexPosition = 0
      state.completeLineCoordinates = []
      if (state.isRouteSelected) {
        const stackItem: SelectStack = {
          mode: "extend_existing_mode",
          operation: "route_selection",
          features: draw
            .getAll()
            .features.filter(
              (feature) =>
                feature.geometry.type === "LineString" &&
                feature.properties?.line === state.routeId &&
                feature.properties?.title !== "Incomplete"
            )
            .map((d) => d as NetworkGeometry),
          properties: {
            routeId: state.routeId,
          },
        }
        this.map.fire(MapEvents.ROUTE_SELECTED, {
          stackItem,
        })
        this.getEndPoints()
      }

      return
    }

    const featureId = e.featureTarget ? e.featureTarget.properties.id : ""
    state.featureId = featureId

    let feature = this.getFeature(featureId)
    if (isLeftClick) {
      if (!state.isRouteSelected && !routeSelectionValue) {
        if (feature.type === "Point") {
          const features = this.featuresAt(e, [0, 0, 0, 0], "click").filter(
            (f: any) => f.geometry.type === "LineString"
          )
          if (features.length == 0) {
            return
          }
          feature = this.getFeature(features[0].properties!.id.toString())
        }
        const routeDetails = editModeRouteSelection.call(
          this,
          state,
          e,
          feature,
          draw
        )
        if (routeDetails) {
          state.routeId = routeDetails![1]
          state.routeFeatures = routeDetails![0] as NetworkGeometry[]
          state.line = newLine.call(this)
          state.currentVertexPosition = 0
          state.completeLineCoordinates = []
          this.getEndPoints()
        }
      } else if (e.originalEvent.detail === 2 && isDrawingInprogress) {
        if (!state.isRouteSelected) {
          state.isRouteSelected = true
        }
        const routeFeatures = draw
          .getAll()
          .features.filter(
            (f) =>
              f.geometry.type === "LineString" &&
              f.properties!.title !== "Incomplete"
          )
        state.routeId = routeFeatures[0].properties!.line
        state.routeFeatures = routeFeatures as NetworkGeometry[]

        const f = state.line
        const createdFeatures: NetworkGeometry[] = []
        state.completeLineCoordinates.push([
          f.coordinates[f.coordinates.length - 1],
        ])
        const drawnFeature = this.getFeature(f.id.toString())
        if (drawnFeature === undefined) {
          /// Call `onCancel` if exists.
          if (typeof state.onCancel === "function") state.onCancel()
          return
        }
        /// remove last added coordinate
        else {
          f.removeCoordinate(`${state.currentVertexPosition}`)
        }

        if (f.isValid()) {
          deletePaths.push(f.id.toString())
          for (const id of deletePaths) {
            this.deleteFeature(id, { silent: true })
          }

          const coordsCollection: Position[] = []
          for (const coords of state.completeLineCoordinates) {
            for (const coord of coords) {
              coordsCollection.push(coord)
            }
          }

          const completePath = createLineStringFeature(
            coordsCollection,
            state.selectedNetworkType
          )
          isDrawingInprogress = false
          const pathId = draw.add(completePath)
          const addedPath = draw.get(pathId.toString())
          createdFeatures.push(addedPath as NetworkGeometry)
          if (state.selectedNetworkType.name == "bike") {
            // drawnFeatures.push(addedPath as NetworkGeometry)
            newlyDrawnFeatures = draw
              .getAll()
              .features.filter(
                (feature) => feature.geometry.type === "LineString"
              )
              .map((d) => d as NetworkGeometry)
          } else {
            const endPoint = createPointFeature(
              coordsCollection[coordsCollection.length - 1],
              allFeatures,
              state.selectedNetworkType,
              state.actionMode
            )

            if (endPoint) {
              createdFeatures.push(endPoint)
              // drawnFeatures.push(endPoint)
              draw.add(endPoint)
              updateDrawnFeatures()
            }

            newlyDrawnFeatures = draw
              .getAll()
              .features.filter((feature) => feature.geometry.type === "Point")
              .map((d) => d as NetworkGeometry)
          }

          createdFeatures.forEach((f) => {
            if (f.geometry.type === "LineString") {
              f.properties.line = state.routeId
              f.properties.name = state.routeFeatures[0].properties.name
              f.properties.frequency_min =
                state.routeFeatures[0].properties.frequency_min
              newlyDrawnLineFeatures.push(f.id as string)
            }
          })

          const stackItem: CRUDStack = {
            stackState: [
              {
                action: "create",
                oldState: [],
                newState: createdFeatures,
              },
            ],
            operation: "added_network_with_stop",
            mode: "extend_existing_mode",
          }
          this.map.fire(MapEvents.CRUD, { stackItem })

          if (addedPath) {
            if (state.selectedNetworkType.name !== "bike") {
              const routeId = state.selectedNetworkType.getPropertyFromFeature(
                addedPath as NetworkGeometry,
                "routeId"
              )
              if (!state.routeId) {
                return
              }

              //this.setSelected(addedPath.id as string)
              const stackItem: SelectStack = {
                mode: "extend_existing_mode",
                operation: "route_selection",
                features: [addedPath as NetworkGeometry],
                properties: { routeId: routeId },
              }
              this.setSelected(completePath.id as string)
              state.featureIds = [completePath.id as string]
              state.feature = this.getFeature(completePath.id!.toString())
              setTimeout(() => {
                this.map.fire(MapEvents.ROUTE_SELECTED, {
                  stackItem,
                })
              }, 100)
            }
          }

          const tooltipbox: HTMLElement =
            document.querySelector(".mouseTooltip")!
          tooltipbox.style.display = "none"
        }
        state.line = newLine.call(this)
        state.currentVertexPosition = 0
        state.completeLineCoordinates = []
        draw
          .getAll()
          .features.filter((f) => f.geometry.type === "Point")
          .map((f) => f as NetworkGeometry)
          .forEach((stop) => {
            if ("is_endPoint" in stop.properties) {
              draw.setFeatureProperty(stop.id as string, "is_endPoint", "false")
            }
          })

        if (state.selectedNetworkType.name !== "bike") {
          getDisconnectedFeatures(
            draw,
            getAllExistingLineStringFeatures,
            state.selectedNetworkType,
            state.actionMode,
            this.map
          )
        }
        this.getEndPoints()
      } else if (isActiveFeature(e) && !isDrawingInprogress) {
        clickActiveFeature.call(this, e, isShiftClick, state, draw)
      } else if (isShiftClick) {
        // Multiple feature selection
        let isExistingFeatureSameId = false
        allExistingFeatures.forEach((existingFeature) => {
          if (existingFeature.id == feature.id) {
            isExistingFeatureSameId = true
          }
        })
        if (!isExistingFeatureSameId) {
          state.featureIds.push(featureId)
          this.select(featureId)
          const stackItem: SelectStack = {
            mode: "extend_existing_mode",
            operation: "selection_change",
            features: this.getSelected().map(
              (d) => d.toGeoJSON() as NetworkGeometry
            ),
            properties: {},
          }

          this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
        }
      } else {
        if (
          e.featureTarget &&
          e.featureTarget.source === "mapbox-gl-draw-cold" &&
          !isDrawingInprogress &&
          ((e.featureTarget._geometry.type == "Point" && isShiftClick) ||
            e.featureTarget._geometry.type == "LineString")
        ) {
          let isExistingFeatureSameId = false
          allExistingFeatures.forEach((existingFeature) => {
            if (existingFeature.id == feature.id) {
              isExistingFeatureSameId = true
            }
          })
          if (isExistingFeatureSameId) {
            return
          }
          openContextMenu.call(this, e, state, draw)

          if (!isExistingFeatureSameId) {
            state.feature = feature
            state.featureOrg = feature.toGeoJSON()
            state.featureIds = [featureId]
            this.setSelected(featureId)
            const stackItem: SelectStack = {
              mode: "extend_existing_mode",
              operation: "selection_change",
              features: this.getSelected().map(
                (d) => d.toGeoJSON() as NetworkGeometry
              ),
              properties: {},
            }

            this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
          }
        } else {
          if (state.currentVertexPosition == 0) {
            if (isMatching) {
              state.startCords = [lng, lat]
              this.drawLine(state, isLeftClick)
            }
          } else {
            state.startCords = [e.lngLat.lng, e.lngLat.lat]
            this.drawLine(state, isLeftClick)
          }
        }
      }
    } else if (isRightClick && state.isRouteSelected) {
      clickActiveFeature.call(this, e, isShiftClick, state, draw)
    }

    newlyDrawnFeatures = draw
      .getAll()
      .features.filter((feature) => feature.geometry.type === "Point")
      .map((d) => d as NetworkGeometry)

    allFeatures = drawnFeatures.concat(newlyDrawnFeatures)
    const { snapList, vertices } = createSnapList(
      this.map,
      allFeatures,
      state.line
    )
    state.vertices = vertices
    state.snapList = snapList
  },

  onMouseMove(state: ExtendExistingModeState, e: any) {
    // On mousemove that is not a drag, stop vertex movement.
    insideMouseMove.call(this, state, e, isDrawingInprogress)
  },

  drawLine(state: ExtendExistingModeState, isLeftClick: boolean) {
    state.feature = undefined
    state.featureIds = []
    this.clearSelectedFeatures()
    isDrawingInprogress = true
    // We save some processing by rounding on click, not mousemove
    const lng = state.snappedLng
    const lat = state.snappedLat

    // End the drawing if this click is on the previous position
    // Note: not bothering with 'direction'
    if (state.currentVertexPosition > 0) {
      state.lastVertex = state.line.coordinates[state.currentVertexPosition - 1]

      if (isLeftClick) {
        const coords = [state.lastVertex]
        const geojson: NetworkGeometry = createLineStringFeature(coords)
        draw.add(geojson)
        deletePaths.push(geojson.id!.toString())
        state.completeLineCoordinates.push(coords)
      }
    }

    addPointTovertices(state.map, state.vertices, { lng, lat }, false)

    state.line.updateCoordinate(
      state.currentVertexPosition.toString(),
      lng,
      lat
    )
    state.currentVertexPosition++
    state.line.updateCoordinate(
      state.currentVertexPosition.toString(),
      lng,
      lat
    )
  },

  onStop(_: ExtendExistingModeState) {},

  onKeyUp(state: ExtendExistingModeState, e: any) {
    if (isUndo(e)) {
      if (state.currentVertexPosition > 0) {
        state.line.removeCoordinate(state.currentVertexPosition)
        state.currentVertexPosition--
        state.completeLineCoordinates.pop()
        state.startCords =
          state.line.coordinates[state.line.coordinates.length - 2]
      } else {
        undo()
      }
    } else if (isRedo(e)) {
      if (state.currentVertexPosition == 0) {
        redo()
      }
    } else if (isEsc(e)) {
      draw.delete(state.line.id.toString())
      state.currentVertexPosition = 0
      state.completeLineCoordinates = []
      state.line = newLine.call(this)
      if (isDrawingInprogress) {
        isDrawingInprogress = false
      } else if (this.getSelectedIds().length > 0) {
        state.featureId = undefined
        state.featureIds = []
        this.clearSelectedFeatures()
        const stackItem: SelectStack = {
          mode: "extend_existing_mode",
          operation: "route_selection",
          features: draw
            .getAll()
            .features.filter(
              (feature) =>
                feature.geometry.type === "LineString" &&
                feature.properties?.line === state.routeId &&
                feature.properties?.title !== "Incomplete"
            )
            .map((d) => d as NetworkGeometry),
          properties: {
            routeId: state.routeId,
          },
        }
        this.map.fire(MapEvents.ROUTE_SELECTED, {
          stackItem,
        })
      } else {
        this.map.fire(MapEvents.ROUTE_DESELECTED, {})
        state.isRouteSelected = false
      }
    }
  },

  toDisplayFeatures(
    state: ExtendExistingModeState,
    geojson: any,
    display: (geojson: GeoJSON) => void
  ) {
    if (isDrawingInprogress) {
      addModeDisplyFeatures(state, geojson, display)
    } else {
      editModeDisplyFeatures(state, geojson, display)
      this.fireActionable(state)
    }
  },

  snapLineToRoad(state: ExtendExistingModeState, e: any) {
    if (state.startCords.length === 0) {
      state.startCords = [e.lngLat.lng, e.lngLat.lat]
    } else {
      state.endCords = [e.lngLat.lng, e.lngLat.lat]
      this.shortestPath(state, () => {
        state.startCords = [e.lngLat.lng, e.lngLat.lat]
        state.endCords = [0, 0]
      })
    }
  },

  shortestPath(state: ExtendExistingModeState, callback: () => void) {
    const start = state.startCords
    const end = state.endCords
    this.getShortestPath(start, end)
      .then((jsonData) => {
        if (jsonData.code === "Ok") {
          const routes = jsonData.routes
          if (routes && routes.length > 0) {
            let shortestDistance = routes[0].distance
            let shortestRoute = routes[0].geometry.coordinates
            for (const route of routes) {
              if (route.distance <= shortestDistance) {
                shortestDistance = route.distance
                shortestRoute = route.geometry.coordinates
              }
            }

            shortestRoute[0] = start
            const geojson = createLineStringFeature(shortestRoute)
            state.completeLineCoordinates.push(shortestRoute)
            draw.add(geojson)
            const pathId = geojson.id!.toString()
            const stackItem: MapEventStack = {
              stackState: [
                {
                  action: "create",
                  oldState: [],
                  newState: [geojson],
                },
              ],
              operation: "added_shortest_path",
              mode: "extend_existing_mode",
            }
            this.map.fire(MapEvents.CRUD, { stackItem })
            deletePaths.push(pathId)

            this.drawLine(state, false)
          } else {
            alert("No routes found.")
          }
        }
      })
      .catch((error) => {
        console.error("Error in getting shortest path:", error)
      })
    callback()
  },

  async getShortestPath(start: Position, end: Position): Promise<any> {
    const apiUrl =
      `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?alternatives=true&annotations=distance%2Cduration%2Cspeed&geometries=geojson&language=en&overview=simplified&steps=true&access_token=` +
      MAPBOX_ACCESS_TOKEN

    try {
      const response = await axios.get(apiUrl)
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  },

  fireActionable(_: ExtendExistingModeState) {},

  onDrag(state: ExtendExistingModeState, e: any) {
    const tooltipbox: HTMLElement = document.querySelector(".mouseTooltip")!
    tooltipbox.style.display = "none"
    if (!state.canDragMove) return
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation!.lng,
      lat: e.lngLat.lat - state.dragMoveLocation!.lat,
    }
    if (state.selectedCoordPaths.length > 0) {
      dragVertex.call(this, state, e, delta)
    } else {
      dragFeature.call(this, state, e, delta, draw)
    }

    state.dragMoveLocation = e.lngLat
  },

  onMouseUp(state: ExtendExistingModeState, _: MapboxDraw.MapMouseEvent) {
    this.handleDraw(state)
  },

  onTouchEnd(state: ExtendExistingModeState, _: MapboxDraw.MapTouchEvent) {
    this.handleDraw(state)
  },

  handleDraw(state: ExtendExistingModeState) {
    if (state.dragMoving) {
      const feat = state.feature?.toGeoJSON() as NetworkGeometry
      if (Object.prototype.hasOwnProperty.call(feat.properties, "speed")) {
        const time = calculateTime(feat.geometry, feat.properties)
        state.feature?.setProperty("time_min", time)
      }
      const oldState = [state.featureOrg as NetworkGeometry]
      state.feature?.setProperty("has_improvement", true)
      const newState = [state.feature?.toGeoJSON() as NetworkGeometry]
      if (state.feature?.type === "Point") {
        const position = state.feature.getCoordinate()
        if (state.featuresAtPoint.length == 2) {
          const fc: any = {
            type: "FeatureCollection",
            features: state.featuresAtPoint.map((f) => f.feature),
          }
          const lineSplitFC = lineSplit(
            lineString(coordAll(fc)),
            point(position)
          )
          if (lineSplitFC.features.length == 2) {
            state.featuresAtPoint.forEach((f, idx) => {
              oldState.push(clone(f.feature))
              f.feature.geometry.coordinates =
                lineSplitFC.features[idx].geometry.coordinates
              newState.push(f.feature)
            })
          }
        }
      }
      draw.add({
        type: "FeatureCollection",
        features: newState,
      })
      this.fireUpdate(state, oldState, newState)
    }
    stopDragging.call(this, state)
  },

  onMouseOut(state: ExtendExistingModeState, _: MapboxDraw.MapMouseEvent) {
    // As soon as you mouse leaves the canvas, update the feature
    if (state.dragMoving) this.fireUpdate(state)

    // Skip render
    return true
  },

  onTouchStart(state: ExtendExistingModeState, e: MapboxDraw.MapTouchEvent) {
    this.startDraw(state, e)
  },

  onMouseDown(state: ExtendExistingModeState, e: any) {
    this.startDraw(state, e)
  },

  startDraw(state: ExtendExistingModeState, e: any) {
    if (isVertex(e)) return onVertex.call(this, state, e)
    if (isActiveFeature(e)) return onFeature.call(this, state, e)
  },

  getEndPoints() {
    const firstAndLastCoordinates: any[] = []
    endPoints = []
    draw
      .getAll()
      .features.filter(
        (f) =>
          f.geometry.type === "LineString" &&
          f.properties?.title !== "Incomplete"
      )
      .map((f) => f as NetworkGeometry)
      .forEach((d) => {
        const coordinates = d.geometry.coordinates
        const firstCoordinate = coordinates[0]
        const lastCoordinate = coordinates[coordinates.length - 1]
        firstAndLastCoordinates.push([firstCoordinate, lastCoordinate])
      })

    const flattenedArray = firstAndLastCoordinates.flat()

    flattenedArray.forEach((d) => {
      d[0] = round(d[0], GEOJSON_COORD_PRECISION)
      d[1] = round(d[1], GEOJSON_COORD_PRECISION)
    })

    const coordCounts = {}

    flattenedArray.forEach((coord) => {
      const key = JSON.stringify(coord)
      coordCounts[key] = (coordCounts[key] || 0) + 1
    })

    endPoints = flattenedArray.filter((coord, index) => {
      return (
        flattenedArray.findIndex(
          (c, i) => i !== index && c[0] === coord[0] && c[1] === coord[1]
        ) === -1
      )
    })

    draw.getAll().features.forEach((feature) => {
      if (feature.geometry.type === "Point") {
        const point = feature as NetworkGeometry
        const isEndPoint = endPoints.some(
          (coord) =>
            coord[0] === point.geometry.coordinates[0] &&
            coord[1] === point.geometry.coordinates[1]
        )
        if (isEndPoint) {
          draw.setFeatureProperty(feature.id as string, "is_endPoint", "true")
        } else {
          draw.setFeatureProperty(feature.id as string, "is_endPoint", "false")
        }
      }
    })
  },
}

export default ExtendExistingMode
