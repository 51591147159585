import request from "./api"

export function login(username: string, password: string) {
  return request.post("/login", { username, password })
}

export function validateSignUpToken(inviteToken: { inviteToken: string }) {
  return request.post("/invite/validate-token", inviteToken)
}

export function createUserAndUpdateInviteStatus(userSignUp: UserSignup) {
  return request.post("/users/create", userSignUp)
}

export function getUsers() {
  return request.get("/users/", {
    headers: {
      "x-show-loader": true,
    },
  })
}

export function getInvites() {
  return request.get("/invite/", {
    headers: {
      "x-show-loader": true,
    },
  })
}

export function generateInviteToken(userInvite: UserInvite) {
  return request.post("/invite/generate", userInvite)
}

export function deleteUserByEmail(userEmail: { email: string }) {
  return request.put("/users/delete", { data: userEmail })
}

export function updateUserById(user: User) {
  return request.put("/users/update", user)
}

export function updateUserPasswordById(data: {
  id: string
  oldHash: string
  newHash: string
}) {
  return request.put("/users/changePassword", data)
}

export function cancelUserInviteByToken(data) {
  return request.put("/invite/cancel", data)
}

export function getUserById(userId: { id: string }) {
  return request.post("/users/getUser", userId)
}

export function saveUserProject(projectData) {
  return request.post("/users/saveProject", projectData)
}

export function saveUserProjectBaseline(baselineData) {
  return request.post("/users/saveProjectBaseline", baselineData)
}

export function getAllUserProjects(userId) {
  return request.post("/users/getProjects", userId, {
    headers: {
      "x-show-loader": true,
    },
  })
}

export function getUserBaselineData(projectId) {
  return request.post("/users/getProjectBaseline", projectId, {
    headers: {
      "x-show-loader": true,
    },
  })
}

export function deleteUserProject(userId: { id: string }) {
  return request.delete("/users/deleteProject", { data: userId })
}

export function saveSimulationResult(resultData) {
  return request.post("/project/saveSimulationResult", resultData)
}

export function getSimulationResult(projectId) {
  return request.post("/project/getSimulationResult", projectId)
}

export function updateProject(projectData) {
  return request.put("/users/updateProject", projectData)
}

export function deleteProjectBaselineById(projectId: { id: string }) {
  return request.delete("/users/deleteProjectBaselineById", { data: projectId })
}

export function updateSimulationResult(simulationResult) {
  return request.put("/project/updateSimulation", simulationResult)
}
