import { jwtDecode } from "jwt-decode"
import { Navigate } from "react-router-dom"
import React from "react"

export default function Landing() {
  let navigateTo = "login"
  try {
    const token = localStorage.getItem("token")
    if (token != null) {
      jwtDecode(token)
      navigateTo = "/editor"
    }
  } catch (e) {
    // Blank
  }
  return <Navigate to={navigateTo} />
}
