import React from "react"
import { Map } from "mapbox-gl"
import { NetworkGeometry, NetworkType } from "../../specs/Networks"
import { generateSourceId } from "../../utils/mapbox/Layers"
import { Feature } from "@turf/helpers"
import MultiSelectDropdown from "../form-elements/MultiSelectDropdown"
import { SelectOption, SelectValue } from "../../specs/FormElements"
import intl from "react-intl-universal"
import {
  getAllFeaturesWithSameRouteId,
  getAllRouteFeatures,
} from "../../utils/mapbox/FeaturesUtils"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { OperationMode, SelectStack } from "../../specs/Mapbox"
import ExtendExistingMode from "./ExtendExistingMode"

interface RouteSelectorProps {
  map: Map
  draw: MapboxDraw
  selectedNetworkType: NetworkType | null
  onSelection: (e) => void
  operationMode: OperationMode
}

interface RouteSelectorState {
  options: SelectOption[]
  selected: SelectValue[]
  data: NetworkGeometry[]
}

export default class RouteSelector extends React.Component<
  RouteSelectorProps,
  RouteSelectorState
> {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      selected: [],
      data: [],
    }
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidMount() {
    let data: NetworkGeometry[] = []
    if (this.props.selectedNetworkType != null) {
      this.props.selectedNetworkType.baselines.map((baseline) => {
        if (this.props.selectedNetworkType != null) {
          const source: any = this.props.map.getSource(
            generateSourceId(this.props.selectedNetworkType, baseline)
          )
          data = data.concat(source._data.features)
        }
      })
    }
    this.setState({
      options: this.getOptions(this.props.selectedNetworkType, data),
      data: data,
    })
  }

  getOptions(
    networkType: NetworkType | null,
    data: NetworkGeometry[]
  ): SelectOption[] {
    if (networkType == null) {
      return []
    }
    const featuresOption: SelectOption[] = []

    if (typeof data === "object" && data.length && data.length > 0) {
      data
        .filter(
          (d: Feature) => d.geometry.type === "LineString" && d.properties
        )
        .forEach((d: any) => {
          if (d.properties != null) {
            const ele = {
              label:
                networkType.getPropertyFromFeature(d, "routeId") +
                " " +
                networkType.getPropertyFromFeature(d, "label"),
              value: networkType.getPropertyFromFeature(d, "routeId"),
              disabled: false,
            }
            if (!featuresOption.some((d) => d.value === ele.value)) {
              featuresOption.push(ele)
            }
          }
        })
    }
    return featuresOption.sort((a, b) => {
      if (typeof a.value === "string" && typeof b.value === "string") {
        return a.value.localeCompare(b.value)
      } else if (typeof a.value === "number" && typeof b.value === "number") {
        return a.value - b.value
      }
      return 0
    })
  }

  handleOnChange(selected: SelectValue[]) {
    if (selected.length == 0) {
      this.props.draw.add({
        type: "FeatureCollection",
        features: this.state.data,
      })
    } else {
      const routeId = selected[selected.length - 1].value
      const filteredFeatures = getAllFeaturesWithSameRouteId(
        this.props.selectedNetworkType!,
        this.props.draw,
        routeId,
        this.props.operationMode
      )
      const stackItem: SelectStack = {
        mode: "unknown",
        operation: "route_selection",
        features: filteredFeatures,
        properties: { routeId },
      }
      this.props.onSelection({ stackItem })
      if (this.props.operationMode === "extend_existing_mode") {
        ExtendExistingMode.getEndPoints()
        getAllRouteFeatures(this.props.draw)
      }
    }
  }

  render() {
    return (
      <MultiSelectDropdown
        options={this.state.options}
        hasSelectAll={false}
        onChange={this.handleOnChange}
        closeOnChangedValue={true}
        placeholder={intl.get("editor.select_routes")}
      />
    )
  }
}
