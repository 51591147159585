import { IBuildNetwork, IRunSimulation } from "../specs/Engine"
import request from "./api"

export function buildNetwork(data: IBuildNetwork) {
  return request.post(
    "https://" + import.meta.env.VITE_ENGINE_URI + "/build_networks",
    data
  )
}

export function runSimulation(data: IRunSimulation) {
  return request.post(
    "https://" + import.meta.env.VITE_ENGINE_URI + "/run_simulation",
    data
  )
}
