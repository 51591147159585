import moment from "moment"
import React, { Component } from "react"
import Modal from "react-responsive-modal"
import ConfirmationModal from "../../components/mapbox/ConfirmationPopup"
import intl from "react-intl-universal"

interface ProjectSelectionModalProps {
  isModalOpen: boolean
  closeProjectSelectionModal?: any
  closeModal: () => void
  projectList: any
}
interface ProjectSelectionModalState {
  selectedProjectId: string | null
  openNewProjectConfirmationModalOpen: boolean
}

class ProjectSelectionModal extends Component<
  ProjectSelectionModalProps,
  ProjectSelectionModalState
> {
  projectId: string
  constructor(props: ProjectSelectionModalProps) {
    super(props)
    this.state = {
      selectedProjectId: null,
      openNewProjectConfirmationModalOpen: false,
    }
    const urlParam = new URLSearchParams(window.location.search)
    this.projectId = urlParam.get("projectId") || ""
    this.handleProjectSelection = this.handleProjectSelection.bind(this)
    this.openSelectedProject = this.openSelectedProject.bind(this)
    this.closeProjectSelectionModal = this.closeProjectSelectionModal.bind(this)
    this.openAddNewProjectConfirmationPopup =
      this.openAddNewProjectConfirmationPopup.bind(this)
    this.openSelectedProjectWithResult =
      this.openSelectedProjectWithResult.bind(this)
  }

  componentDidMount(): void {
    this.setState({
      selectedProjectId: this.projectId,
    })
  }

  handleProjectSelection(projectId: string) {
    this.setState({ selectedProjectId: projectId })
  }

  openSelectedProject() {
    const generatedProjectUrl = `${window.location.origin}/editor?projectId=${this.state.selectedProjectId}`
    window.location.href = generatedProjectUrl
  }

  openSelectedProjectWithResult() {
    const generatedProjectUrl = `${window.location.origin}/editor?projectId=${this.state.selectedProjectId}&result=${true}`
    window.location.href = generatedProjectUrl
  }

  convertDateTime(date) {
    const dateInUTC = moment.utc(date)
    const dateInLocalTime = dateInUTC.local()
    return dateInLocalTime.format("YYYY-MM-DD HH:mm")
  }

  closeProjectSelectionModal() {
    this.setState(
      {
        selectedProjectId: this.projectId,
      },
      () => {
        this.props.closeModal()
      }
    )
  }

  openAddNewProjectConfirmationPopup() {
    this.setState({ openNewProjectConfirmationModalOpen: true })
  }

  openNewProjectConfirmationButtons() {
    return [
      {
        label: intl.get("button.no"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.setState({ openNewProjectConfirmationModalOpen: false })
        },
      },
      {
        label: intl.get("button.yes"),
        buttonClass: "submitModalButton",
        action: () => {
          window.location.assign("/editor")
        },
      },
    ]
  }

  render() {
    const { closeModal, projectList, isModalOpen } = this.props
    const { selectedProjectId } = this.state
    return (
      <>
        <Modal
          open={isModalOpen}
          onClose={this.closeProjectSelectionModal}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={true}
          center={true}
          styles={{
            modal: {
              background: "#323232",
              color: "#fff",
              width: "50%",
              padding: 0,
              borderRadius: "4px",
            },
            closeButton: { fill: "white", top: 10, right: 5 },
          }}
        >
          <div className="modal-header">Select Project</div>
          <div className="modal-body">
            <div className="grid grid-cols-1 gap-2 gap-x-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 mt-3">
              {projectList.map((project) => (
                <label
                  key={project.id}
                  className={`radio-card relative ${
                    selectedProjectId === project.id ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="project"
                    value={project.id}
                    className="absolute top-0 right-0 m-3"
                    defaultChecked={
                      this.projectId ? this.projectId === project.id : false
                    }
                    onChange={() => this.handleProjectSelection(project.id)}
                  />
                  <div className="bg-neutral-900 p-3 rounded-lg cursor-pointer project-card">
                    <h3 className="text-white text-sm" title={project.name}>
                      {project.name}
                    </h3>
                    <p className="text-neutral-400 text-xs pt-1">
                      Updated on: {this.convertDateTime(project.updatedAt)}
                    </p>
                  </div>
                </label>
              ))}

              {this.projectId ? (
                <div
                  className="bg-neutral-900 p-3 rounded-lg cursor-pointer project-card flex flex-col justify-center items-center"
                  onClick={this.openAddNewProjectConfirmationPopup}
                >
                  <span className="inline-block align-middle">
                    <div className="text-center">
                      <i className="fa-solid fa-plus"></i>
                    </div>
                    <div
                      className="text-white text-sm mt-1 text-center"
                      title="newProject"
                    >
                      {intl.get("label.add_new_project")}
                    </div>
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="action-buttons">
              <button
                disabled={!this.state.selectedProjectId}
                className="submitModalButton float-right"
                onClick={this.openSelectedProjectWithResult}
              >
                {intl.get("label.show_result_with_network")}
              </button>
              <button
                disabled={!this.state.selectedProjectId}
                className="submitModalButton float-right"
                onClick={this.openSelectedProject}
              >
                {intl.get("label.show_network")}
              </button>
            </div>
          </div>
        </Modal>
        <ConfirmationModal
          isModalOpen={this.state.openNewProjectConfirmationModalOpen}
          buttons={this.openNewProjectConfirmationButtons()}
          message={intl.get("editor.add_new_project_confirmatn_msg")}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
      </>
    )
  }
}

export default ProjectSelectionModal
