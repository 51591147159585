import React, { Component } from "react"
import DataTable from "react-data-table-component"
import intl from "react-intl-universal"
import { deleteUserByEmail, getUsers } from "../../services/user.service"
import { showNotification } from "../../utils/Toaster"
import moment from "moment"
import SendInviteModalPopup from "./SendInviteModalPopup"
import ConfirmationModal from "../mapbox/ConfirmationPopup"
import { AppContext } from "../../pages/App"
import { capitalize } from "../../utils/common"
import Tippy from "@tippyjs/react"

interface UsersTableState {
  data: any
  showModal: boolean
  openconfirmationPopup: boolean
  id: string
  tableAction: string
  confirmationMsg: string
}

class UsersTable extends Component<any, UsersTableState> {
  static contextType = AppContext
  private appContext: any
  constructor(props: any, context: any) {
    super(props)
    this.state = {
      data: [],
      showModal: false,
      openconfirmationPopup: false,
      id: "",
      tableAction: "",
      confirmationMsg: "",
    }
    this.appContext = context
    this.handleInviteClick = this.handleInviteClick.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.clearConfirmationPopup = this.clearConfirmationPopup.bind(this)
  }

  componentDidMount() {
    this.getUsersData()
  }

  handleInviteClick = () => {
    this.setState({ showModal: true })
  }

  handleModalClose = () => {
    this.setState({
      showModal: false,
    })
  }

  clearConfirmationPopup() {
    this.setState({
      openconfirmationPopup: false,
      id: "",
      tableAction: "",
      confirmationMsg: "",
    })
  }

  getDeleteUserOptionButtons() {
    return [
      {
        label: intl.get("button.no"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.clearConfirmationPopup()
        },
      },
      {
        label: intl.get("button.yes"),
        buttonClass: "submitModalButton",
        action: () => {
          if (this.state.tableAction === "deleteUser") {
            this.deleteUser()
          }
        },
      },
    ]
  }

  getUsersData() {
    getUsers()
      .then((res) => {
        const responseData = res.data
        this.setState({
          data: responseData,
        })
      })
      .catch((err) => {
        console.error("Error:", err)
      })
  }

  deleteUser() {
    const userId = this.state.id
    const deleteUser = this.state.data.find((user) => user.id === userId)
    deleteUserByEmail({ email: deleteUser.email })
      .then((res) => {
        this.getUsersData()
        this.clearConfirmationPopup()
        showNotification(intl.get("alert.user_deleted_successfully"), "success")
      })
      .catch((err) => {
        console.log("error:", err)
      })
  }

  confirmationPopup(id: string, action: string) {
    let confirmationMsg = ""
    if (action === "deleteUser") {
      confirmationMsg = intl.get("confirm.delete_user")
    }
    this.setState({
      openconfirmationPopup: true,
      id: id,
      tableAction: action,
      confirmationMsg: confirmationMsg,
    })
  }

  convertDateTime(date) {
    const dateInUTC = moment.utc(date)
    const dateInLocalTime = dateInUTC.local()
    return dateInLocalTime.format("YYYY-MM-DD HH:mm")
  }

  render() {
    const columns = [
      // { name: "Sr No.", selector: (row) => row.id },
      { name: intl.get("label.first_name"), selector: (row) => row.firstName },
      { name: intl.get("label.last_name"), selector: (row) => row.lastName },
      { name: intl.get("label.email_id"), selector: (row) => row.email },
      { name: intl.get("label.role"), selector: (row) => capitalize(row.role) },
      {
        name: intl.get("label.organization"),
        selector: (row) => row.organization,
      },
      {
        name: intl.get("table.created_date"),
        selector: (row) => this.convertDateTime(row.createdAt),
      },
      {
        name: intl.get("label.action"),
        cell: (row) => (
          <span>
            <Tippy
              content={"Delete User"}
              placement={"bottom"}
              trigger={"mouseenter"}
              interactive={true}
              interactiveBorder={10}
              className="legend-btn-tooltip table-tooltip"
            >
              <button
                onClick={() => this.confirmationPopup(row.id, "deleteUser")}
                disabled={row.id === this.appContext.user.id}
                className={row.id === this.appContext.user.id ? "disabled" : ""}
              >
                <i
                  className="fa fa-trash tableDeleteButton"
                  aria-hidden="true"
                ></i>
              </button>
            </Tippy>
          </span>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]
    const tableCustomStyles = {
      headRow: {
        style: {
          color: "#ffffff",
          backgroundColor: "#303030",
          fontWeight: "bold",
        },
      },
      rows: {
        style: {
          color: "#ffffff",
          backgroundColor: "#303030",
        },
        stripedStyle: {
          color: "#ffffff",
          backgroundColor: "#404040",
        },
      },
      pagination: {
        style: {
          backgroundColor: "#404040",
          color: "#ffffff",
        },
        pageButtonsStyle: {
          color: "#ffffff",
        },
      },
    }
    return (
      <div>
        {" "}
        <button
          className="submitModalButton mb-4 float-right"
          onClick={this.handleInviteClick}
        >
          <i className="fa-solid fa-user-plus" aria-hidden="true"></i>{" "}
          {intl.get("header.send_invite")}
        </button>
        <DataTable
          title="Users"
          columns={columns}
          data={this.state.data.reverse()}
          pagination
          striped
          customStyles={tableCustomStyles}
          fixedHeader
          fixedHeaderScrollHeight={"50vh"}
          responsive
        />
        <SendInviteModalPopup
          isModalOpen={this.state.showModal}
          handleModalClose={this.handleModalClose}
          id={this.appContext.user.id}
          users={this.state.data}
        ></SendInviteModalPopup>
        <ConfirmationModal
          isModalOpen={this.state.openconfirmationPopup}
          buttons={this.getDeleteUserOptionButtons()}
          message={this.state.confirmationMsg}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
      </div>
    )
  }
}

export default UsersTable
