import React, { Component } from "react"
import Modal from "react-responsive-modal"
import PulseLoader from "react-spinners/PulseLoader"

interface ConfirmationPopupButton {
  label: string
  buttonClass?: string
  iconClass?: string
  action?: (e) => void
  processing?: boolean
}

interface ConfirmationModalProps {
  isModalOpen: boolean
  closeConfirmationModal?: any
  delete?: any
  message?: string
  featureName?: string
  buttons: ConfirmationPopupButton[]
  iconClasses: string
  modalWidth?: string
  showCloseBtn?: boolean
}

class ConfirmationModal extends Component<ConfirmationModalProps> {
  constructor(props: ConfirmationModalProps) {
    super(props)
  }

  render() {
    const { buttons } = this.props
    return (
      <Modal
        open={this.props.isModalOpen}
        onClose={this.props.closeConfirmationModal}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={
          this.props.showCloseBtn ? this.props.showCloseBtn : false
        }
        center={true}
        styles={{
          modal: {
            background: "#323232",
            color: "#fff",
            width: this.props.modalWidth ? this.props.modalWidth : "30%",
            padding: 0,
            fontWeight: "bold",
            borderRadius: "4px",
          },
          closeButton: { top: 10, right: 5, fill: "white" },
        }}
      >
        <div className="confirmationModalBody">
          <div className="confirmationModalHeader">
            <div>
              {" "}
              <span className="deleteiconSpan">
                {" "}
                <i className={this.props.iconClasses}></i>{" "}
              </span>
            </div>
            <p style={{ whiteSpace: "pre-line" }}>
              {this.props.message} <b>{this.props.featureName}</b>
            </p>
          </div>
        </div>
        <div className="confirmationModalfooter">
          {buttons?.map((button) => (
            <button
              key={button.label}
              onClick={button.action}
              className={button.buttonClass}
              disabled={button.processing}
            >
              {button.processing ? (
                <PulseLoader size={4} color="#fff" margin={1}></PulseLoader>
              ) : null}
              {button.label}
            </button>
          ))}
        </div>
      </Modal>
    )
  }
}

export default ConfirmationModal
