// Heavily inspired from work of @davidgilbertson on Github and `leaflet-geoman` project.
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {
  BBox,
  bboxPolygon,
  booleanDisjoint,
  distance,
  featureCollection,
  getCoords,
  lineString as turfLineString,
  midpoint,
  nearestPoint as nearestPointInPointSet,
  nearestPointOnLine,
  point as turfPoint,
  polygonToLine,
  Position,
} from "@turf/turf"
import { NetworkGeometry } from "../../specs/Networks"
import { Map } from "mapbox-gl"
import { getNearByFeaturesFrom } from "./FeaturesUtils"

const { geojsonTypes } = MapboxDraw.constants

export const addPointTovertices = (
  map: Map,
  vertices,
  coordinates,
  forceInclusion
) => {
  const { width: w, height: h } = map.getCanvas()
  // Just add verteices of features currently visible in viewport
  const { x, y } = map.project(coordinates)
  const pointIsOnTheScreen = x > 0 && x < w && y > 0 && y < h

  // But do add off-screen points if forced (e.g. for the current feature)
  // So features will always snap to their own points
  if (pointIsOnTheScreen || forceInclusion) {
    vertices.push(coordinates)
  }
}

export const createSnapList = (map, features, currentFeature) => {
  // Get all drawn features
  // const features = draw.getAll().features
  const snapList: NetworkGeometry[] = []

  // Get current bbox as polygon
  const bboxAsPolygon = (() => {
    const canvas = map.getCanvas(),
      w = canvas.width,
      h = canvas.height,
      cUR = map.unproject([w, 0]).toArray(),
      cLL = map.unproject([0, h]).toArray()

    return bboxPolygon([cLL, cUR].flat() as BBox)
  })()

  const vertices: Position[] = []

  // Keeps vertices for drwing guides
  const addVerticesTovertices = (coordinates, isCurrentFeature = false) => {
    if (!Array.isArray(coordinates)) throw Error("Your array is not an array")

    if (Array.isArray(coordinates[0])) {
      // coordinates is an array of arrays, we must go deeper
      coordinates.forEach((coord) => {
        addVerticesTovertices(coord)
      })
    } else {
      // If not an array of arrays, only consider arrays with two items
      if (coordinates.length === 2) {
        addPointTovertices(map, vertices, coordinates, isCurrentFeature)
      }
    }
  }

  features.forEach((feature) => {
    // For currentfeature
    if (feature.id === currentFeature.id) {
      if (currentFeature.type === geojsonTypes.POLYGON) {
        // For the current polygon, the last two points are the mouse position and back home
        // so we chop those off (else we get vertices showing where the user clicked, even
        // if they were just panning the map)
        addVerticesTovertices(
          feature.geometry.coordinates[0].slice(0, -2),
          true
        )
      }
      return
    }

    addVerticesTovertices(feature.geometry.coordinates)

    // If feature is currently on viewport add to snap list
    if (!booleanDisjoint(bboxAsPolygon, feature)) {
      snapList.push(feature)
    }
  })

  return { snapList, vertices }
}

const calcLayerDistances = (lngLat, layer) => {
  // the point P which we want to snap (probpably the marker that is dragged)
  const P = [lngLat.lng, lngLat.lat]

  // is this a marker?
  const isMarker = layer.geometry.type === "Point"
  // is it a polygon?
  const isPolygon = layer.geometry.type === "Polygon"
  // is it a multiPolygon?
  const isMultiPolygon = layer.geometry.type === "MultiPolygon"
  // is it a multiPoint?
  const isMultiPoint = layer.geometry.type === "MultiPoint"

  let lines: any = undefined

  // the coords of the layer
  const latlngs = getCoords(layer)

  if (isMarker) {
    const [lng, lat] = latlngs
    // return the info for the marker, no more calculations needed
    return {
      latlng: { lng, lat },
      distance: distance(latlngs, P),
    }
  }

  if (isMultiPoint) {
    const np = nearestPointInPointSet(
      P,
      featureCollection(latlngs.map((x) => turfPoint(x)))
    )
    const c = np.geometry.coordinates
    return {
      latlng: { lng: c[0], lat: c[1] },
      distance: np.properties.distanceToPoint,
    }
  }

  if (isPolygon || isMultiPolygon) {
    lines = polygonToLine(layer)
  } else {
    lines = layer
  }

  let nearestPoint
  if (isPolygon && lines) {
    let lineStrings
    if (lines.geometry.type === "LineString") {
      lineStrings = [turfLineString(lines.geometry.coordinates)]
    } else {
      lineStrings = lines.geometry.coordinates.map((coords) =>
        turfLineString(coords)
      )
    }

    const closestFeature = getFeatureWithNearestPoint(lineStrings, P)
    lines = closestFeature.feature
    nearestPoint = closestFeature.point
  } else if (isMultiPolygon) {
    const lineStrings = lines.features
      .map((feat) => {
        if (feat.geometry.type === "LineString") {
          return [feat.geometry.coordinates]
        } else {
          return feat.geometry.coordinates
        }
      })
      .flatMap((coords) => coords)
      .map((coords) => turfLineString(coords))

    const closestFeature = getFeatureWithNearestPoint(lineStrings, P)
    lines = closestFeature.feature
    nearestPoint = closestFeature.point
  } else {
    nearestPoint = nearestPointOnLine(lines, P)
  }

  const [lng, lat] = nearestPoint.geometry.coordinates

  let segmentIndex = nearestPoint.properties.index
  if (segmentIndex + 1 === lines.geometry.coordinates.length) segmentIndex--

  return {
    latlng: { lng, lat },
    segment: lines.geometry.coordinates.slice(segmentIndex, segmentIndex + 2),
    distance: nearestPoint.properties.dist,
    isMarker,
  }
}

function getFeatureWithNearestPoint(lineStrings, P) {
  const nearestPointsOfEachFeature = lineStrings.map((feat) => ({
    feature: feat,
    point: nearestPointOnLine(feat, P),
  }))

  nearestPointsOfEachFeature.sort(
    (a, b) => a.point.properties.dist - b.point.properties.dist
  )

  return {
    feature: nearestPointsOfEachFeature[0].feature,
    point: nearestPointsOfEachFeature[0].point,
  }
}

const calcClosestFeature = (
  lngLat,
  features,
  searchInBbox: boolean = false
) => {
  let closestFeature: any = {}

  if (searchInBbox) {
    features = getNearByFeaturesFrom(features, [lngLat.lng, lngLat.lat])
  }

  // loop through the layers
  features.forEach((feature) => {
    // find the closest latlng, segment and the distance of this layer to the dragged marker latlng
    const results = calcLayerDistances(lngLat, feature)

    // save the info if it doesn't exist or if the distance is smaller than the previous one
    if (
      closestFeature.distance === undefined ||
      results.distance < closestFeature.distance
    ) {
      closestFeature = results
      closestFeature.layer = feature
    }
  })

  // return the closest layer and it's data
  // if there is no closest layer, return undefined
  return closestFeature
}

// minimal distance before marker snaps (in pixels)
const metersPerPixel = function (latitude, zoomLevel) {
  const earthCircumference = 40075017
  const latitudeRadians = latitude * (Math.PI / 180)
  return (
    (earthCircumference * Math.cos(latitudeRadians)) /
    Math.pow(2, zoomLevel + 8)
  )
}

// we got the point we want to snap to (C), but we need to check if a coord of the polygon
function snapToLineOrPolygon(
  closestLayer,
  snapOptions,
  snapVertexPriorityDistance
) {
  // A and B are the points of the closest segment to P (the marker position we want to snap)
  const A = closestLayer.segment[0]
  const B = closestLayer.segment[1]

  // C is the point we would snap to on the segment.
  // The closest point on the closest segment of the closest polygon to P. That's right.
  const C = [closestLayer.latlng.lng, closestLayer.latlng.lat]

  // distances from A to C and B to C to check which one is closer to C
  const distanceAC = distance(A, C)
  const distanceBC = distance(B, C)

  // closest latlng of A and B to C
  let closestVertexLatLng = distanceAC < distanceBC ? A : B

  // distance between closestVertexLatLng and C
  let shortestDistance = distanceAC < distanceBC ? distanceAC : distanceBC

  // snap to middle (M) of segment if option is enabled
  if (snapOptions && snapOptions.snapToMidPoints) {
    const M = midpoint(A, B).geometry.coordinates
    const distanceMC = distance(M, C)

    if (distanceMC < distanceAC && distanceMC < distanceBC) {
      // M is the nearest vertex
      closestVertexLatLng = M
      shortestDistance = distanceMC
    }
  }

  // the distance that needs to be undercut to trigger priority
  const priorityDistance = snapVertexPriorityDistance

  // the latlng we ultemately want to snap to
  let snapLatlng

  // if C is closer to the closestVertexLatLng (A, B or M) than the snapDistance,
  // the closestVertexLatLng has priority over C as the snapping point.
  if (shortestDistance < priorityDistance) {
    snapLatlng = closestVertexLatLng
  } else {
    snapLatlng = C
  }

  // return the copy of snapping point
  const [lng, lat] = snapLatlng
  return { lng, lat }
}

function snapToPoint(closestLayer) {
  return closestLayer.latlng
}

const checkPrioritySnapping = (
  closestLayer,
  snapOptions,
  snapVertexPriorityDistance = 1.25
) => {
  const snappingToPoint = !Array.isArray(closestLayer.segment)
  if (snappingToPoint) {
    return snapToPoint(closestLayer)
  } else {
    return snapToLineOrPolygon(
      closestLayer,
      snapOptions,
      snapVertexPriorityDistance
    )
  }
}

/**
 * Returns snap points if there are any, otherwise the original lng/lat of the event
 * Also, defines if vertices should show on the state object
 *
 * Mutates the state object
 *
 * @param state
 * @param e
 * @returns {{lng: number, lat: number}}
 */
export const snap = (state, e) => {
  const lng = e.lngLat.lng
  const lat = e.lngLat.lat

  // Holding alt bypasses all snapping
  if (e.originalEvent.altKey) {
    state.showVerticalSnapLine = false
    state.showHorizontalSnapLine = false

    return { lng, lat }
  }

  if (state.snapList.length <= 0) {
    return { lng, lat }
  }

  // snapping is on
  let closestFeature, minDistance, snapLatLng
  if (state.options.snap) {
    closestFeature = calcClosestFeature(
      { lng, lat },
      state.snapList,
      state.selectedNetworkType.name === "bike"
    )

    // if no layers found. Can happen when circle is the only visible layer on the map and the hidden snapping-border circle layer is also on the map
    if (Object.keys(closestFeature).length === 0) {
      return { lng, lat }
    }

    const isMarker = closestFeature.isMarker
    const snapVertexPriorityDistance = state.options.snapOptions
      ? state.options.snapOptions.snapVertexPriorityDistance
      : undefined

    if (!isMarker) {
      snapLatLng = checkPrioritySnapping(
        closestFeature,
        state.options.snapOptions,
        snapVertexPriorityDistance
      )
    } else {
      snapLatLng = closestFeature.latlng
    }

    minDistance =
      ((state.options.snapOptions && state.options.snapOptions.snapPx) || 15) *
      metersPerPixel(snapLatLng.lat, state.map.getZoom())
  }

  if (closestFeature && closestFeature.distance * 1000 < minDistance) {
    return snapLatLng
  } else {
    return { lng, lat }
  }
}
