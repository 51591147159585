import React from "react"
import { MultiSelect } from "react-multi-select-component"
import { SelectOption, SelectValue } from "../../specs/FormElements"

interface MultiSelectDropdownProps {
  options: SelectOption[]
  value?: SelectValue[]
  onChange?: (selected: SelectValue[]) => void
  hasSelectAll: boolean
  closeOnChangedValue?: boolean
  placeholder: string
}

interface MultiSelectDropdownState {
  selected: SelectValue[]
}

export default class MultiSelectDropdown extends React.Component<
  MultiSelectDropdownProps,
  MultiSelectDropdownState
> {
  constructor(props) {
    super(props)
    this.state = {
      selected: this.props.value !== undefined ? this.props.value : [],
    }
    this.onChange = this.onChange.bind(this)
  }

  valueRenderer(selected) {
    if (!selected.length) {
      return this.props.placeholder
    }
  }

  onChange(selected: SelectValue[]) {
    this.setState(
      {
        selected: selected,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.selected)
        }
      }
    )
  }

  render() {
    return (
      <div className="multi-select-dropdown-div">
        <MultiSelect
          options={this.props.options}
          value={this.state.selected}
          onChange={this.onChange}
          labelledBy={"select"}
          hasSelectAll={this.props.hasSelectAll}
          closeOnChangedValue={this.props.closeOnChangedValue}
          valueRenderer={this.valueRenderer.bind(this)}
          className="multi-select-dropdown"
        />
      </div>
    )
  }
}
