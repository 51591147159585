import React, { Component } from "react"
import intl from "react-intl-universal"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import UsersTable from "./UsersTable"
import UserInvitesTable from "./UserInvitesTable"

interface UsersTabContentState {
  tabIndex: number
}

class UsersTabContent extends Component<any, UsersTabContentState> {
  constructor(props: any) {
    super(props)
    this.state = {
      tabIndex: 0,
    }
  }

  render() {
    return (
      <div>
        {" "}
        <Tabs
          selectedIndex={this.state.tabIndex}
          onSelect={(index) => this.setState({ tabIndex: index })}
        >
          <TabList className="flex mb-4">
            <Tab className="mr-2 text-white rounded-md px-4 py-1 cursor-pointer text-xs ">
              {intl.get("label.active")}
            </Tab>
            <Tab className="text-white rounded-md px-4 py-1 cursor-pointer text-xs">
              {intl.get("label.user_invites")}
            </Tab>
          </TabList>
          <TabPanel>
            <UsersTable></UsersTable>
          </TabPanel>
          <TabPanel>
            <UserInvitesTable></UserInvitesTable>
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}
export default UsersTabContent
