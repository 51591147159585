import React from "react"
import intl from "react-intl-universal"
import "../languages-selector/style.scss"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { updateUserPasswordById } from "../../services/user.service"
import { showNotification, ToastType } from "../../utils/Toaster"
import { PulseLoader } from "react-spinners"

interface ChangePasswordFormProps {
  id: string
  closeMyProfilePopup: () => void
  showNotification: (message, type: ToastType) => void
}

class ChangePasswordForm extends React.Component<ChangePasswordFormProps, any> {
  private forgetPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, intl.get("error.password_length"))
      .required(intl.get("error.required")),
    newPassword: Yup.string()
      .notOneOf(
        [Yup.ref("oldPassword"), null],
        "New password cannot be same as old password"
      )
      .min(6, intl.get("error.password_length"))
      .required(intl.get("error.required")),
    confirmNewPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), undefined],
        intl.get("error.passwords_do_not_match")
      )
      .min(6, intl.get("error.password_length"))
      .required(intl.get("error.required")),
  })

  constructor(props) {
    super(props)
    this.updatePasswordDetails = this.updatePasswordDetails.bind(this)
  }

  async updatePasswordDetails(values, { setErrors, setSubmitting }) {
    const data = {
      id: this.props.id,
      oldHash: values.oldPassword,
      newHash: values.newPassword,
    }
    updateUserPasswordById(data)
      .then((responseData) => {
        if (responseData) {
          this.props.closeMyProfilePopup()
          this.props.showNotification(
            intl.get("alert.password_changed_successfully"),
            "success"
          )
        }
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          setErrors({
            oldPassword: intl.get("error.old_password_do_not_match"),
          })
        } else {
          console.error("API Error:", error)
        }
        setSubmitting(false)
      })
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={this.forgetPasswordSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          this.updatePasswordDetails(values, { setErrors, setSubmitting })
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-1 gap-2 gap-x-4 sm:grid-cols-2">
              <div className="key-value-pair me-2">
                <label
                  htmlFor="oldPassword"
                  className="text-normal font-medium leading-6 text-gray-200"
                >
                  {intl.get("label.old_password")}
                </label>
                <Field
                  name="oldPassword"
                  type="password"
                  placeholder={intl.get("label.old_password")}
                />
                {errors.oldPassword && touched.oldPassword ? (
                  <div className="text-red-500 mt-1 text-start">
                    {errors.oldPassword}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 gap-x-4 sm:grid-cols-2 mt-1">
              <div className="key-value-pair me-2">
                <label
                  htmlFor="newPassword"
                  className="text-normal font-medium leading-6 text-gray-200"
                >
                  {intl.get("label.new_password")}
                </label>
                <Field
                  name="newPassword"
                  type="password"
                  placeholder={intl.get("label.new_password")}
                />
                {errors.newPassword && touched.newPassword ? (
                  <div className="text-red-500 mt-1 text-start">
                    {errors.newPassword}
                  </div>
                ) : null}
              </div>
              <div className="key-value-pair me-2">
                <label
                  htmlFor="confirmNewPassword"
                  className="text-normal font-medium leading-6 text-gray-200"
                >
                  {intl.get("label.confirm_new_password")}
                </label>
                <Field
                  name="confirmNewPassword"
                  type="password"
                  placeholder={intl.get("label.confirm_new_password")}
                />
                {errors.confirmNewPassword && touched.confirmNewPassword ? (
                  <div className="text-red-500 mt-1 text-start">
                    {errors.confirmNewPassword}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="action-buttons">
              <button
                type="button"
                className={"cancelModalButton"}
                onClick={this.props.closeMyProfilePopup}
              >
                <span>{intl.get("button.cancel")}</span>
              </button>
              <button className={"submitModalButton"} disabled={isSubmitting}>
                {isSubmitting && (
                  <PulseLoader size={4} color="#fff" margin={1} />
                )}
                {isSubmitting
                  ? intl.get("button.updating")
                  : intl.get("button.update")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}

export default ChangePasswordForm
