import React, { Component } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import intl from "react-intl-universal"
import { PulseLoader } from "react-spinners"
import {
  getConfigurations,
  updateConfigurations,
} from "../../services/configuration.service"

import { showNotification } from "../../utils/Toaster"

interface ConfigurationTabContentState {
  data: any
}

class ConfigurationTabContent extends Component<
  any,
  ConfigurationTabContentState
> {
  private ConfigurationSchema = Yup.object().shape({
    maxNoOfProjects: Yup.string()
      .required(intl.get("error.required"))
      .matches(/^[1-9][0-9]*$/, {
        message: intl.get("error.no_greater_than_zero"),
      }),
  })

  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount() {
    this.getConfigurationData()
  }

  getConfigurationData() {
    getConfigurations()
      .then((res) => {
        this.setState({
          data: res.data,
        })
      })
      .catch((err) => {
        console.error("Error:", err)
      })
  }

  handleFormSubmit(values, { setSubmitting }) {
    const data: Configuration = {
      id: this.state.data.id,
      projectLimit: values.maxNoOfProjects,
    }
    updateConfigurations(data)
      .then((res) => {
        showNotification(
          intl.get("alert.project_limit_changed_successfully"),
          "success"
        )
        setSubmitting(false)
      })
      .catch((err) => {
        console.log(err)
        setSubmitting(false)
      })
  }

  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            maxNoOfProjects: this.state.data.projectLimit,
          }}
          validationSchema={this.ConfigurationSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.handleFormSubmit(values, { setSubmitting })
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <label
                htmlFor="maxNoOfProjects"
                className="text-normal font-medium leading-6 text-gray-200 text-xs"
              >
                {intl.get("label.project_limit")}
              </label>
              <div className="gap-x-4 flex">
                <div className="key-value-pair block w-64 ">
                  <Field
                    name="maxNoOfProjects"
                    type="number"
                    min="0"
                    placeholder={intl.get("label.project_limit")}
                    onKeyDown={(e) => {
                      const invalidChars = ["e", "+", "-", "."]
                      if (invalidChars.includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                  />
                  {errors.maxNoOfProjects && touched.maxNoOfProjects ? (
                    <div className="text-red-500 mt-1 text-start">
                      {String(errors.maxNoOfProjects)}
                    </div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting} // Disable button when submitting
                  className={`submitModalButton configUpdateBtn`}
                >
                  {isSubmitting && (
                    <PulseLoader size={4} color="#fff" margin={1} />
                  )}
                  {isSubmitting
                    ? intl.get("button.updating")
                    : intl.get("button.update")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}
export default ConfigurationTabContent
