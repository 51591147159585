import React, { Component } from "react"
import intl from "react-intl-universal"
import {
  createUserAndUpdateInviteStatus,
  validateSignUpToken,
} from "../services/user.service"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { showNotification } from "../utils/Toaster"
import { PulseLoader } from "react-spinners"

interface SignUpState {
  email: string
  role: string
  isLinkExpired: boolean
}

class SignUp extends Component<any, SignUpState> {
  token: string
  private inputFieldDetails: any = []

  private SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]+$/, intl.get("error.firstName_only_alphabet"))
      .required(intl.get("error.required")),
    lastName: Yup.string()
      .matches(/^[A-Za-z]+$/, intl.get("error.lastName_only_alphabet"))
      .required(intl.get("error.required")),
    organization: Yup.string().required(intl.get("error.required")),
    password: Yup.string()
      .min(6, intl.get("error.password_length"))
      .required(intl.get("error.required")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        intl.get("error.passwords_do_not_match")
      )
      .min(6, intl.get("error.password_length"))
      .required(intl.get("error.required")),
  })

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      role: "",
      isLinkExpired: false,
    }
    this.inputFieldDetails = [
      {
        name: "firstName",
        label: intl.get("label.first_name"),
        type: "text",
      },
      {
        name: "lastName",
        label: intl.get("label.last_name"),
        type: "text",
      },
      {
        name: "email",
        label: intl.get("label.email_id"),
        type: "email",
        readOnly: true,
      },
      {
        name: "organization",
        label: intl.get("label.organization"),
        type: "text",
      },
      {
        name: "password",
        label: intl.get("label.password"),
        type: "password",
      },
      {
        name: "confirmPassword",
        label: intl.get("label.confirm_password"),
        type: "password",
      },
    ]

    const urlParam = new URLSearchParams(window.location.search)
    this.token = urlParam.get("token") || ""
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount() {
    if (!this.token) {
      this.setState({
        isLinkExpired: true,
      })
      return
    }
    validateSignUpToken({ inviteToken: this.token })
      .then((res) => {
        const responseData = res.data
        const status = responseData.status
        if (status !== "invited") {
          this.setState({
            isLinkExpired: true,
          })
        } else {
          this.setState({
            email: responseData.email,
            role: responseData.role,
          })
        }
      })
      .catch((err) => {
        this.setState({
          isLinkExpired: true,
        })
      })
  }

  handleFormSubmit(values, { setSubmitting }) {
    const userSignupData: UserSignup = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      organization: values.organization,
      password: values.password,
      role: this.state.role,
      inviteToken: this.token,
    }
    createUserAndUpdateInviteStatus(userSignupData)
      .then((res) => {
        window.location.replace("/login")
      })
      .catch((err) => {
        if (err.response.data.errorCode === "P2002") {
          showNotification(intl.get("alert.email_already_exist"), "success")
        }
        setSubmitting(false)
      })
  }

  render() {
    return (
      <div className="flex min-h-full h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-neutral-800 px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-[620px]">
          {!this.state.isLinkExpired ? (
            <div className="bg-neutral-900 px-6 py-6 shadow rounded-lg border-gradient">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="text-center text-xl font-bold leading-9 tracking-tight text-white">
                  {intl.get("navbar_title")}
                </h2>
              </div>
              <div
                className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                aria-hidden="true"
              ></div>
              <Formik
                enableReinitialize
                initialValues={{
                  firstName: "",
                  lastName: "",
                  organization: "",
                  email: this.state.email,
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={this.SignUpSchema}
                onSubmit={(values, { setSubmitting }) => {
                  this.handleFormSubmit(values, { setSubmitting })
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="grid grid-cols-1 gap-2 gap-x-4 sm:grid-cols-2">
                      {this.inputFieldDetails.map((field) => (
                        <div className="key-value-pair me-2" key={field.name}>
                          <label
                            htmlFor={field.name}
                            className="text-normal font-medium leading-6 text-gray-200"
                          >
                            {field.label}
                          </label>
                          <Field
                            name={field.name}
                            type={field.type}
                            readOnly={field.readOnly}
                            className="w-full rounded-md border-gray-300 shadow-sm"
                            placeholder={field.label}
                          />
                          {errors[field.name] && touched[field.name] && (
                            <div className="text-red-500 mt-1 text-start">
                              {errors[field.name]}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="mt-6 w-full  submitModalButton"
                    >
                      {isSubmitting && (
                        <PulseLoader size={4} color="#fff" margin={1} />
                      )}
                      {isSubmitting
                        ? intl.get("label.signing_up")
                        : intl.get("label.sign_up")}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="bg-neutral-900 px-6 py-6 shadow rounded-lg border-gradient">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <span className="text-center text-3xl text-red-700 font-bold ">
                  <i className="fa-solid fa-exclamation-triangle"></i>
                </span>
                <h2 className="text-center text-xl font-bold leading-9 tracking-tight text-white">
                  {intl.get("invalid.url")}
                </h2>
              </div>
              <div
                className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                aria-hidden="true"
              ></div>
              <p className="text-center text-s text-white">
                {intl.get("invalid.url_msg")}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default SignUp
