import { ToastOptions, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export type ToastType = "success" | "error" | "warning"

export function showNotification(message, type) {
  switch (type) {
    case "success":
      toast.success(message, getToastConfig())
      break
    case "error":
      toast.error(message, getToastConfig())
      break
    case "warning":
      toast.warning(message, getToastConfig())
      break
    default:
      toast(message, getToastConfig())
  }
}

// Function to get common toast configuration
function getToastConfig() {
  return {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
    closeButton: false,
  } as ToastOptions<unknown>
}
