import axios from "axios"

const apiClient = () => {
  const defaultOptions = {
    baseURL: "/api/",
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  }

  const instance = axios.create(defaultOptions)

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token")
    config.headers.Authorization = token ? `Bearer ${token}` : ""
    return config
  })

  return instance
}

const request = apiClient()
export default request
