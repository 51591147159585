import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {
  addPointTovertices,
  createSnapList,
  snap,
} from "../../utils/mapbox/Snapping"
import { Position, round } from "@turf/turf"
import {
  AddModeOptions,
  AddModeState,
  CRUDStack,
  IAddMode,
  MapEvents,
  MapEventStack,
  SelectStack,
} from "../../specs/Mapbox"
import { GeoJSON } from "geojson"
import { NetworkGeometry } from "../../specs/Networks"
import {
  GEOJSON_COORD_PRECISION,
  MAPBOX_ACCESS_TOKEN,
} from "../../constants/config"
import axios from "axios"
import { isEsc, isRedo, isUndo, redo, undo } from "../../utils/mapbox/Commons"
import {
  createLineStringFeature,
  createPointFeature,
  getDisconnectedFeatures,
} from "../../utils/mapbox/FeaturesUtils"
import intl from "react-intl-universal"
import { addModeDisplyFeatures, newLine } from "./CommonOperationMode"

const { doubleClickZoom } = MapboxDraw.lib
const { cursors } = MapboxDraw.constants
const CommonSelectors = MapboxDraw.lib.CommonSelectors
const deletePaths: string[] = []
let drawnFeatures: NetworkGeometry[] = []
let draw: MapboxDraw

let newlyDrawnFeatures: NetworkGeometry[] = []
let allFeatures: NetworkGeometry[] = []
let isDrawingInprogress = false
export let getAllExistingLineStringFeatures: NetworkGeometry[] = []

export function updateDrawnFeatures() {
  newlyDrawnFeatures = draw
    .getAll()
    .features.filter((feature) => feature.geometry.type === "Point")
    .map((d) => d as NetworkGeometry)
}

const AddMode: IAddMode = {
  onSetup(options: AddModeOptions): AddModeState {
    const geometryType =
      options.selectedNetworkType.name == "bike" ? "LineString" : "Point"
    if (options.init) {
      draw = this["_ctx"]["api"]
      drawnFeatures = draw
        .getAll()
        .features.filter((feature) => feature.geometry.type === geometryType)
        .map((d) => d as NetworkGeometry)
      getAllExistingLineStringFeatures = draw
        .getAll()
        .features.filter((feature) => feature.geometry.type === "LineString")
        .map((d) => d as NetworkGeometry)
      draw.deleteAll()
    }
    const line = newLine.call(this)

    const selectedFeatures = this.getSelected()
    this.clearSelectedFeatures()
    doubleClickZoom.disable(this)

    newlyDrawnFeatures = draw
      .getAll()
      .features.filter(
        (feature) =>
          feature.geometry.type === geometryType &&
          feature.properties!.title !== "Incomplete"
      )
      .map((d) => d as NetworkGeometry)

    allFeatures = drawnFeatures.concat(newlyDrawnFeatures)

    const { snapList, vertices } = createSnapList(this.map, allFeatures, line)
    const state: AddModeState = {
      map: this.map,
      line,
      currentVertexPosition: 0,
      vertices,
      snapList,
      selectedFeatures,
      direction: "forward", // expected by DrawLineString
      startCords: [],
      endCords: [],
      completeLineCoordinates: [],
      options: {
        ...this["_ctx"]["options"],
        guides: false,
        snap: true,
        snapOptions: {
          snapPx: 15,
          snapToMidPoints: false,
          snapVertexPriorityDistance: 0,
        },
      },
      snappedLat: 0,
      snappedLng: 0,
      lastVertex: [],
      onCancel: () => {},
      selectedNetworkType: options.selectedNetworkType,
      network: options.selectedNetworkType.baselineProperties.Network,
      stop: options.selectedNetworkType.baselineProperties.Stops,
    }

    const moveendCallback = () => {
      const { snapList, vertices } = createSnapList(this.map, allFeatures, line)
      state.vertices = vertices
      state.snapList = snapList
    }

    // for removing listener later on close
    state["moveendCallback"] = moveendCallback

    const optionsChangedCallBAck = (options) => {
      state.options = options
    }
    // for removing listener later on close
    state["optionsChangedCallBAck"] = optionsChangedCallBAck

    this.map.on("moveend", moveendCallback)
    this.map.on("draw.snap.options_changed", optionsChangedCallBAck)
    return state
  },

  onClick(state: AddModeState, e: any) {
    // const isRightClick = e.originalEvent.button === 2
    const isLeftClick = e.originalEvent.button === 0
    const isShiftClick = CommonSelectors.isShiftDown(e)

    if (isLeftClick) {
      if (e.originalEvent.detail === 2) {
        const f = state.line
        const createdFeatures: NetworkGeometry[] = []
        state.completeLineCoordinates.push([
          f.coordinates[f.coordinates.length - 1],
        ])
        const drawnFeature = this.getFeature(f.id.toString())
        if (drawnFeature === undefined) {
          /// Call `onCancel` if exists.
          if (typeof state.onCancel === "function") state.onCancel()
          return
        }
        /// remove last added coordinate
        else {
          f.removeCoordinate(`${state.currentVertexPosition}`)
        }

        if (f.isValid()) {
          deletePaths.push(f.id.toString())
          for (const id of deletePaths) {
            this.deleteFeature(id, { silent: true })
          }

          const coordsCollection: Position[] = []
          for (const coords of state.completeLineCoordinates) {
            for (const coord of coords) {
              coordsCollection.push(coord)
            }
          }

          const completePath = createLineStringFeature(
            coordsCollection,
            state.selectedNetworkType
          )
          isDrawingInprogress = false
          const pathId = draw.add(completePath)
          const addedPath = draw.get(pathId.toString())
          createdFeatures.push(addedPath as NetworkGeometry)
          if (state.selectedNetworkType.name == "bike") {
            // drawnFeatures.push(addedPath as NetworkGeometry)
            newlyDrawnFeatures = draw
              .getAll()
              .features.filter(
                (feature) => feature.geometry.type === "LineString"
              )
              .map((d) => d as NetworkGeometry)
          } else {
            const startPoint = createPointFeature(
              coordsCollection[0],
              allFeatures,
              state.selectedNetworkType
            )
            if (startPoint) {
              createdFeatures.push(startPoint)
              draw.add(startPoint)
              updateDrawnFeatures()
            }

            const endPoint = createPointFeature(
              coordsCollection[coordsCollection.length - 1],
              allFeatures,
              state.selectedNetworkType
            )

            if (endPoint) {
              createdFeatures.push(endPoint)
              // drawnFeatures.push(endPoint)
              draw.add(endPoint)
              updateDrawnFeatures()
            }

            newlyDrawnFeatures = draw
              .getAll()
              .features.filter((feature) => feature.geometry.type === "Point")
              .map((d) => d as NetworkGeometry)
          }

          const stackItem: CRUDStack = {
            stackState: [
              {
                action: "create",
                oldState: [],
                newState: createdFeatures,
              },
            ],
            operation: "added_network_with_stop",
            mode: "add_mode",
          }
          this.map.fire(MapEvents.CRUD, { stackItem })

          if (addedPath) {
            if (state.selectedNetworkType.name !== "bike") {
              const routeId = state.selectedNetworkType.getPropertyFromFeature(
                addedPath as NetworkGeometry,
                "routeId"
              )
              if (!routeId) {
                return
              }
              const stackItem: SelectStack = {
                mode: "add_mode",
                operation: "route_selection",
                features: [addedPath as NetworkGeometry],
                properties: { routeId: routeId },
              }

              setTimeout(() => {
                this.map.fire(MapEvents.ROUTE_SELECTED, {
                  stackItem,
                })
              }, 100)
            }

            this.changeMode("edit_mode", {
              featureId: addedPath.id,
              actionMode: "add_mode",
              selectedNetworkType: state.selectedNetworkType,
            })
          }

          const tooltipbox: HTMLElement =
            document.querySelector(".mouseTooltip")!
          tooltipbox.style.display = "none"
        }
        state.line = newLine.call(this)
        state.currentVertexPosition = 0
        state.completeLineCoordinates = []
        if (state.selectedNetworkType.name !== "bike") {
          getDisconnectedFeatures(
            draw,
            getAllExistingLineStringFeatures,
            state.selectedNetworkType,
            "add_mode",
            this.map
          )
        }
      } else {
        if (
          e.featureTarget &&
          e.featureTarget.source === "mapbox-gl-draw-cold" &&
          !isDrawingInprogress &&
          ((e.featureTarget._geometry.type == "Point" && isShiftClick) ||
            e.featureTarget._geometry.type == "LineString")
        ) {
          this.changeMode("edit_mode", {
            featureId: e.featureTarget.properties.id,
            actionMode: e.featureTarget.properties.mode,
            selectedNetworkType: state.selectedNetworkType,
            event: e,
          })
        } else {
          state.startCords = [e.lngLat.lng, e.lngLat.lat]
          this.drawLine(state, isLeftClick)
        }
      }
    }
    // else if (isRightClick) {
    //   this.snapLineToRoad(state, e)
    // }

    allFeatures = drawnFeatures.concat(newlyDrawnFeatures)
    const { snapList, vertices } = createSnapList(
      this.map,
      allFeatures,
      state.line
    )
    state.vertices = vertices
    state.snapList = snapList
  },

  onMouseMove(state: AddModeState, e: any) {
    let { lng, lat } = snap(state, e)
    lng = round(lng, GEOJSON_COORD_PRECISION)
    lat = round(lat, GEOJSON_COORD_PRECISION)
    const isFeature = CommonSelectors.isActiveFeature(e)
    const onVertex = CommonSelectors.isVertex(e)
    const tooltipbox: HTMLElement = document.querySelector(".mouseTooltip")!
    const tooltipContent: HTMLElement =
      document.querySelector(".mouseTooltip span")!
    let tooltipInnerHTML
    let isPointFeature = false
    if (e.featureTarget) {
      isPointFeature = e.featureTarget._geometry.type == "Point"
    }
    if (isPointFeature) {
      tooltipbox.style.display = "block"
      tooltipbox.style.top = e.originalEvent.clientY + 10 + "px"
      tooltipbox.style.left = e.originalEvent.clientX + 10 + "px"
      tooltipInnerHTML =
        "<p>" +
        intl.get("add_mode.point_select_tooltip") +
        "<br />" +
        intl.get("add_mode.line_draw_tooltip") +
        "</p>"
      tooltipContent.innerHTML = tooltipInnerHTML
    } else if (isFeature || onVertex) {
      tooltipbox.style.display = "block"
      tooltipbox.style.top = e.originalEvent.clientY + 10 + "px"
      tooltipbox.style.left = e.originalEvent.clientX + 10 + "px"
      tooltipInnerHTML =
        "<p>" +
        intl.get("add_mode.left_click") +
        "<br />" +
        intl.get("add_mode.double_click") +
        "</p>"
      tooltipContent.innerHTML = tooltipInnerHTML
    } else {
      tooltipbox.style.display = "none"
    }
    state.line.updateCoordinate(
      state.currentVertexPosition.toString(),
      lng,
      lat
    )
    state.snappedLng = lng
    state.snappedLat = lat

    this.updateUIClasses({ mouse: cursors.POINTER })
  },

  drawLine(state: AddModeState, isLeftClick: boolean) {
    isDrawingInprogress = true
    // We save some processing by rounding on click, not mousemove
    const lng = state.snappedLng
    const lat = state.snappedLat

    // End the drawing if this click is on the previous position
    // Note: not bothering with 'direction'
    if (state.currentVertexPosition > 0) {
      state.lastVertex = state.line.coordinates[state.currentVertexPosition - 1]

      if (isLeftClick) {
        const coords = [state.lastVertex]
        const geojson: NetworkGeometry = createLineStringFeature(coords)
        draw.add(geojson)
        deletePaths.push(geojson.id!.toString())
        state.completeLineCoordinates.push(coords)
      }
    }

    addPointTovertices(state.map, state.vertices, { lng, lat }, false)

    state.line.updateCoordinate(
      state.currentVertexPosition.toString(),
      lng,
      lat
    )
    state.currentVertexPosition++
    state.line.updateCoordinate(
      state.currentVertexPosition.toString(),
      lng,
      lat
    )
  },

  onStop(_: AddModeState) {},

  onKeyUp(state: AddModeState, e: any) {
    if (isUndo(e)) {
      if (state.currentVertexPosition > 0) {
        state.line.removeCoordinate(state.currentVertexPosition)
        state.currentVertexPosition--
        state.completeLineCoordinates.pop()
        state.startCords =
          state.line.coordinates[state.line.coordinates.length - 2]
      } else {
        undo()
      }
    } else if (isRedo(e)) {
      if (state.currentVertexPosition == 0) {
        redo()
      }
    } else if (isEsc(e)) {
      draw.delete(state.line.id.toString())
      state.currentVertexPosition = 0
      state.completeLineCoordinates = []
      state.line = newLine.call(this)
      isDrawingInprogress = false
    }
  },

  toDisplayFeatures(
    state: AddModeState,
    geojson: any,
    display: (geojson: GeoJSON) => void
  ) {
    addModeDisplyFeatures(state, geojson, display)
  },

  snapLineToRoad(state: AddModeState, e: any) {
    if (state.startCords.length === 0) {
      state.startCords = [e.lngLat.lng, e.lngLat.lat]
    } else {
      state.endCords = [e.lngLat.lng, e.lngLat.lat]
      this.shortestPath(state, () => {
        state.startCords = [e.lngLat.lng, e.lngLat.lat]
        state.endCords = [0, 0]
      })
    }
  },

  shortestPath(state: AddModeState, callback: () => void) {
    const start = state.startCords
    const end = state.endCords
    this.getShortestPath(start, end)
      .then((jsonData) => {
        if (jsonData.code === "Ok") {
          const routes = jsonData.routes
          if (routes && routes.length > 0) {
            let shortestDistance = routes[0].distance
            let shortestRoute = routes[0].geometry.coordinates
            for (const route of routes) {
              if (route.distance <= shortestDistance) {
                shortestDistance = route.distance
                shortestRoute = route.geometry.coordinates
              }
            }

            shortestRoute[0] = start
            const geojson = createLineStringFeature(shortestRoute)
            state.completeLineCoordinates.push(shortestRoute)
            draw.add(geojson)
            const pathId = geojson.id!.toString()
            const stackItem: MapEventStack = {
              stackState: [
                {
                  action: "create",
                  oldState: [],
                  newState: [geojson],
                },
              ],
              operation: "added_shortest_path",
              mode: "add_mode",
            }
            this.map.fire(MapEvents.CRUD, { stackItem })
            deletePaths.push(pathId)

            this.drawLine(state, false)
          } else {
            alert("No routes found.")
          }
        }
      })
      .catch((error) => {
        console.error("Error in getting shortest path:", error)
      })
    callback()
  },

  async getShortestPath(start: Position, end: Position): Promise<any> {
    const apiUrl =
      `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?alternatives=true&annotations=distance%2Cduration%2Cspeed&geometries=geojson&language=en&overview=simplified&steps=true&access_token=` +
      MAPBOX_ACCESS_TOKEN

    try {
      const response = await axios.get(apiUrl)
      return response.data
    } catch (error) {
      console.error("Error:", error)
      throw error
    }
  },
}

export default AddMode
