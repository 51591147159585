import React, { Component } from "react"

import { Link } from "react-router-dom"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import intl from "react-intl-universal"
import ConfigurationTabContent from "../components/user-management/ConfigurationTabContent"
import UsersTabContent from "../components/user-management/UsersTabContent"
import { AppContext } from "./App"

interface AdminDashboardState {
  tabIndex: number
}

class AdminDashboard extends Component<any, AdminDashboardState> {
  static contextType = AppContext
  private appContext: any
  constructor(props: any, context: any) {
    super(props)
    this.state = {
      tabIndex: 0,
    }
    this.appContext = context
  }

  updateLocale() {
    this.forceUpdate()
  }

  render() {
    const breadcrumb = (
      <div className="mb-4 text-xs">
        <Link to="/editor">
          <i
            className="fa fa-home"
            aria-hidden="true"
            style={{ color: "#7c7c7c" }}
          ></i>
        </Link>{" "}
        <span className="px-1">/</span> {intl.get("label.admin_dashboard")}
      </div>
    )

    return (
      <>
        <div className="mx-auto p-4 bg-neutral-800 text-white h-screen text-[14px]">
          {breadcrumb}
          <Tabs
            selectedIndex={this.state.tabIndex}
            onSelect={(index) => this.setState({ tabIndex: index })}
          >
            <TabList className="flex mb-4">
              <Tab className="mr-2 text-white rounded-md px-4 py-1 cursor-pointer ">
                {intl.get("tab.users")}
              </Tab>
              <Tab className="text-white rounded-md px-4 py-1 cursor-pointer">
                {intl.get("label.configuration")}
              </Tab>
            </TabList>
            <TabPanel>
              <UsersTabContent></UsersTabContent>
            </TabPanel>
            <TabPanel>
              <ConfigurationTabContent></ConfigurationTabContent>
            </TabPanel>
          </Tabs>
        </div>
      </>
    )
  }
}

export default AdminDashboard
