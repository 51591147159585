import React, { Component } from "react"
import intl from "react-intl-universal"
import Modal from "react-responsive-modal"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { generateInviteToken } from "../../services/user.service"
import { PulseLoader } from "react-spinners"

interface SendInviteModalPopupProps {
  isModalOpen: boolean
  handleModalClose: any
  id: string
  users: any
}

interface SendInviteModalPopupState {
  inviteUrl: string
  showInviteURL: boolean
  copied: boolean
}

class SendInviteModalPopup extends Component<
  SendInviteModalPopupProps,
  SendInviteModalPopupState
> {
  private sendInviteSchema = Yup.object().shape({
    inviteEmail: Yup.string()
      .email(intl.get("error.invalid_email"))
      .required(intl.get("error.required")),
    inviteRole: Yup.string().required(intl.get("error.required")),
  })
  constructor(props: SendInviteModalPopupProps) {
    super(props)
    this.state = {
      inviteUrl: "",
      showInviteURL: false,
      copied: false,
    }
    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.handleInviteSend = this.handleInviteSend.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  copyToClipboard() {
    navigator.clipboard
      .writeText(this.state.inviteUrl)
      .then(() => {
        this.setState({ copied: true })
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error)
      })
  }
  handleInviteSend(values, { setErrors, setSubmitting }) {
    const isUserExist = this.props.users.find(
      (user) => user.email === values.inviteEmail
    )

    if (isUserExist) {
      setErrors({ inviteEmail: intl.get("error.user_already_exist") })
      setSubmitting(false)
      return
    }
    const data: UserInvite = {
      email: values.inviteEmail,
      role: values.inviteRole,
      invitedBy: this.props.id,
    }

    generateInviteToken(data)
      .then((res) => {
        const invitetoken = res.data[0].id
        const generatedInviteUrl = `${window.location.origin}/signup?token=${invitetoken}`
        this.setState({
          inviteUrl: generatedInviteUrl,
          showInviteURL: true,
        })
      })
      .catch((err) => {
        console.error("Error:", err)
        setSubmitting(false)
      })
  }

  handleModalClose() {
    this.props.handleModalClose()
    this.setState({ showInviteURL: false })
  }

  render() {
    return (
      <Modal
        open={this.props.isModalOpen}
        onClose={this.handleModalClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        center={true}
        styles={{
          modal: {
            background: "#323232",
            color: "#fff",
            width: "30%",
            padding: 0,
            fontWeight: "bold",
            borderRadius: "4px",
          },
          closeButton: { top: 10, right: 6, fill: "white" },
        }}
      >
        <div className="modal-header">
          <span>{intl.get("header.generate_inite_url")}</span>
        </div>
        {this.state.showInviteURL ? (
          <div className="modal-body">
            <p className="invite-url">{this.state.inviteUrl}</p>
            <div className="action-buttons">
              {this.state.copied ? (
                <button className="submitModalButton">
                  <i className="fa fa-check" aria-hidden="true"></i>
                  {intl.get("button.copied")}
                </button>
              ) : (
                <button
                  className="submitModalButton"
                  onClick={this.copyToClipboard}
                >
                  <i className="fa fa-clipboard" aria-hidden="true"></i>
                  {intl.get("button.copy_url")}
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="modal-body">
            <Formik
              initialValues={{
                inviteEmail: "",
                inviteRole: "",
              }}
              validationSchema={this.sendInviteSchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                // Handle form submission
                this.handleInviteSend(values, { setErrors, setSubmitting })
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="key-value-pair me-2">
                    <label className="text-normal font-medium leading-6 text-gray-200">
                      {intl.get("label.email_id")}
                    </label>
                    <Field
                      className="font-normal"
                      type="mail"
                      name="inviteEmail"
                      placeholder={intl.get("label.email_id")}
                    />
                    {errors.inviteEmail && touched.inviteEmail ? (
                      <div className="text-red-500 mt-1 text-start font-normal">
                        {errors.inviteEmail}
                      </div>
                    ) : null}
                  </div>
                  <div className="key-value-pair me-2">
                    <label className="text-normal font-medium leading-6 text-gray-200">
                      {intl.get("label.role")}
                    </label>
                    <Field
                      as="select"
                      name="inviteRole"
                      className="mb-4 font-normal"
                    >
                      <option value="" disabled>
                        {intl.get("editor.select")}
                      </option>
                      <option value="admin">{intl.get("role.admin")}</option>
                      <option value="user">{intl.get("role.user")}</option>
                    </Field>
                    {errors.inviteRole && touched.inviteRole ? (
                      <div className="text-red-500 mt-1 text-start font-normal">
                        {errors.inviteRole}
                      </div>
                    ) : null}
                  </div>

                  <div className="action-buttons">
                    <button
                      type="button" // Ensure this button doesn't trigger form submission
                      className={"cancelModalButton"}
                      onClick={this.props.handleModalClose}
                    >
                      <span>{intl.get("button.cancel")}</span>
                    </button>
                    <button
                      type="submit" // This button will trigger form submission
                      disabled={isSubmitting}
                      className={"submitModalButton"}
                    >
                      {isSubmitting && (
                        <PulseLoader size={4} color="#fff" margin={1} />
                      )}
                      {isSubmitting
                        ? intl.get("button.generating")
                        : intl.get("button.generate")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Modal>
    )
  }
}

export default SendInviteModalPopup
