import React, { useEffect, useReducer, useState } from "react"
import Navbar from "../components/Navbar"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { CustomControl } from "../specs/Mapbox"
import { jwtDecode } from "jwt-decode"
import { rbacRules } from "../routes/rbac"
import NotFoundPage from "./NotFoundPage"
import Loader from "../components/Loader"
import request from "../services/api"

export const AppContext = React.createContext<
  | undefined
  | {
      setNavBarButtons: React.Dispatch<React.SetStateAction<CustomControl[]>>
      user: { [key in string]: any }
      setRunSimulationBtn: React.Dispatch<React.SetStateAction<CustomControl[]>>
      setProjectSelectionBtn: React.Dispatch<
        React.SetStateAction<CustomControl[]>
      >
    }
>(undefined)

function App() {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  const [buttons, setNavBarButtons] = React.useState<CustomControl[]>([])
  const [runSimulationBtn, setRunSimulationBtn] = React.useState<
    CustomControl[]
  >([])
  const [projectSelectionBtn, setProjectSelectionBtn] = React.useState<
    CustomControl[]
  >([])

  useEffect(() => {
    request.interceptors.request.use(
      function (config) {
        if (config.headers["x-show-loader"] == "true") {
          setLoading(true)
        }

        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    // Add a response interceptor
    request.interceptors.response.use(
      function (response) {
        setLoading(false)
        return response
      },
      function (error) {
        return Promise.reject(error)
      }
    )
  }, [])
  let user: User = {
    id: "",
    firstName: "",
    lastName: "",
    role: "user",
    email: "",
    organization: "",
  }
  const token = localStorage.getItem("token")
  if (token != null) {
    try {
      user = jwtDecode(token) as User
      let restrict: boolean = false
      rbacRules.forEach((e) => {
        if (location.pathname === e.path) {
          if (!e.role.includes(user.role)) {
            restrict = true
          }
        }
      })
      if (restrict) {
        return <NotFoundPage />
      }
      return (
        <>
          <Navbar
            updateLocale={forceUpdate}
            buttons={buttons}
            user={user}
            runSimulationBtn={runSimulationBtn}
            projectSelectionBtn={projectSelectionBtn}
          />
          <Loader show={loading}></Loader>
          <AppContext.Provider
            value={{
              setNavBarButtons,
              user,
              setRunSimulationBtn,
              setProjectSelectionBtn,
            }}
          >
            <Outlet />
          </AppContext.Provider>
        </>
      )
    } catch (e) {
      // Ignored
    }
  }
  return <Navigate to={"/login"} />
}

export default App
