import React, { Component } from "react"
import {
  cancelUserInviteByToken,
  getInvites,
} from "../../services/user.service"
import intl from "react-intl-universal"
import { showNotification } from "../../utils/Toaster"
import moment from "moment"
import DataTable from "react-data-table-component"
import ConfirmationModal from "../mapbox/ConfirmationPopup"
import { capitalize } from "../../utils/common"
import Tippy from "@tippyjs/react"

interface UserInvitesTableState {
  invitesData: any
  openconfirmationPopup: boolean
  id: string
  tableAction: string
  confirmationMsg: string
}

class UserInvitesTable extends Component<any, UserInvitesTableState> {
  constructor(props: any) {
    super(props)
    this.state = {
      invitesData: [],
      openconfirmationPopup: false,
      id: "",
      tableAction: "",
      confirmationMsg: "",
    }
    this.clearConfirmationPopup = this.clearConfirmationPopup.bind(this)
  }

  componentDidMount() {
    this.getInvitesData()
  }

  getInvitesData() {
    getInvites()
      .then((res) => {
        const responseData = res.data
        this.setState({
          invitesData: responseData,
        })
      })
      .catch((err) => {
        console.error("Error:", err)
      })
  }
  confirmationPopup(id: string, action: string) {
    let confirmationMsg = ""
    if (action === "cancelInvite") {
      confirmationMsg = intl.get("confirm.cancel_invite")
    }

    this.setState({
      openconfirmationPopup: true,
      id: id,
      tableAction: action,
      confirmationMsg: confirmationMsg,
    })
  }

  cancelUserInvite() {
    const token = this.state.id
    const data = { token: token, status: "cancelled" }
    cancelUserInviteByToken(data)
      .then((res) => {
        this.getInvitesData()
        this.clearConfirmationPopup()
        showNotification(
          intl.get("alert.invite_cancelled_successfully"),
          "success"
        )
      })
      .catch((err) => {
        console.log("error:", err)
      })
  }

  getDeleteUserOptionButtons() {
    return [
      {
        label: intl.get("button.no"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.clearConfirmationPopup()
        },
      },
      {
        label: intl.get("button.yes"),
        buttonClass: "submitModalButton",
        action: () => {
          if (this.state.tableAction === "cancelInvite") {
            this.cancelUserInvite()
          }
        },
      },
    ]
  }

  clearConfirmationPopup() {
    this.setState({
      openconfirmationPopup: false,
      id: "",
      tableAction: "",
      confirmationMsg: "",
    })
  }

  convertDateTime(date) {
    const dateInUTC = moment.utc(date)
    const dateInLocalTime = dateInUTC.local()
    return dateInLocalTime.format("YYYY-MM-DD HH:mm")
  }

  copyToClipboard(token: string) {
    const invitetoken = token
    const generatedInviteUrl = `${window.location.origin}/signup?token=${invitetoken}`
    navigator.clipboard.writeText(generatedInviteUrl)
  }

  render() {
    const invitesColumns = [
      // { name: "Sr No.", selector: (row) => row.id },
      {
        name: intl.get("table.sent_to"),
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: intl.get("label.role"),
        selector: (row) => capitalize(row.role),
      },
      {
        name: intl.get("table.status"),
        selector: (row) => capitalize(row.status),
      },
      {
        name: intl.get("table.invited_by"),
        selector: (row) => row.invitedByUser.email,
      },
      {
        name: intl.get("table.sent_on_date"),
        selector: (row) => this.convertDateTime(row.createdAt),
      },
      {
        name: intl.get("label.action"),
        cell: (row) => (
          <span>
            <Tippy
              content={"Cancel Invite"}
              placement={"bottom"}
              trigger={"mouseenter"}
              interactive={true}
              interactiveBorder={10}
              className="legend-btn-tooltip table-tooltip"
            >
              <button
                className={
                  row.status === "cancelled" || row.status === "accepted"
                    ? "disabled"
                    : ""
                }
                onClick={() => this.confirmationPopup(row.id, "cancelInvite")}
                disabled={
                  row.status === "cancelled" || row.status === "accepted"
                }
              >
                <i
                  className="fa fa-close tableDeleteButton"
                  aria-hidden="true"
                ></i>
              </button>
            </Tippy>
            <Tippy
              content={"Copy to Clipboard"}
              placement={"bottom"}
              trigger={"mouseenter"}
              interactive={true}
              interactiveBorder={10}
              className="legend-btn-tooltip table-tooltip"
            >
              <button
                className={"float-left mr-2"}
                onClick={() => this.copyToClipboard(row.id)}
                style={{
                  visibility: row.status === "invited" ? "visible" : "hidden",
                }}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
              </button>
            </Tippy>
          </span>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]

    const tableCustomStyles = {
      headRow: {
        style: {
          color: "#ffffff",
          backgroundColor: "#303030",
          fontWeight: "bold",
        },
      },
      rows: {
        style: {
          color: "#ffffff",
          backgroundColor: "#303030",
        },
        stripedStyle: {
          color: "#ffffff",
          backgroundColor: "#404040",
        },
      },
      pagination: {
        style: {
          backgroundColor: "#404040",
          color: "#ffffff",
        },
        pageButtonsStyle: {
          color: "#ffffff",
        },
      },
    }
    return (
      <div>
        {" "}
        <DataTable
          title="Invites"
          columns={invitesColumns}
          data={this.state.invitesData.reverse()}
          pagination
          striped
          customStyles={tableCustomStyles}
          fixedHeader
          fixedHeaderScrollHeight={"57vh"}
          responsive
        />
        <ConfirmationModal
          isModalOpen={this.state.openconfirmationPopup}
          buttons={this.getDeleteUserOptionButtons()}
          message={this.state.confirmationMsg}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
      </div>
    )
  }
}
export default UserInvitesTable
