import React from "react"
import App from "../pages/App"
import AdminDashboard from "../pages/AdminDashboard"
import { Route } from "react-router-dom"
import { Paths } from "./paths"

export const UserRoutes = (
  <Route path={Paths.user} element={<App />}>
    <Route path={Paths.user_admin} element={<AdminDashboard />}></Route>
  </Route>
)
