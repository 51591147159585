import MapboxDraw, { DrawFeature } from "@mapbox/mapbox-gl-draw"
import {
  clone,
  coordAll,
  lineSplit,
  lineString,
  point,
  Position,
} from "@turf/turf"
import mapboxgl from "mapbox-gl"
import { NetworkGeometry } from "../../specs/Networks"
import { GeoJSON } from "geojson"
import {
  CRUDStack,
  EditModeOptions,
  EditModeState,
  FeaturesAtPoint,
  IEditMode,
  MapEvents,
  SelectStack,
} from "../../specs/Mapbox"
import {
  isDelete,
  isEsc,
  isRedo,
  isUndo,
  redo,
  undo,
} from "../../utils/mapbox/Commons"
import {
  calculateTime,
  checkDisconnectedFeatures,
  getAllLineStringFeaturesEditMode,
  getDisconnectedFeatures,
  routeSelectionValue,
} from "../../utils/mapbox/FeaturesUtils"
import {
  clickActiveFeature,
  dragFeature,
  dragVertex,
  editModeDisplyFeatures,
  editModeRouteSelection,
  insideMouseMove,
  onFeature,
  onVertex,
  openContextMenu,
  popup,
  stopDragging,
} from "./CommonOperationMode"
import { getAllExistingLineStringFeatures } from "./AddMode"

const { doubleClickZoom, createSupplementaryPoints } = MapboxDraw.lib
const CommonSelectors = MapboxDraw.lib.CommonSelectors
const { isActiveFeature, isInactiveFeature, isOfMetaType, noTarget } =
  CommonSelectors
const Constants = MapboxDraw.constants
const isVertex = isOfMetaType(Constants.meta.VERTEX)
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT)
let draw: MapboxDraw
let overlappingFeatures: NetworkGeometry[]

const EditMode: IEditMode = {
  fireUpdate(
    state: EditModeState,
    oldState?: NetworkGeometry[],
    newState?: NetworkGeometry[]
  ) {
    const feature = state.featureOrg as NetworkGeometry
    if (
      feature.geometry.type === "LineString" &&
      state.selectedNetworkType.name !== "bike"
    ) {
      if (state.actionMode === "add_mode") {
        getDisconnectedFeatures(
          draw,
          getAllExistingLineStringFeatures,
          state.actionMode,
          state.selectedNetworkType,
          this.map
        )
      } else {
        const allFeatures = getAllLineStringFeaturesEditMode
          .map((d) => d as NetworkGeometry)
          .filter((f) => f.properties.line !== feature.properties.line)
        checkDisconnectedFeatures(
          draw,
          allFeatures,
          "edit_mode",
          state.selectedNetworkType,
          this.map
        )
      }
    }

    if (!oldState) {
      oldState = [state.featureOrg as NetworkGeometry]
    }
    if (!newState) {
      newState = this.getSelected().map((f) => f.toGeoJSON() as NetworkGeometry)
    } else {
      const getSelectedFeatures = this.getSelected().map(
        (f) => f.toGeoJSON() as NetworkGeometry
      )
      getSelectedFeatures.forEach((f) => {
        if (newState && f.id === newState[0].id) {
          newState[0].properties = f.properties
        }
      })
    }

    const stackItem: CRUDStack = {
      stackState: [
        {
          action: "update",
          oldState: oldState,
          newState: newState,
        },
      ],
      operation: "updated_coordinates",
      mode: "edit_mode",
    }
    this.map.fire(MapEvents.CRUD, {
      stackItem,
    })
  },

  onSetup(options: EditModeOptions): EditModeState {
    const featureId = options.featureId
    let feature: DrawFeature | undefined = undefined
    const featureIds: string[] = []
    if (featureId) {
      feature = this.getFeature(featureId)
      featureIds.push(featureId)
    }

    const state: EditModeState = {
      feature: feature,
      featureIds: featureIds,
      dragMoving: false,
      canDragMove: false,
      actionMode: options.actionMode,
      isRouteSelected: false,
      selectedCoordPaths: [],
      selectedNetworkType: options.selectedNetworkType,
      featureOrg: feature ? feature.toGeoJSON() : undefined,
      featuresAtPoint: [],
    }

    if (featureId) {
      this.setSelected(featureId)
      state.isRouteSelected = true
      if (feature) {
        const stackItem: SelectStack = {
          mode: "edit_mode",
          operation: "selection_change",
          features: [feature.toGeoJSON() as NetworkGeometry],
          properties: {},
        }
        this.map.fire(MapEvents.SELECTION_CHANGE, {
          stackItem,
        })
      }
    }
    doubleClickZoom.disable(this)

    if (state.actionMode == "add_mode" && options.event) {
      this.clickInactive(state, options.event, false)
    }

    draw = this["_ctx"]["api"]
    return state
  },

  onClick(state: EditModeState, e: any) {
    const isShiftClick = CommonSelectors.isShiftDown(e)

    // Reset selection
    if (noTarget(e)) this.clickNoTarget(state, e, isShiftClick)
    // if feature is selected, Add stop or control point
    else if (isActiveFeature(e))
      clickActiveFeature.call(this, e, isShiftClick, state, draw)
    // if feature is not selected, then select feature
    else if (isInactiveFeature(e)) this.clickInactive(state, e, isShiftClick)

    const stackItem: SelectStack = {
      mode: "edit_mode",
      operation: "selection_change",
      features: this.getSelected().map((d) => d.toGeoJSON() as NetworkGeometry),
      properties: {},
    }

    if (state.actionMode === "edit_mode") {
      this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
    }
  },

  onMouseMove(state: EditModeState, e: MapboxDraw.MapMouseEvent) {
    // On mousemove that is not a drag, stop vertex movement.
    insideMouseMove.call(this, state, e)
  },

  onDrag(state: EditModeState, e: any) {
    popup.remove()
    const tooltipbox: HTMLElement = document.querySelector(".mouseTooltip")!
    tooltipbox.style.display = "none"
    if (!state.canDragMove) return
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation!.lng,
      lat: e.lngLat.lat - state.dragMoveLocation!.lat,
    }
    if (state.selectedCoordPaths.length > 0) {
      dragVertex.call(this, state, e, delta)
    } else {
      dragFeature.call(this, state, e, delta, draw)
    }

    state.dragMoveLocation = e.lngLat
  },

  fireActionable(_: EditModeState) {},

  clickNoTarget: function (
    state: EditModeState,
    e: mapboxgl.MapMouseEvent,
    isShiftClick: boolean
  ): void {
    // No action in case of shift
    if (isShiftClick) {
      return
    }

    if (state.actionMode === "add_mode") {
      popup.remove()
      this.changeMode("add_mode", {
        selectedNetworkType: state.selectedNetworkType,
        init: false,
      })
      const stackItem: SelectStack = {
        mode: "add_mode",
        operation: "route_selection",
        features: [],
        properties: {},
      }
      this.map.fire(MapEvents.ROUTE_SELECTED, {
        stackItem,
      })
    } else {
      state.feature = undefined
      state.featureIds = []
      this.clearSelectedFeatures()
      popup.remove()
    }
  },

  clickInactive: function (
    state: EditModeState,
    e: any,
    isShiftClick: boolean
  ): void {
    const featureId = e.featureTarget.properties.id
    state.featureId = featureId

    let feature = this.getFeature(featureId)
    // Route selection
    if (
      !state.isRouteSelected &&
      state.selectedNetworkType.name !== "bike" &&
      !routeSelectionValue
    ) {
      if (feature.type === "Point") {
        const features = this.featuresAt(e, [0, 0, 0, 0], "click").filter(
          (f: any) => f.geometry.type === "LineString"
        )
        if (features.length == 0) {
          return
        }
        feature = this.getFeature(features[0].properties!.id.toString())
      }
      editModeRouteSelection.call(this, state, e, feature, draw)
      return
    } else if (isShiftClick) {
      // Multiple feature selection
      state.featureIds.push(featureId)
      this.select(featureId)
      if (state.actionMode === "add_mode") {
        const stackItem: SelectStack = {
          mode: "edit_mode",
          operation: "selection_change",
          features: this.getSelected().map(
            (d) => d.toGeoJSON() as NetworkGeometry
          ),
          properties: {},
        }

        this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
      }
    } else {
      // Feature selection
      const featuresAt = this.featuresAt(e, [0, 0, 0, 0], "click").map(
        (f) => this.getFeature(f.properties!.id).toGeoJSON() as NetworkGeometry
      )

      if (feature.type === "Point") {
        state.featureIds = [featureId]
        state.featuresAtPoint = featuresAt
          .filter((f) => f.geometry.type === "LineString")
          .map((f) => {
            const coordinates = f.geometry.coordinates as Position[]
            let idx = coordinates.findIndex(
              (p) =>
                p[0] === feature.getCoordinates()[0] &&
                p[1] === feature.getCoordinates()[1]
            )
            if (idx != 0 && idx != coordinates.length - 1) {
              idx = -1
            }
            return {
              feature: f,
              coordIdx: idx,
            } as FeaturesAtPoint
          })
          .filter((f) => f.coordIdx !== -1)
        const stackItem: SelectStack = {
          mode: "edit_mode",
          operation: "selection_change",
          features: [this.getFeature(featureId).toGeoJSON() as NetworkGeometry],
          properties: {},
        }
        this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
      } else {
        openContextMenu.call(this, e, state, draw)
      }
    }
    state.feature = feature
    state.featureOrg = feature.toGeoJSON()
    state.featureIds = [featureId]
    if (!isShiftClick) {
      this.setSelected(featureId)
    }
  },

  onContextMenuClick: function (
    this: MapboxDraw.DrawCustomModeThis & IEditMode,
    state: EditModeState,
    e: any
  ): void {
    const featureId = e.target.id
    this.changeMode("edit_mode", {
      featureId: featureId,
      actionMode: state.actionMode,
      selectedNetworkType: state.selectedNetworkType,
    })
    popup.remove()
  },

  onMouseUp(state: EditModeState, _: MapboxDraw.MapMouseEvent) {
    this.handleDraw(state)
  },

  onTouchEnd(state: EditModeState, _: MapboxDraw.MapTouchEvent) {
    this.handleDraw(state)
  },

  handleDraw(state: EditModeState) {
    if (state.dragMoving) {
      const feat = state.feature?.toGeoJSON() as NetworkGeometry
      if (Object.prototype.hasOwnProperty.call(feat.properties, "speed")) {
        const time = calculateTime(feat.geometry, feat.properties)
        state.feature?.setProperty("time_min", time)
      }
      const oldState = [state.featureOrg as NetworkGeometry]
      state.feature?.setProperty("has_improvement", true)
      const newState = [state.feature?.toGeoJSON() as NetworkGeometry]
      if (state.feature?.type === "Point") {
        const position = state.feature.getCoordinate()
        if (state.featuresAtPoint.length == 2) {
          const fc: any = {
            type: "FeatureCollection",
            features: state.featuresAtPoint.map((f) => f.feature),
          }
          const lineSplitFC = lineSplit(
            lineString(coordAll(fc)),
            point(position)
          )
          if (lineSplitFC.features.length == 2) {
            state.featuresAtPoint.forEach((f, idx) => {
              oldState.push(clone(f.feature))
              f.feature.geometry.coordinates =
                lineSplitFC.features[idx].geometry.coordinates
              newState.push(f.feature)
            })
          }
        }
      }
      draw.add({
        type: "FeatureCollection",
        features: newState,
      })
      this.fireUpdate(state, oldState, newState)
    }
    stopDragging.call(this, state)
  },

  onMouseOut(state: EditModeState, _: MapboxDraw.MapMouseEvent) {
    // As soon as you mouse leaves the canvas, update the feature
    if (state.dragMoving) this.fireUpdate(state)

    // Skip render
    return true
  },

  onStop(_: EditModeState) {
    doubleClickZoom.enable(this)
    this.clearSelectedCoordinates()
  },

  onTouchStart(state: EditModeState, e: MapboxDraw.MapTouchEvent) {
    this.startDraw(state, e)
  },

  onMouseDown(state: EditModeState, e: any) {
    this.startDraw(state, e)
  },

  startDraw(state: EditModeState, e: any) {
    if (isVertex(e)) return onVertex.call(this, state, e)
    if (isActiveFeature(e)) return onFeature.call(this, state, e)
  },

  toDisplayFeatures(
    state: EditModeState,
    geojson: any,
    display: (geojson: GeoJSON) => void
  ) {
    editModeDisplyFeatures(state, geojson, display)
    this.fireActionable(state)
  },

  onKeyUp(state: EditModeState, e: any) {
    if (isUndo(e)) {
      undo()
    } else if (isRedo(e)) {
      redo()
    } else if (isDelete(e)) {
      if (this.getSelected().length > 0) {
        draw.delete(this.getSelectedIds())
      }
      state.featureIds = []
      state.featureId = undefined
    } else if (isEsc(e)) {
      state.isRouteSelected = false
      state.featureId = undefined
      state.featureIds = []
      this.clearSelectedFeatures()
      if (state.actionMode !== "add_mode") {
        draw.getAll().features.forEach((element) => {
          draw.delete(element.id!.toString())
        })
        this.map.fire(MapEvents.ROUTE_DESELECTED, {})
      } else {
        const stackItem: SelectStack = {
          mode: "add_mode",
          operation: "selection_change",
          features: [],
          properties: {},
        }

        this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
      }
    }
  },
}

export default EditMode
