import React from "react"
import ReactDOM from "react-dom/client"
import "mapbox-gl/dist/mapbox-gl.css"
import "./index.scss"
import intl from "react-intl-universal"
import { LOCALES_DATA } from "./i18n"
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom"
import { PublicRoutes } from "./routes/public"
import { UserRoutes } from "./routes/user"
import { EditorRoutes } from "./routes/editor"
import Loader from "./components/Loader"

const initializeIntl = () => {
  // 1. Get the currentLocale from url, cookie, or browser setting
  let currentLocale = intl.determineLocale({
    urlLocaleKey: "lang",
    cookieLocaleKey: "lang",
    localStorageLocaleKey: "lang",
  })

  if (currentLocale.includes("-")) {
    currentLocale = currentLocale.substring(0, currentLocale.indexOf("-"))
  }

  // 2. Fallback to "en" if the currentLocale isn't supported in LOCALES_LIST
  if (Object.keys(LOCALES_DATA).indexOf(currentLocale) === -1) {
    currentLocale = "en"
  }

  // 3. Set currentLocale and load locale data
  setCurrentLocale(currentLocale)
}

const setCurrentLocale = (currentLocale: string) => {
  intl
    .init({
      currentLocale,
      locales: LOCALES_DATA,
    })
    .then(() => {})
}

initializeIntl()

export const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
  <RouterProvider
    router={createBrowserRouter(
      createRoutesFromElements([PublicRoutes, EditorRoutes, UserRoutes])
    )}
    fallbackElement={<Loader />}
  />
)
