import React, { Component } from "react"
import intl from "react-intl-universal"
import { login } from "../services/user.service"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { PulseLoader } from "react-spinners"

class Login extends Component<any, any> {
  private LoginSchema = Yup.object().shape({
    username: Yup.string()
      .email(intl.get("error.invalid_email"))
      .required(intl.get("error.required")),
    password: Yup.string()
      .min(6, intl.get("error.password_length"))
      .required(intl.get("error.required")),
  })

  constructor(props) {
    super(props)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit(values, { setErrors, setSubmitting }) {
    login(values.username, values.password)
      .then((res) => {
        const token = res.data
        localStorage.setItem("token", token)
        window.location.assign("/editor")
      })
      .catch((err) => {
        setSubmitting(false)
        if (err.response.status === 401) {
          setErrors({ responseError: intl.get("error.credentials_not_valid") })
        }
      })
  }

  render() {
    return (
      <div className="flex min-h-full h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-neutral-800 px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-[400px]">
          <div className="bg-neutral-900 px-6 py-6 shadow rounded-lg border-gradient">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="text-center text-xl font-bold leading-9 tracking-tight text-white">
                {intl.get("navbar_title")}
              </h2>
            </div>
            <Formik
              initialValues={{
                username: "",
                password: "",
                responseError: "",
              }}
              validationSchema={this.LoginSchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                this.handleFormSubmit(values, { setErrors, setSubmitting })
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="key-value-pair">
                    <label
                      htmlFor="username"
                      className="text-normal font-medium leading-6 text-gray-200"
                    >
                      {intl.get("label.email_id")}
                    </label>
                    <Field
                      name="username"
                      autoComplete="username"
                      placeholder={intl.get("label.email_id")}
                    />
                    {errors.username && touched.username ? (
                      <div className="text-red-500 mt-1 text-start">
                        {errors.username}
                      </div>
                    ) : null}
                  </div>
                  <div className="key-value-pair">
                    <label
                      htmlFor="password"
                      className="text-normal font-medium leading-6 text-gray-200"
                    >
                      {intl.get("label.password")}
                    </label>
                    <Field
                      name="password"
                      type="password"
                      autoComplete="password"
                      placeholder={intl.get("label.password")}
                    />
                    {errors.password && touched.password ? (
                      <div className="text-red-500 mt-1 text-start">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting} // Disable button when submitting
                    className={`mt-6  w-full submitModalButton`}
                  >
                    {isSubmitting && (
                      <PulseLoader size={4} color="#fff" margin={1} />
                    )}
                    {isSubmitting
                      ? intl.get("label.signing_in")
                      : intl.get("label.sign_in")}
                  </button>
                  {errors.responseError && (
                    <div className="text-red-500 mt-2 text-start text-xs">
                      {errors.responseError}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
