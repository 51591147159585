import { Map } from "mapbox-gl"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {
  BaselineType,
  getStyleForLayer,
  Network,
  NetworkData,
  NetworkType,
} from "../../specs/Networks"
import { MapboxInfoBoxControl } from "mapbox-gl-infobox"
import React from "react"
import ReactDomServer from "react-dom/server"
import intl from "react-intl-universal"
import { generateLayerId, generateSourceId } from "./Layers"
import EditMode from "../../components/mapbox/EditMode"
import AddMode from "../../components/mapbox/AddMode"
import ExtendExistingMode from "../../components/mapbox/ExtendExistingMode"
import styles from "../../style.module.scss"

interface InfoBoxOptions {
  layerId: string
  formatter: (properties: any) => string
}

function buildLayersForNetwork(
  map: Map,
  network: Network,
  networkData: NetworkData[]
) {
  const layerIds: string[] = []
  const sourceIds: string[] = []
  network.networkTypes.forEach((networkType) => {
    networkType.baselines.forEach((baselineType) => {
      const sourceId: string = generateSourceId(networkType, baselineType)
      const source = map.getSource(sourceId)
      if (source == undefined) {
        sourceIds.push(sourceId)
        map.addSource(sourceId, {
          type: "geojson",
          tolerance: 0,
          data: networkData.find(
            (d) =>
              d.networkType === networkType && d.baselineType == baselineType
          )?.data,
        })
      } else {
        if (source.type === "geojson") {
          const data = networkData.find(
            (d) =>
              d.networkType === networkType && d.baselineType == baselineType
          )?.data
          source.setData(data as GeoJSON.FeatureCollection<GeoJSON.Geometry>)
        }
      }
      const layerId = generateLayerId(networkType, baselineType)
      if (map.getLayer(layerId) == undefined) {
        layerIds.push(layerId)
        map.addLayer({
          ...getStyleForLayer(layerId, networkType, baselineType),
          source: sourceId,
        })
        //addPopUp(map, layerId, baselineType, networkType)
      }
    })
  })
  return { layerIds, sourceIds }
}

function drawingControl() {
  return new MapboxDraw({
    displayControlsDefault: false,
    modes: {
      ...MapboxDraw.modes,
      edit_mode: EditMode,
      add_mode: AddMode,
      extend_existing_mode: ExtendExistingMode,
    },
    userProperties: true,
    // Styling guides
    styles: [
      {
        id: "gl-draw-line",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["!=", "mode", "static"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          "line-sort-key": 1,
        },
        paint: {
          "line-color": [
            "match",
            ["get", "user_is_disconnected"],
            "true",
            "#FF0000",
            "#FFFFFF",
          ],
          "line-width": [
            "match",
            ["get", "user_category"],
            "Urban Mixed Roads",
            1,
            "Other Mixed Roads",
            1,
            "BFF",
            3,
            "Fietssnelweg",
            5,
            5,
          ],
        },
      },
      {
        id: "gl-draw-line-editable",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["!=", "mode", "static"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          "line-sort-key": 1,
        },
        paint: {
          "line-color": [
            "match",
            ["get", "user_is_disconnected"],
            "true",
            "#FF0000",
            "#FFFFFF",
          ],
          "line-width": [
            "match",
            ["get", "user_category"],
            "Urban Mixed Roads",
            1,
            "Other Mixed Roads",
            1,
            "BFF",
            3,
            "Fietssnelweg",
            5,
            5,
          ],
        },
      },
      {
        id: "gl-draw-point",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["!=", "mode", "static"]],
        paint: {
          "circle-color": "#303030",
          "circle-radius": ["match", ["get", "user_is_endPoint"], "true", 5, 4],
          "circle-stroke-color": [
            "match",
            ["get", "user_is_endPoint"],
            "true",
            styles.highlightingColor,
            "#FFFFFF",
          ],
          "circle-stroke-width": [
            "match",
            ["get", "user_is_endPoint"],
            "true",
            3,
            2,
          ],
        },
        layout: {
          "circle-sort-key": 10,
        },
      },
      {
        id: "gl-draw-line-vertex-editable",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"],
        ],
        paint: {
          "circle-radius": 3,
          "circle-stroke-width": 2,
          "circle-color": styles.highlightingColor,
          "circle-stroke-color": "#FFFFFF",
        },
        layout: {
          "circle-sort-key": 10,
        },
      },
      {
        id: "guide",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "user_isSnapGuide", "true"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          "line-sort-key": 1,
        },
        paint: {
          "line-color": "#FFC95F",
          "line-width": 5,
        },
      },
      {
        id: "gl-draw-line-color-picker",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["has", "user_portColor"],
        ],
        paint: {
          "line-color": ["get", "user_portColor"],
          "line-width": 3,
        },
        layout: {
          "line-sort-key": 1,
        },
      },
      {
        id: "gl-draw-point-color-picker",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["has", "user_portColor"]],
        paint: {
          "circle-radius": 4,
          "circle-color": ["get", "user_portColor"],
        },
        layout: {
          "circle-sort-key": 10,
        },
      },
      {
        id: "gl-draw-line-active",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "active", "true"],
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          "line-sort-key": 0,
        },
        paint: {
          "line-color": styles.highlightingColor,
          "line-dasharray": [0.9, 2],
          "line-width": 3,
        },
      },
      {
        id: "gl-draw-point-active",
        type: "circle",
        filter: [
          "all",
          ["==", "$type", "Point"],
          ["!=", "meta", "midpoint"],
          ["==", "active", "true"],
        ],
        paint: {
          "circle-color": "#303030",
          "circle-radius": 4,
          "circle-stroke-color": styles.highlightingColor,
          "circle-stroke-width": 2,
        },
        layout: {
          "circle-sort-key": 10,
        },
      },
    ],
  })
}

let infoBoxControl: MapboxInfoBoxControl | null = null // Reference to the MapboxInfoBoxControl

function addPopUp(
  map: Map,
  layerId: string,
  baselineType: BaselineType,
  networkType: NetworkType
) {
  const infoboxOptions: InfoBoxOptions = {
    layerId: "", // Initialize with an empty layerId
    formatter: (properties) => {
      return properties
        ? generateHTML(properties, networkType, baselineType)
        : ""
    },
  }

  map.on("mouseenter", layerId, (e) => {
    if (e.features && e.features.length > 0 && !infoBoxControl) {
      infoboxOptions.layerId = layerId // Update the layerId in infoboxOptions
      infoBoxControl = new MapboxInfoBoxControl(infoboxOptions)
      map.addControl(infoBoxControl)
    }
  })
  map.on("mouseout", layerId, () => {
    if (infoBoxControl) {
      map.removeControl(infoBoxControl)
      infoBoxControl = null
    }
  })
}

function generateHTML(
  properties: {
    [key: string]: any
  },
  networkType: NetworkType,
  baselineType: BaselineType
): string {
  const ele: any = (
    <>
      <div className="infobox_title">
        <span>
          <i
            style={{ color: networkType.color }}
            className="fa-solid fa-circle infobox-network-type"
          />
        </span>
        <b>
          {intl.get("network_type." + networkType.name)} - {baselineType}
        </b>
      </div>
      <table className="propertyDetailsTable">
        <tbody>
          {networkType.baselineProperties[baselineType]?.properties.map(
            (property) => {
              return (
                <tr key={property.propertyName}>
                  <th style={{ textAlign: "left" }}>
                    {intl.get("properties." + property.localeKey)}
                  </th>
                  {property.datatype === "boolean" ? (
                    <td>
                      {properties[property.propertyName] == "0"
                        ? "False"
                        : "True"}
                    </td>
                  ) : (
                    <td>{properties[property.propertyName]}</td>
                  )}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </>
  )
  return ReactDomServer.renderToString(ele)
}

export { addPopUp, buildLayersForNetwork, drawingControl, generateHTML }
