import React, { Component } from "react"
import mapboxgl, { Map } from "mapbox-gl"
import { buildLayersForNetwork } from "../utils/mapbox/MapboxRenderHelper"
import { Network, NetworkData } from "../specs/Networks"
import { MAPBOX_ACCESS_TOKEN } from "../constants/config"
import "react-input-range/lib/css/index.css"
import "react-responsive-modal/styles.css"
import "tippy.js/dist/tippy.css"
import { bbox } from "@turf/turf"
import { FeatureCollection } from "geojson"
import { AppContext } from "./App"

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

interface MapboxCanvasState {
  networkData: NetworkData[]
  mapLoaded: boolean
  mapRendered: boolean
}

interface MapboxCanvasProps {
  network: Network
  lng: number
  lat: number
  zoom: number
}

export default class MapboxCanvas extends Component<
  MapboxCanvasProps,
  MapboxCanvasState
> {
  static contextType = AppContext
  private map!: Map
  private readonly mapContainer: React.RefObject<HTMLDivElement>

  constructor(props: any) {
    super(props)
    this.state = {
      networkData: [],
      mapLoaded: false,
      mapRendered: false,
    }
    this.mapContainer = React.createRef<HTMLDivElement>()
    this.handleLoad = this.handleLoad.bind(this)
    this.fetchNetworkData = this.fetchNetworkData.bind(this)
    this.fitMap = this.fitMap.bind(this)
  }

  componentDidMount() {
    this.map = new Map({
      container: this.mapContainer.current as HTMLDivElement,
      style: "mapbox://styles/tarnekar/clj5ajr3d001b01p96rr693b9",
      center: [this.props.lng, this.props.lat],
      zoom: this.props.zoom,
      preserveDrawingBuffer: true,
    })
    this.map.getCanvas().style.cursor = "pointer"
    this.fetchNetworkData()

    // Event Handlers
    this.map.on("load", this.handleLoad)
  }

  fetchNetworkData() {
    const networkData: NetworkData[] = this.props.network.loadNetworkData()
    this.setState({ networkData }, async () => {
      while (!this.state.mapLoaded || networkData.length == 0) {
        await sleep(250)
      }
      buildLayersForNetwork(this.map, this.props.network, networkData)
      this.fitMap()
      setTimeout(() => this.setState({ mapRendered: true }), 1000)
    })
  }

  fitMap() {
    const allFeatures = this.state.networkData.flatMap((d) => d.data.features)
    const filteredFeatures = allFeatures.filter(
      (f) => f.properties!.has_improvement === true
    )
    this.fitBounds({
      type: "FeatureCollection",
      features: filteredFeatures,
    })
  }

  handleLoad() {
    this.setState({
      mapLoaded: true,
    })
  }

  fitBounds(featureCollection: FeatureCollection) {
    if (featureCollection.features.length > 0) {
      const bounds: any = bbox(featureCollection)
      this.map.fitBounds(bounds, {
        padding: 100,
      })
    }
  }

  render() {
    return (
      <div
        ref={this.mapContainer}
        className={`map-container ${this.state.mapRendered ? "rendered" : ""}`}
      ></div>
    )
  }
}
