import { BaselineType, NetworkType } from "../../specs/Networks"

export function generateSourceId(
  networkType: NetworkType,
  baselineType: BaselineType
): string {
  return generateId([networkType.name, baselineType, "data"])
}

export function generateLayerId(
  networkType: NetworkType,
  baselineType: BaselineType
) {
  return generateId([networkType.name, baselineType, "layer"])
}

function generateId(arr: string[]): string {
  return arr.join("-").toLowerCase()
}
