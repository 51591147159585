import { Paths } from "./paths"

interface Rule {
  path: string
  role: Array<"admin" | "user">
}

export const rbacRules: Rule[] = [
  {
    path: Paths.user_admin,
    role: ["admin"],
  },
]
