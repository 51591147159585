import { PropagateLoader } from "react-spinners"
import React from "react"

interface LoaderProps {
  show?: boolean
}

export default class Loader extends React.Component<LoaderProps, any> {
  render() {
    return (
      this.props.show && (
        <div id="overlay">
          <PropagateLoader color="#2196f3" />
        </div>
      )
    )
  }
}
