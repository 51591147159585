import { NetworkData } from "../specs/Networks"
import { Feature } from "geojson"
import { geoJSONProject } from "@edugis/proj-convert"
import { v4 as uuid } from "uuid"
import request from "./api"

export function dumpGeoJSONToS3(
  networkData: NetworkData[],
  crsName: string,
  crs: any,
  callback: (result: any) => void
) {
  const result: any = {}
  let bikeNetwork: Feature[] = []
  let stops: Feature[] = []
  let network: Feature[] = []
  JSON.parse(JSON.stringify(networkData)).forEach((nd: NetworkData) => {
    if (nd.networkType.name === "bike") {
      bikeNetwork = bikeNetwork.concat(nd.data.features)
    } else {
      if (nd.baselineType === "Stops") {
        stops = stops.concat(nd.data.features)
      } else if (nd.baselineType === "Network") {
        network = network.concat(nd.data.features)
      }
    }
  })

  const formData = new FormData()
  formData.append(
    "file0",
    new Blob([generateGeoJson(bikeNetwork, crsName, crs)], {
      type: "application/octet-stream",
    }),
    `baseline_bike_edges.geojson`
  )
  formData.append(
    "file1",
    new Blob([generateGeoJson(stops, crsName, crs)], {
      type: "application/octet-stream",
    }),
    `baseline_transit_nodes.geojson`
  )
  formData.append(
    "file2",
    new Blob([generateGeoJson(network, crsName, crs)], {
      type: "application/octet-stream",
    }),
    `baseline_transit_edges.geojson`
  )
  const uuidStr = uuid()
  request
    .post(`aws/upload?path=networks/temp/${uuidStr}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.status == 200) {
        result.status = 200
        result.uuid = uuidStr
      }
      setTimeout(() => callback(result), 1000)
    })
    .catch((err) => {
      result.status = err.status
      result.text = err.string
      callback(result)
    })
}

function generateGeoJson(features: Feature[], crsName: string, crs: any) {
  const ele = geoJSONProject(
    {
      type: "FeatureCollection",
      features: features,
    },
    "EPSG:4326",
    crsName
  )
  ele.crs = crs
  return JSON.stringify(ele)
}
