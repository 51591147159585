import MapboxDraw, { DrawLineString, lib } from "@mapbox/mapbox-gl-draw"
import React from "react"
import {
  CRUDStack,
  FeaturesAtPoint,
  MapEvents,
  SelectStack,
} from "../../specs/Mapbox"
import { NetworkGeometry } from "../../specs/Networks"
import {
  calculateSpeed,
  calculateTime,
  createPointFeature,
  getAllFeaturesWithSameRouteId,
  getAllRouteFeatures,
  snapPointToNearbyLine,
} from "../../utils/mapbox/FeaturesUtils"
import ReactDomServer from "react-dom/server"
import intl from "react-intl-universal"
import {
  lineSplit,
  lineString,
  nearestPointOnLine,
  point,
  round,
  truncate,
} from "@turf/turf"
import { GEOJSON_COORD_PRECISION } from "../../constants/config"
import { snap } from "../../utils/mapbox/Snapping"
import mapboxgl from "mapbox-gl"

const CommonSelectors = MapboxDraw.lib.CommonSelectors
const { isActiveFeature, isInactiveFeature, isOfMetaType, noTarget } =
  CommonSelectors
const Constants = MapboxDraw.constants
const isVertex = isOfMetaType(Constants.meta.VERTEX)
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT)
const { geojsonTypes, cursors } = MapboxDraw.constants
const { doubleClickZoom, createVertex, createSupplementaryPoints } =
  MapboxDraw.lib
export const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
})
type Position = number[]

function editModeRouteSelection(
  this: any,
  state: any,
  e: any,
  feature: any,
  draw: any
) {
  popup.remove()
  const featuresAt = this.featuresAt(e, [0, 0, 0, 0], "click")
    .map(
      (f: any) =>
        this.getFeature(f.properties!.id).toGeoJSON() as NetworkGeometry
    )
    .filter((f: any) => f.geometry.type === "LineString")
    .filter(function (x, i, a) {
      return a.findIndex((y) => y.properties.line === x.properties.line) === i
    })

  if (featuresAt.length > 1 && !e.skipContextMenu) {
    const element = getContextMenuForFeatures(
      state,
      featuresAt,
      feature.toGeoJSON() as NetworkGeometry
    )

    const div = document.createElement("div")
    div.innerHTML = ReactDomServer.renderToStaticMarkup(element)
    div.querySelectorAll(".context-menu-item").forEach((e: any) =>
      e.addEventListener("click", (event: any) => {
        onRouteSelectionContextMenuClick.call(
          this,
          state,
          event,
          featuresAt,
          draw
        )
      })
    )
    popup.setLngLat(e.lngLat).setDOMContent(div).addTo(this.map)
    return
  }

  const routeId = state.selectedNetworkType.getPropertyFromFeature(
    feature.toGeoJSON() as NetworkGeometry,
    "routeId"
  )
  if (!routeId) {
    return
  }
  const filteredFeatures = getAllFeaturesWithSameRouteId(
    state.selectedNetworkType,
    draw,
    routeId,
    state.actionMode
  )

  state.isRouteSelected = true

  const stackItem: SelectStack = {
    mode: state.actionMode,
    operation: "route_selection",
    features: filteredFeatures,
    properties: {
      routeId: routeId,
    },
  }
  this.map.fire(MapEvents.ROUTE_SELECTED, {
    stackItem,
  })

  if (state.actionMode === "extend_existing_mode") {
    getAllRouteFeatures(draw)
  }

  return [filteredFeatures, routeId]
}

function getContextMenuForFeatures(
  state: any,
  features: NetworkGeometry[],
  selectedFeature: NetworkGeometry
) {
  return (
    <div id="contextMenu" className="context-menu">
      <p className="context-menu-title">{intl.get("editor.select_feat_msg")}</p>
      <div className="elementContainer">
        {features.map((feature: any) => {
          return state.isRouteSelected ||
            state.selectedNetworkType.name == "bike" ? (
            <div
              key={feature.id}
              id={feature.id!.toString()}
              className={`context-menu-style context-menu-item ${
                feature.id === selectedFeature.id ? "selected" : ""
              }`}
            >
              <i
                className={`fa-solid ${
                  feature.geometry.type === "Point"
                    ? "fa-circle-dot"
                    : "fa-road"
                }`}
              />
              {state.selectedNetworkType.getPropertyFromFeature(feature, "id") +
                " - " +
                state.selectedNetworkType.getPropertyFromFeature(
                  feature,
                  "label"
                )}
            </div>
          ) : (
            <div
              key={feature.id}
              id={feature.id!.toString()}
              className={`context-menu-style context-menu-item`}
            >
              <i
                className={`fa-solid ${
                  feature.geometry.type === "Point"
                    ? "fa-circle-dot"
                    : "fa-road"
                }`}
              />
              {state.selectedNetworkType.getPropertyFromFeature(
                feature,
                "routeId"
              ) +
                " " +
                state.selectedNetworkType.getPropertyFromFeature(
                  feature,
                  "label"
                )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function onRouteSelectionContextMenuClick(
  this: any,
  state: any,
  e: any,
  featuresAt: any,
  draw: any
) {
  popup.remove()
  const featureId = e.target.id
  const overlappingFeatures = featuresAt.filter((f) => f.id !== featureId)

  let feature = this.getFeature(featureId)
  if (feature.type === "Point") {
    const features = this.featuresAt(e, [0, 0, 0, 0], "click").filter(
      (f: any) => f.geometry.type === "LineString"
    )
    if (features.length == 0) {
      return
    }
    feature = this.getFeature(features[0].properties!.id.toString())
  }
  const routeId = state.selectedNetworkType.getPropertyFromFeature(
    feature.toGeoJSON() as NetworkGeometry,
    "routeId"
  )
  if (!routeId) {
    return
  }
  const filteredFeatures = getAllFeaturesWithSameRouteId(
    state.selectedNetworkType,
    draw,
    routeId,
    state.actionMode
  )

  state.isRouteSelected = true

  const stackItem: SelectStack = {
    mode: "edit_mode",
    operation: "route_selection",
    features: filteredFeatures,
    properties: {
      routeId: routeId,
      overlappingFeatures: overlappingFeatures,
    },
  }
  this.map.fire(MapEvents.ROUTE_SELECTED, {
    stackItem,
  })

  if (state.actionMode === "extend_existing_mode") {
    this.getEndPoints()
    getAllRouteFeatures(draw)
    state.routeId = routeId
    state.routeFeatures = filteredFeatures
    state.line = this.newLine()
    state.currentVertexPosition = 0
    state.completeLineCoordinates = []
  }
  return
}

function addStop(this: any, state: any, e: any, draw: any): void {
  if (!e.featureTarget) {
    return
  }
  const feature = this.getFeature(e.featureTarget.properties.id)
  if (feature.type !== "LineString" && feature.type !== "MultiLineString") {
    return
  }
  const existingCoordinates = getExistingCoordinates(feature)
  if (!existingCoordinates) {
    return
  }
  const line = lineString(existingCoordinates)
  const cursorAt = point([e.lngLat.lng, e.lngLat.lat])
  const snapped = nearestPointOnLine(line, cursorAt)
  const featureCollection = truncate(lineSplit(line, snapped), {
    precision: GEOJSON_COORD_PRECISION,
  })
  const featuresToAdd: NetworkGeometry[] = []
  const featuresToUpdate: NetworkGeometry[] = []

  const stop = createPointFeature(
    snapped.geometry.coordinates,
    [],
    state.selectedNetworkType
  )
  if (stop) {
    featuresToAdd.push(stop)
    if (state.actionMode === "edit_mode") {
      setTimeout(() => {
        this.changeMode("edit_mode", {
          featureId: stop.id,
          actionMode: state.actionMode,
          selectedNetworkType: state.selectedNetworkType,
        })
      }, 10)
    } else {
      setTimeout(() => {
        state.feature = undefined
        state.featureIds = []
        this.clearSelectedFeatures()
        if (state.actionMode === "extend_existing_mode") {
          state.line = this.newLine()
          state.currentVertexPosition = 0
          state.completeLineCoordinates = []
        }
      }, 10)
    }
  }

  const networkBaseline = state.selectedNetworkType.baselineProperties.Network!

  featureCollection.features.forEach((f, index) => {
    f.properties = JSON.parse(JSON.stringify(feature.properties))
    if (f.properties) {
      const id = f.properties[networkBaseline.id] + "-" + (index + 1)
      f.properties[networkBaseline.id] = id
      f.properties["has_improvement"] = true
      f.id = id
    }
    calculateTime(f.geometry, f.properties!)
    featuresToUpdate.push(f as NetworkGeometry)
  })

  draw.add({
    type: "FeatureCollection",
    features: featuresToAdd.concat(featuresToUpdate),
  })

  setTimeout(() => {
    draw.delete(feature.id.toString())
    if (state.selectedNetworkType.name !== "bike" && feature) {
      const filteredFeatures = getAllFeaturesWithSameRouteId(
        state.selectedNetworkType,
        draw,
        feature.properties?.line,
        state.actionMode
      )
      let stackItem: SelectStack = {
        mode: state.actionMode,
        operation: "route_selection",
        features: filteredFeatures,
        properties: { routeId: feature.properties?.line },
      }
      this.map.fire(MapEvents.ROUTE_SELECTED, {
        stackItem,
      })

      this.setSelected(stop?.id as string)
      state.featureIds = [stop?.id as string]
      stackItem = {
        mode: state.actionMode,
        operation: "selection_change",
        features: [stop as NetworkGeometry],
        properties: {},
      }
      this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
      if (state.actionMode === "extend_existing_mode") {
        state.line = newLine.call(this)
        state.currentVertexPosition = 0
        state.completeLineCoordinates = []
      }
    }
  }, 10)

  const stackItem: CRUDStack = {
    mode: state.actionMode,
    operation: "added_stop",
    stackState: [
      {
        action: "update",
        oldState: [feature.toGeoJSON() as NetworkGeometry],
        newState: featuresToUpdate,
      },
      {
        action: "create",
        newState: featuresToAdd,
        oldState: [],
      },
    ],
  }

  this.map.fire(MapEvents.CRUD, {
    stackItem,
  })
}

function addControlPoint(this: any, state: any, e: any) {
  if (!e.featureTarget) {
    return
  }
  const feature = this.getFeature(e.featureTarget.properties.id)
  if (feature.type !== "LineString" && feature.type !== "MultiLineString") {
    return
  }
  const existingCoordinates = getExistingCoordinates(feature)
  if (!existingCoordinates) {
    return
  }
  const line = lineString(existingCoordinates)
  const cursorAt = point([e.lngLat.lng, e.lngLat.lat])
  const snapped = nearestPointOnLine(line, cursorAt)
  const featureCollection = lineSplit(line, snapped)
  const featureCoordinates: Position[] = []
  featureCollection.features.forEach((f) => {
    f.geometry.coordinates.forEach((d) => {
      if (!featureCoordinates.includes(d)) {
        featureCoordinates.push(d)
      }
    })
  })
  const featureOrg = feature.toGeoJSON()
  updateFeatureCoordinates(feature, featureCoordinates)
  this.addFeature(feature)
  const stackItem: CRUDStack = {
    mode: state.actionMode,
    operation: "added_control_point",
    stackState: [
      {
        action: "update",
        oldState: [featureOrg as NetworkGeometry],
        newState: [feature.toGeoJSON() as NetworkGeometry],
      },
    ],
  }
  this.map.fire(MapEvents.CRUD, {
    stackItem,
  })
}

function getExistingCoordinates(feature: MapboxDraw.DrawFeature): Position[] {
  if (feature.type === "MultiLineString" && feature.features.length === 1) {
    return feature.features[0].getCoordinates()
  } else if (feature.type === "LineString") {
    return feature.getCoordinates()
  }
  return []
}

function updateFeatureCoordinates(
  feature: MapboxDraw.DrawFeature,
  featureCoordinates: Position[]
): void {
  if (featureCoordinates.length > 0) {
    if (feature.type === "LineString") {
      feature.setCoordinates(featureCoordinates)
    } else if (feature.type === "MultiLineString") {
      feature.features[0].setCoordinates(featureCoordinates)
    }
  }
}

function clickActiveFeature(
  this: any,
  e: any,
  isShiftClick: boolean,
  state: any,
  draw: MapboxDraw
) {
  const isRightClick = e.originalEvent.button === 2
  const isLeftClick = e.originalEvent.button === 0

  if (isShiftClick) {
    //this.deselect(e.featureTarget.properties.id)
    state.featureIds = state.featureIds.filter(
      (item) => item !== e.featureTarget.properties.id
    )
  } else if (isLeftClick && state.selectedNetworkType.name !== "bike") {
    addStop.call(this, state, e, draw)
  } else if (isRightClick) {
    addControlPoint.call(this, state, e)
  }
}

function dragVertex(
  this: any,
  state: any,
  e: mapboxgl.MapMouseEvent,
  delta: { lng: number; lat: number }
) {
  const selectedCoords = state.selectedCoordPaths.map((coord_path) =>
    state.feature!.getCoordinate(coord_path)
  )
  const selectedCoordPoints = selectedCoords.map((coords) =>
    this.newFeature({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords,
      },
    })
  )

  const constrainedDelta = lib.constrainFeatureMovement(
    selectedCoordPoints,
    delta
  )
  for (let i = 0; i < selectedCoords.length; i++) {
    const coord = selectedCoords[i]
    state.feature!.updateCoordinate(
      state.selectedCoordPaths[i],
      coord[0] + constrainedDelta.lng,
      coord[1] + constrainedDelta.lat
    )
  }
}

function dragFeature(
  this: any,
  state: any,
  e: any,
  delta: { lng: number; lat: number },
  draw: MapboxDraw
) {
  if (state.feature && state.feature.type === "Point") {
    const position = snapPointToNearbyLine(
      point([e.lngLat.lng, e.lngLat.lat]),
      draw
    )
    state.feature.setCoordinates(position)
  }
  // stopped dragging of linestring features
  //  else {
  //   lib.moveFeatures(this.getSelected(), delta)
  // }
  state.dragMoveLocation = e.lngLat
}

function startDragging(this: any, state: any, e: mapboxgl.MapMouseEvent) {
  this.map.dragPan.disable()
  state.canDragMove = true
  state.dragMoveLocation = e.lngLat
}

function stopDragging(this: any, state: any) {
  this.map.dragPan.enable()
  state.dragMoving = false
  state.canDragMove = false
  state.dragMoveLocation = undefined
  if (state.feature) {
    state.featureOrg = state.feature.toGeoJSON()
  }
}

function onVertex(this: any, state: any, e: any) {
  startDragging.call(this, state, e)
  const about = e.featureTarget.properties!
  const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path)
  if (!CommonSelectors.isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths = [about.coord_path]
  } else if (CommonSelectors.isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths.push(about.coord_path)
  }

  const selectedCoordinates = state.selectedCoordPaths.map((coord_path) => ({
    feature_id: state.featureId!,
    coord_path,
  }))

  this.setSelectedCoordinates(selectedCoordinates)
}

function onFeature(this: any, state: any, e: any) {
  if (state.selectedCoordPaths.length === 0) startDragging.call(this, state, e)
  else stopDragging.call(this, state)
}

function insideMouseMove(
  this: any,
  state: any,
  e: any,
  isDrawingInprogress?: boolean
) {
  const isFeature = isActiveFeature(e)
  const onVertex = isVertex(e)
  const isMidPoint = isMidpoint(e)
  const isDraggableItem = onVertex || isFeature || isMidPoint
  if (isDraggableItem && state.dragMoving) this.fireUpdate(state)

  const tooltipbox: HTMLElement = document.querySelector(".mouseTooltip")!
  const tooltipContent: HTMLElement =
    document.querySelector(".mouseTooltip span")!
  let tooltipInnerHTML
  let isPointFeature = false
  let isEndpoint = false
  if (e.featureTarget) {
    isPointFeature = e.featureTarget._geometry.type == "Point"
    isEndpoint = e.featureTarget.properties.user_is_endPoint == "true"
  }
  tooltipbox.style.top = e.originalEvent.clientY + 10 + "px"
  tooltipbox.style.left = e.originalEvent.clientX + 10 + "px"

  if (
    isPointFeature &&
    isEndpoint &&
    e.featureTarget.properties.user_has_improvement === true
  ) {
    tooltipbox.style.display = "block"
    tooltipInnerHTML =
      "<p>" +
      intl.get("add_mode.point_select_tooltip") +
      "<br />" +
      intl.get("existing_mode.line_draw_tooltip") +
      "</p>"
    tooltipContent.innerHTML = tooltipInnerHTML
  } else if (
    isPointFeature &&
    isEndpoint &&
    e.featureTarget.properties.user_has_improvement === false
  ) {
    tooltipbox.style.display = "block"
    tooltipInnerHTML =
      "<p>" + intl.get("existing_mode.line_draw_tooltip") + "</p>"
    tooltipContent.innerHTML = tooltipInnerHTML
  } else if (
    isPointFeature &&
    !isEndpoint &&
    e.featureTarget.properties.user_has_improvement === true &&
    state.actionMode !== "edit_mode"
  ) {
    tooltipbox.style.display = "block"
    tooltipInnerHTML =
      "<p>" + intl.get("add_mode.point_select_tooltip") + "</p>"
    tooltipContent.innerHTML = tooltipInnerHTML
  } else if (isFeature && isDrawingInprogress && !isPointFeature) {
    tooltipbox.style.display = "block"
    tooltipInnerHTML =
      "<p>" +
      intl.get("add_mode.left_click") +
      "<br />" +
      intl.get("add_mode.double_click") +
      "</p>"
    tooltipContent.innerHTML = tooltipInnerHTML
  } else if (
    isFeature &&
    !isDrawingInprogress &&
    state.selectedNetworkType.name !== "bike" &&
    !isPointFeature
  ) {
    tooltipbox.style.display = "block"
    tooltipInnerHTML =
      "<p>" +
      intl.get("edit_mode.left_click") +
      "<br />" +
      intl.get("edit_mode.right_click") +
      "</p>"
    tooltipContent.innerHTML = tooltipInnerHTML
  } else if (onVertex) {
    tooltipbox.style.display = "block"

    tooltipInnerHTML = "<p>" + intl.get("edit_mode.select_and_drag") + "</p>"
    tooltipContent.innerHTML = tooltipInnerHTML
  } else {
    tooltipbox.style.display = "none"
  }

  if (state.actionMode === "extend_existing_mode") {
    let { lng, lat } = snap(state, e)
    lng = round(lng, GEOJSON_COORD_PRECISION)
    lat = round(lat, GEOJSON_COORD_PRECISION)
    state.line.updateCoordinate(
      state.currentVertexPosition.toString(),
      lng,
      lat
    )
    state.snappedLng = lng
    state.snappedLat = lat
    stopDragging.call(this, state)
  }

  this.updateUIClasses({ mouse: cursors.POINTER })
}

function addModeDisplyFeatures(state, geojson, display) {
  const isActiveLine = geojson.properties.id === state.line.id
  geojson.properties.active = isActiveLine
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE
  if (!isActiveLine) return display(geojson)

  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return
  geojson.properties.meta = Constants.meta.FEATURE
  if (state.line.id !== undefined) {
    display(
      createVertex(
        state.line.id.toString(),
        geojson.geometry.coordinates[
          state.direction === "forward"
            ? geojson.geometry.coordinates.length - 2
            : 1
        ],
        `${
          state.direction === "forward"
            ? geojson.geometry.coordinates.length - 2
            : 1
        }`,
        false
      )
    )
  }

  display(geojson)
}

function editModeDisplyFeatures(state, geojson, display) {
  if (state.featureIds.includes(geojson.properties.id)) {
    geojson.properties.active = Constants.activeStates.ACTIVE
    display(geojson)
    createSupplementaryPoints(geojson, {
      midpoints: false,
      selectedPaths: state.selectedCoordPaths,
    }).forEach(display)
  } else {
    geojson.properties.active = Constants.activeStates.INACTIVE
    display(geojson)
  }
}

function onContextMenuClick(this: any, state: any, e: any) {
  this.clearSelectedFeatures()
  const featureId = e.target.id
  state.featureIds = [featureId]
  this.select(featureId)
  if (state.actionMode == "extend_existing_mode") {
    const stackItem: SelectStack = {
      mode: "extend_existing_mode",
      operation: "selection_change",
      features: this.getSelected().map((d) => d.toGeoJSON() as NetworkGeometry),
      properties: {},
    }

    this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
  } else {
    this.changeMode("edit_mode", {
      featureId: featureId,
      actionMode: state.actionMode,
      selectedNetworkType: state.selectedNetworkType,
    })
  }
  popup.remove()
}

function openContextMenu(this, e, state, draw) {
  const featureId = e.featureTarget ? e.featureTarget.properties.id : ""
  state.featureId = featureId

  const feature = this.getFeature(featureId)
  const featuresAt = this.featuresAt(e, [0, 0, 0, 0], "click").map(
    (f) => this.getFeature(f.properties!.id).toGeoJSON() as NetworkGeometry
  )

  if (feature.type === "Point") {
    state.featureIds = [featureId]
    state.featuresAtPoint = featuresAt
      .filter((f) => f.geometry.type === "LineString")
      .map((f) => {
        const coordinates = f.geometry.coordinates as Position[]
        let idx = coordinates.findIndex(
          (p) =>
            p[0] === feature.getCoordinates()[0] &&
            p[1] === feature.getCoordinates()[1]
        )
        if (idx != 0 && idx != coordinates.length - 1) {
          idx = -1
        }
        return {
          feature: f,
          coordIdx: idx,
        } as FeaturesAtPoint
      })
      .filter((f) => f.coordIdx !== -1)
    const stackItem: SelectStack = {
      mode: state.actionMode,
      operation: "selection_change",
      features: [this.getFeature(featureId).toGeoJSON() as NetworkGeometry],
      properties: {},
    }
    this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
  } else {
    if (featuresAt.length > 1 && !e.skipContextMenu) {
      const element = getContextMenuForFeatures(
        state,
        featuresAt,
        feature.toGeoJSON() as NetworkGeometry
      )

      const div = document.createElement("div")
      div.innerHTML = ReactDomServer.renderToStaticMarkup(element)
      div.querySelectorAll(".context-menu-item").forEach((e) =>
        e.addEventListener("click", (event: any) => {
          onContextMenuClick.call(this, state, event)
        })
      )
      // @ts-ignore
      popup.setLngLat(e.lngLat).setDOMContent(div).addTo(this.map)
    } else {
      popup.remove()
      state.featureIds = [featureId]
      const feat = feature.toGeoJSON() as NetworkGeometry
      if (
        Object.prototype.hasOwnProperty.call(feat.properties, "time_min") &&
        state.selectedNetworkType.name !== "bike"
      ) {
        const speed = calculateSpeed(feat.geometry, feat.properties)
        feature.setProperty("speed", speed)
      }

      let stackItem: SelectStack
      if (
        state.actionMode == "add_mode" &&
        state.selectedNetworkType.name !== "bike"
      ) {
        const routeId = state.selectedNetworkType.getPropertyFromFeature(
          feature.toGeoJSON() as NetworkGeometry,
          "routeId"
        )
        const filteredFeatures = getAllFeaturesWithSameRouteId(
          state.selectedNetworkType,
          draw,
          routeId,
          state.actionMode
        )
        stackItem = {
          mode: "add_mode",
          operation: "route_selection",
          features: filteredFeatures,
          properties: { routeId: routeId },
        }
        this.map.fire(MapEvents.ROUTE_SELECTED, {
          stackItem,
        })
      }

      stackItem = {
        mode: state.actionMode,
        operation: "selection_change",
        features: [feat],
        properties: {},
      }
      this.map.fire(MapEvents.SELECTION_CHANGE, {
        stackItem,
      })
    }
  }
}

function newLine(this: any): MapboxDraw.DrawLineString {
  const line = this.newFeature({
    type: geojsonTypes.FEATURE,
    properties: { title: "Incomplete" },
    geometry: {
      type: geojsonTypes.LINE_STRING,
      coordinates: [[]],
    },
  }) as DrawLineString

  this.addFeature(line)
  return line
}

export {
  editModeRouteSelection,
  getContextMenuForFeatures,
  addStop,
  addControlPoint,
  clickActiveFeature,
  dragVertex,
  dragFeature,
  onVertex,
  onFeature,
  startDragging,
  stopDragging,
  insideMouseMove,
  addModeDisplyFeatures,
  editModeDisplyFeatures,
  onContextMenuClick,
  openContextMenu,
  newLine,
}
