import React, { Component } from "react"
import mapboxgl, { Map } from "mapbox-gl"
import {
  buildLayersForNetwork,
  drawingControl,
} from "../utils/mapbox/MapboxRenderHelper"
import {
  Network,
  NetworkData,
  NetworkGeometry,
  NetworkType,
} from "../specs/Networks"
import Modal from "react-responsive-modal"
import {
  CRUDStack,
  CustomControl,
  MapEvents,
  MapEventStack,
  OperationMode,
  SelectStack,
  StackState,
} from "../specs/Mapbox"
import intl from "react-intl-universal"
import { MAPBOX_ACCESS_TOKEN } from "../constants/config"
import RouteSelector from "../components/mapbox/RouteSelector"
import {
  checkCoordinateMatch,
  checkDisconnectedFeatures,
  combineFeatures,
  getAllPointsAlongLine,
  getAutogenerateStopsLineSplitFeatures,
  getRouteSelected,
} from "../utils/mapbox/FeaturesUtils"
import { handleSave } from "../utils/mapbox/HandleMapboxControls"
import ConfirmationModal from "../components/mapbox/ConfirmationPopup"
import { generateLayerId } from "../utils/mapbox/Layers"
import MouseTooltip from "react-sticky-mouse-tooltip"
import { arrayMove, List } from "react-movable"
import Tippy from "@tippyjs/react"
import RunStimulationPopup from "../components/mapbox/RunSimulationPopup"
import SideActionWindowView from "../components/mapbox/SideActionWindowView"
import {
  NavbarCallbackType,
  PropertyEditorCallbackData,
  PropertyEditorCallbackType,
} from "../specs/Views"
import { DynamicStops } from "../utils/mapbox/DynamicStops"

import "react-input-range/lib/css/index.css"
import "react-responsive-modal/styles.css"
import "tippy.js/dist/tippy.css"
import { bbox } from "@turf/turf"
import { FeatureCollection } from "geojson"
import Color from "color"
import { cloneDeep } from "lodash"
import ExtendExistingMode from "../components/mapbox/ExtendExistingMode"
import styles from "../style.module.scss"
import { popup } from "../components/mapbox/CommonOperationMode"
import { AppContext } from "./App"
import { getAllUserProjects } from "../services/user.service"
import ProjectSelectionModal from "../components/user-management/ProjectSelectionModal"
import Loader from "../components/Loader"

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

interface EditorState {
  isOverlayOpen: boolean
  isEdit: boolean
  selectedNetworkType: NetworkType | null
  networkTypes: NetworkType[]
  networkData: NetworkData[]
  undoStack: MapEventStack[]
  redoStack: MapEventStack[]
  currentSelection: NetworkGeometry[]
  routeFeatures: NetworkGeometry[]
  isChangesSaved: boolean
  isOperationModeModalOpen: boolean
  mapLoaded: boolean
  isExitEditorButtonClicked: boolean
  operationMode: OperationMode
  isRouteSelected: boolean
  selectedRoute: string
  isRunStimulationModalOpen: boolean
  addedStopsCoords: any
  isHelpBtnClicked: boolean
  isBaselineUpdated: boolean
  isRouteLevelSelection: boolean
  mismatchedCoordinates: boolean
  showCombineFeaturesWithStopAlert: boolean
  isOrphanFeatures: boolean
  userProjects: any
  openProjectSelectionModal: boolean
  savingConfirmationModalOpen: boolean
  closeProjectConfirmationModalOpen: boolean
  openInvalidProjectIdPopup: boolean
  mapRendered: boolean
  language: string
}

interface EditorProps {
  network: Network
  lng: number
  lat: number
  zoom: number
}

export default class Editor extends Component<EditorProps, EditorState> {
  static contextType = AppContext
  private map!: Map
  private readonly mapContainer: React.RefObject<HTMLDivElement>
  private draw: any
  private dynamicStops!: DynamicStops
  private appContext: any
  private ProjectSelected: boolean = false
  private ProjectSelectedName: string = ""

  private reversedNetworkTypes = [...this.props.network.networkTypes].reverse()
  private alertModalButtons = [
    {
      label: intl.get("button.ok"),
      buttonClass: "submitModalButton",
      action: () => {
        this.setState({
          mismatchedCoordinates: false,
        })
      },
    },
  ]

  private alertModalWithStopsButtons = [
    {
      label: intl.get("button.no"),
      buttonClass: "cancelModalButton",
      action: () => {
        this.setState({
          showCombineFeaturesWithStopAlert: false,
        })
      },
    },
    {
      label: intl.get("button.yes"),
      buttonClass: "submitModalButton",
      action: () => {
        this.setState({ showCombineFeaturesWithStopAlert: false }, () => {
          combineFeatures.call(this)
        })
      },
    },
  ]

  getDefaultControl(): CustomControl[] {
    if (!this.state.isEdit) {
      return []
    }
    return [
      {
        dataTooltip: intl.get("editor.control.undo"),
        action: () => this.updateStackState("undo"),
        classes: "undo-button",
        conditionalClasses: () =>
          this.state.undoStack.length === 0 ? "disabled" : "",
        iconClass: "fa-rotate-left",
        visible: this.state.isEdit,
      },
      {
        dataTooltip: intl.get("editor.control.redo"),
        action: () => this.updateStackState("redo"),
        classes: "redo-button",
        conditionalClasses: () =>
          this.state.redoStack.length === 0 ? "disabled" : "",
        iconClass: "fa-rotate-right",
        visible: this.state.isEdit,
      },
      {
        dataTooltip: intl.get("editor.control.save"),
        action: () => {
          if (this.state.undoStack.length === 0) {
            return
          }
          this.saveChanges()
        },
        conditionalClasses: () =>
          this.state.undoStack.length === 0 ? "disabled" : "",
        iconClass: "fa-solid fa-floppy-disk",
        visible: this.state.isEdit,
      },
      {
        dataTooltip: intl.get("editor.exit"),
        action: () => {
          if (!this.state.isEdit) {
            this.state.isOverlayOpen ? this.closeOverlay() : this.openOverlay()
          } else {
            this.setState({ isExitEditorButtonClicked: true })
          }
        },
        iconClass: "fa-close",
        classes: "editExitButton",
        visible: this.state.isEdit,
      },
    ]
  }

  getRunSimulationBtn(): CustomControl[] {
    const urlParam = new URLSearchParams(window.location.search)
    const projectId = urlParam.get("projectId")
    const { userProjects } = this.state
    if (userProjects && userProjects.length > 0 && projectId) {
      if (userProjects && userProjects.length > 0 && projectId) {
        const selectedProject = userProjects.find(
          (project) => project.id === projectId
        )
        if (selectedProject) {
          this.ProjectSelected = true
          this.ProjectSelectedName = selectedProject.name
        }
      }
    }
    if (
      !this.state.isEdit &&
      !this.state.isBaselineUpdated &&
      !this.ProjectSelected
    ) {
      return []
    }
    return [
      {
        dataTooltip: intl.get("editor.run_simulation"),
        title: intl.get("editor.run_simulation"),
        label: intl.get("editor.run_simulation"),
        action: () => this.handleNavbarCallback("run-simulation"),
        iconClass: "fa-circle-play",
        classes: "submitModalButton",
        visible: this.state.isEdit || this.state.isBaselineUpdated,
      },
    ]
  }

  getProjectSelectionBtn(): CustomControl[] {
    return [
      {
        dataTooltip: intl.get("editor.projects"),
        label: intl.get("editor.projects"),
        title: intl.get("editor.projects"),
        action: () => {
          this.getAllUserProjects()
          this.setState({
            openProjectSelectionModal: true,
          })
        },
        iconClass: "fa-list-alt",
        classes:
          this.state.userProjects.length > 0
            ? "submitModalButton"
            : "submitModalButton hidden",
      },
    ]
  }

  constructor(props: any, context: any) {
    super(props)
    this.state = {
      isOverlayOpen: false,
      isEdit: false,
      selectedNetworkType: null,
      networkTypes: this.reversedNetworkTypes,
      networkData: [],
      redoStack: [],
      undoStack: [],
      currentSelection: [],
      routeFeatures: [],
      isChangesSaved: true,
      isOperationModeModalOpen: false,
      mapLoaded: false,
      isExitEditorButtonClicked: false,
      operationMode: "unknown",
      isRouteSelected: false,
      selectedRoute: "",
      isRunStimulationModalOpen: false,
      addedStopsCoords: undefined,
      isHelpBtnClicked: false,
      isBaselineUpdated: false,
      isRouteLevelSelection: false,
      mismatchedCoordinates: false,
      showCombineFeaturesWithStopAlert: false,
      isOrphanFeatures: false,
      userProjects: [],
      openProjectSelectionModal: false,
      savingConfirmationModalOpen: false,
      closeProjectConfirmationModalOpen: false,
      openInvalidProjectIdPopup: false,
      mapRendered: false,
      language: "",
    }
    this.appContext = context
    this.mapContainer = React.createRef<HTMLDivElement>()
    this.handleToggleEditing = this.handleToggleEditing.bind(this)
    this.openOverlay = this.openOverlay.bind(this)
    this.closeOverlay = this.closeOverlay.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
    this.handleDrawEvent = this.handleDrawEvent.bind(this)
    this.updateStackState = this.updateStackState.bind(this)
    this.addFeatures = this.addFeatures.bind(this)
    this.deleteFeatures = this.deleteFeatures.bind(this)
    this.fetchNetworkData = this.fetchNetworkData.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
    this.toggleVisibilityForLayers = this.toggleVisibilityForLayers.bind(this)
    this.getFeaturesOfSelectedNetwork =
      this.getFeaturesOfSelectedNetwork.bind(this)
    this.addToUndoStack = this.addToUndoStack.bind(this)
    this.handleOperationModeSelection =
      this.handleOperationModeSelection.bind(this)
    this.closeOperationModal = this.closeOperationModal.bind(this)
    this.handleRouteSelection = this.handleRouteSelection.bind(this)
    this.onRecenterMapButtonClick = this.onRecenterMapButtonClick.bind(this)
    this.toggleRunSimulationBtn = this.toggleRunSimulationBtn.bind(this)
    this.closeRunSimulationBtn = this.closeRunSimulationBtn.bind(this)
    this.handleCallback = this.handleCallback.bind(this)
    this.handleNavbarCallback = this.handleNavbarCallback.bind(this)
    this.handleClearRouteSelection = this.handleClearRouteSelection.bind(this)
    this.fitMap = this.fitMap.bind(this)
    this.updateLocale = this.updateLocale.bind(this)
    this.addButtonsToNavbar = this.addButtonsToNavbar.bind(this)
    this.closeProjectSelectionModal = this.closeProjectSelectionModal.bind(this)
    this.openCloseProjectConfirmationPopup =
      this.openCloseProjectConfirmationPopup.bind(this)
  }

  componentDidMount() {
    this.map = new Map({
      container: this.mapContainer.current as HTMLDivElement,
      style: "mapbox://styles/tarnekar/clj5ajr3d001b01p96rr693b9",
      center: [this.props.lng, this.props.lat],
      zoom: this.props.zoom,
    })
    this.map.getCanvas().style.cursor = "pointer"
    this.fetchNetworkData()
    this.draw = drawingControl()
    this.dynamicStops = new DynamicStops(this.draw)

    // Event Handlers
    this.map.on("load", this.handleLoad)
    this.map.on(MapEvents.SELECTION_CHANGE, this.handleSelection)
    this.map.on(MapEvents.ROUTE_SELECTED, this.handleRouteSelection)
    this.map.on(MapEvents.ROUTE_DESELECTED, this.handleClearRouteSelection)
    this.map.on(MapEvents.CRUD, this.handleDrawEvent)

    document.addEventListener("keydown", (event) => {
      if (event.key === "Delete") {
        if (this.state.currentSelection.length > 0) {
          this.handleCallback("delete", {})
        }
      }
    })
    this.getAllUserProjects()
  }

  componentDidUpdate(
    prevProps: Readonly<EditorProps>,
    prevState: Readonly<EditorState>,
    snapshot?: any
  ) {
    const language = localStorage.getItem("lang")
    if (language !== this.state.language) {
      this.setState(
        {
          language: language as string,
        },
        () => {
          this.addButtonsToNavbar()
        }
      )
    }
    if (prevState.isEdit != this.state.isEdit) {
      this.addButtonsToNavbar()
    }
  }

  addButtonsToNavbar() {
    const { setNavBarButtons, setRunSimulationBtn, setProjectSelectionBtn } =
      this.appContext
    setNavBarButtons(this.getDefaultControl())
    setRunSimulationBtn(this.getRunSimulationBtn())
    setProjectSelectionBtn(this.getProjectSelectionBtn())
  }

  async fetchNetworkData() {
    const urlParam = new URLSearchParams(window.location.search)
    const projectId = urlParam.get("projectId")
    if (projectId) {
      const isValidProjectId = await this.validateProjectId(projectId)
      console.log("isValidProjectId", isValidProjectId)
      if (!isValidProjectId) {
        // alert("project id is invalid")
        this.setState({
          openInvalidProjectIdPopup: true,
        })
        return
      }
    }
    const networkData: NetworkData[] = this.props.network.loadNetworkData()
    this.setState({ networkData, isBaselineUpdated: false }, async () => {
      while (!this.state.mapLoaded || networkData.length == 0) {
        await sleep(250)
      }
      buildLayersForNetwork(this.map, this.props.network, networkData)
      this.fitMap()
      setTimeout(() => this.setState({ mapRendered: true }), 1000)
    })
  }

  fitMap() {
    const allFeatures = this.state.networkData.flatMap((d) => d.data.features)
    this.fitBounds({
      type: "FeatureCollection",
      features: allFeatures,
    })
  }

  handleLoad() {
    this.setState({
      mapLoaded: true,
    })
  }

  handleSelection(e: { type: string; stackItem: SelectStack }) {
    const stackItem = e.stackItem
    const routeEdit =
      stackItem.features.length == 0 && this.state.selectedRoute != ""
    if (routeEdit) {
      this.setState({
        currentSelection:
          this.state.operationMode === "add_mode"
            ? []
            : this.state.routeFeatures,
        isRouteLevelSelection: true,
      })
    } else {
      const newSelection = stackItem.features
      if (
        JSON.stringify(newSelection) !==
        JSON.stringify(this.state.currentSelection)
      ) {
        this.setState({
          currentSelection: newSelection,
          isRouteLevelSelection: false,
        })
      }
    }
  }

  handleRouteSelection(e: { type: string; stackItem: SelectStack }) {
    const stackItem = e.stackItem
    const routeFeatures =
      this.state.operationMode === "extend_existing_mode"
        ? this.draw
            .getAll()
            .features.filter(
              (f) =>
                f.geometry.type === "LineString" &&
                f.properties.title !== "Incomplete"
            )
        : stackItem.features

    if (stackItem.properties.is_disconnected) {
      this.setState({
        routeFeatures: routeFeatures,
      })
    } else {
      this.setState(
        {
          isRouteSelected: true,
          selectedRoute: stackItem.properties.routeId,
          currentSelection: stackItem.features,
          routeFeatures: routeFeatures,
          isRouteLevelSelection: true,
        },
        () => {
          if (
            this.state.operationMode === "add_mode" ||
            this.state.operationMode === "extend_existing_mode"
          ) {
            return
          }
          this.fitBounds({
            type: "FeatureCollection",
            features: stackItem.features,
          })
        }
      )
    }

    if (stackItem.operation == "route_selection") {
      getRouteSelected(true)
    }
    this.dynamicStops.cleanAddedStops(this.state.currentSelection)
  }

  handleClearRouteSelection() {
    this.setState(
      {
        isRouteSelected: false,
        selectedRoute: "",
        currentSelection: [],
        routeFeatures: [],
        undoStack: [],
        redoStack: [],
      },
      () => {
        this.activateEditingForLayer()
        getRouteSelected(false)
      }
    )
  }

  handleDrawEvent(e: { type: string; stackItem: CRUDStack }) {
    this.setState(
      {
        isChangesSaved: false,
        isHelpBtnClicked: false,
      },
      () => {
        if (e.type === MapEvents.CRUD) {
          const currentSelection = e.stackItem.stackState.flatMap(
            (s) => s.newState
          )
          const routeFeatures = this.state.routeFeatures
          e.stackItem.stackState.flatMap((s) => {
            s.newState.forEach((newState) => {
              const idx = this.state.routeFeatures.findIndex(
                (route) => route.id === newState.id
              )
              routeFeatures[idx] = newState
            })
          })

          this.setState(
            {
              undoStack: [...this.state.undoStack, e.stackItem],
              currentSelection: currentSelection,
              routeFeatures: routeFeatures,
            },
            () => {
              if (this.state.isEdit) {
                this.addButtonsToNavbar()
              }
            }
          )
        }
      }
    )
  }

  saveChanges() {
    if (this.state.selectedNetworkType == null) {
      return []
    }

    if (this.state.operationMode === "extend_existing_mode") {
      this.draw
        .getAll()
        .features.filter(
          (f) => f.geometry.type === "Point" && "is_endPoint" in f.properties
        )
        .forEach((stop) => {
          this.draw.setFeatureProperty(stop.id, "is_endPoint", false)
          if (stop.properties.title === "temperory_stop") {
            this.draw.delete(stop.id)
          }
        })
    }

    const updatedNetworkData = handleSave(
      this.draw,
      this.state.selectedNetworkType,
      this.state.networkData,
      this.state.undoStack,
      this.state.operationMode,
      this.map
    )

    if (updatedNetworkData !== undefined) {
      this.setState(
        {
          networkData: updatedNetworkData,
          isBaselineUpdated: true,
        },
        () => {
          this.exitEditMode()
          buildLayersForNetwork(
            this.map,
            this.props.network,
            updatedNetworkData
          )
        }
      )
      return updatedNetworkData
    } else {
      this.setState({
        isOrphanFeatures: true,
      })
    }
  }

  undo() {
    const undoStack = this.state.undoStack
    if (undoStack.length == 0) {
      return
    }
    const redoStack = this.state.redoStack
    let currentSelection: NetworkGeometry[] = []
    let routeFeatures: NetworkGeometry[] = []

    const topElement = undoStack.pop() as CRUDStack
    if (topElement) {
      redoStack.push(topElement)
      topElement.stackState.forEach((s) => {
        switch (s.action) {
          case "create":
            this.deleteFeatures(s.newState)
            break
          case "update":
            this.deleteFeatures(s.newState)
            this.addFeatures(s.oldState)
            if (topElement.operation === "updated_properties") {
              currentSelection = currentSelection.concat(s.oldSelection!)
              if (
                s.oldState.filter((f) => f.geometry.type === "LineString")
                  .length > 0
              ) {
                routeFeatures = routeFeatures.concat(s.oldState)
              } else {
                routeFeatures = this.state.routeFeatures
              }
            } else {
              currentSelection = currentSelection.concat(s.oldState)
              routeFeatures = this.draw
                .getAll()
                .features.filter((f) => f.geometry.type === "LineString")
                .map((f) => f as NetworkGeometry)
            }
            break
          case "delete":
            this.addFeatures(s.oldState)
            currentSelection = currentSelection.concat(s.oldState)
            routeFeatures = this.state.routeFeatures.concat(
              s.oldState.filter((f) => f.geometry.type === "LineString")
            )
            break
        }
      })
    }

    const getAllExistingFeartures = this.getFeaturesOfSelectedNetwork().filter(
      (f) => f.geometry.type === "LineString"
    )
    checkDisconnectedFeatures(
      this.draw,
      getAllExistingFeartures,
      this.state.operationMode,
      this.state.selectedNetworkType,
      this.map
    )

    if (this.state.operationMode === "extend_existing_mode") {
      ExtendExistingMode.getEndPoints()
      const allFeatures = this.draw
        .getAll()
        .features.filter(
          (f) =>
            f.geometry.type === "LineString" &&
            f.properties.title !== "Incomplete"
        )

      currentSelection = allFeatures as NetworkGeometry[]
      routeFeatures = allFeatures as NetworkGeometry[]
    }

    this.draw.getAll().features.forEach((feature) => {
      routeFeatures.forEach((routeFeature) => {
        if (
          routeFeature.id === feature.id &&
          !feature.properties["is_disconnected"]
        ) {
          delete routeFeature.properties.is_disconnected
        }
      })
    })

    this.setState(
      {
        undoStack,
        redoStack,
        currentSelection,
        routeFeatures,
      },
      () => {
        if (this.state.isEdit) {
          this.addButtonsToNavbar()
        }
      }
    )
  }

  redo() {
    const undoStack = this.state.undoStack
    const redoStack = this.state.redoStack
    if (redoStack.length == 0) {
      return
    }

    const topElement = redoStack.pop() as CRUDStack
    let currentSelection: NetworkGeometry[] = []
    let routeFeatures: NetworkGeometry[] = []
    if (topElement) {
      undoStack.push(topElement)
      topElement.stackState.forEach((s) => {
        switch (s.action) {
          case "create":
            this.addFeatures(s.newState)
            currentSelection = currentSelection.concat(s.newState)
            break
          case "update":
            this.deleteFeatures(s.oldState)
            this.addFeatures(s.newState)
            if (topElement.operation === "updated_properties") {
              currentSelection = currentSelection.concat(s.newSelection!)
              routeFeatures = routeFeatures.concat(s.newState)
            } else {
              currentSelection = currentSelection.concat(s.newState)
              routeFeatures = routeFeatures.concat(s.newState)
            }
            break
          case "delete":
            this.deleteFeatures(s.oldState)
            break
        }
      })
    }
    if (this.state.operationMode === "extend_existing_mode") {
      ExtendExistingMode.getEndPoints()
      const allFeatures = this.draw
        .getAll()
        .features.filter(
          (f) =>
            f.geometry.type === "LineString" &&
            f.properties.title !== "Incomplete"
        )

      currentSelection = allFeatures as NetworkGeometry[]
      routeFeatures = allFeatures as NetworkGeometry[]
    }

    const getAllExistingFeartures = this.getFeaturesOfSelectedNetwork().filter(
      (f) => f.geometry.type === "LineString"
    )
    checkDisconnectedFeatures(
      this.draw,
      getAllExistingFeartures,
      this.state.operationMode,
      this.state.selectedNetworkType,
      this.map
    )

    this.draw.getAll().features.forEach((feature) => {
      routeFeatures.forEach((routeFeature) => {
        if (
          routeFeature.id === feature.id &&
          !feature.properties["is_disconnected"]
        ) {
          delete routeFeature.properties.is_disconnected
        }
      })
    })

    this.setState(
      {
        undoStack,
        redoStack,
        currentSelection,
        routeFeatures,
      },
      () => {
        if (this.state.isEdit) {
          this.addButtonsToNavbar()
        }
      }
    )
  }

  updateStackState(operation: "undo" | "redo") {
    if (operation === "undo") {
      this.undo()
    } else if (operation === "redo") {
      this.redo()
    }
  }

  addFeatures(features: any[]) {
    this.draw.add({
      type: "FeatureCollection",
      features: features,
    })
  }

  deleteFeatures(features: any[]) {
    const ids: string[] = []
    features.forEach((feature) => {
      ids.push(feature.id)
    })
    this.draw.delete(ids)
  }

  openOverlay = () => {
    this.setState({
      isOverlayOpen: true,
    })
  }

  closeOverlay = () => {
    this.setState({
      isOverlayOpen: false,
    })
  }

  toggleVisibilityForLayers() {
    const isNetworkSelectedLayerIds: string[] = []
    this.state.networkTypes.forEach((nt) => {
      const isNetworkSelected = nt.name == this.state.selectedNetworkType?.name
      nt.baselines.forEach((baselineType) => {
        const layerId = generateLayerId(nt, baselineType)
        if (isNetworkSelected) {
          isNetworkSelectedLayerIds.push(layerId)
        }
        let opacity
        let circleOpacity = 1
        let color = nt.color
        if (this.state.isEdit) {
          if (!nt.visible) {
            opacity = isNetworkSelected ? 1 : 0
            circleOpacity = isNetworkSelected ? 1 : 0
          } else {
            opacity = 1
            // color = Color(color).alpha(0.5).darken(0.5).hex()
          }
        } else {
          opacity = nt.visible ? 1 : 0
          circleOpacity = nt.visible ? 1 : 0
          color = nt.color
        }

        let updatedColor
        if (this.state.isEdit) {
          updatedColor = Color(color).alpha(0.5).darken(0.5).hex()
        } else {
          updatedColor = [
            "case",
            [
              "all",
              ["==", ["get", "mode"], "bike"],
              ["==", ["get", "has_improvement"], true],
            ],
            Color(color).alpha(0.5).lighten(0.9).hex(),
            ["==", ["get", "has_improvement"], true],
            Color(color).alpha(0.5).lighten(0.6).hex(),
            color, // Default color
          ]
        }

        if (baselineType === "Network") {
          this.map.setPaintProperty(layerId, "line-opacity", opacity)
          this.map.setPaintProperty(layerId, "line-color", updatedColor)
          if (isNetworkSelected) {
            this.map.setPaintProperty(layerId, "line-color", styles.greyColor)
          }
        } else if (baselineType === "Stops") {
          this.map.setPaintProperty(layerId, "circle-stroke-opacity", opacity)
          this.map.setPaintProperty(layerId, "circle-opacity", circleOpacity)
          this.map.setPaintProperty(
            layerId,
            "circle-stroke-color",
            updatedColor
          )
          if (isNetworkSelected) {
            this.map.setPaintProperty(
              layerId,
              "circle-stroke-color",
              styles.greyColor
            )
          }
        }
        isNetworkSelectedLayerIds.forEach((layerId) => {
          this.map.moveLayer(layerId, "gl-draw-line.cold")
        })
      })
    })
  }

  activateEditingForLayer() {
    this.props.network.networkTypes.forEach((nt: NetworkType) => {
      const selectedNetwork = nt == this.state.selectedNetworkType
      if (selectedNetwork) {
        this.draw.set({
          type: "FeatureCollection",
          features: this.getFeaturesOfSelectedNetwork(),
        })
      }
      this.toggleVisibilityForLayers()
    })
  }

  handleToggleEditing(networkType: NetworkType) {
    this.setState({
      isEdit: true,
      isOverlayOpen: false,
      selectedNetworkType: networkType,
      isOperationModeModalOpen: true,
    })
  }

  exitEditMode() {
    const selectedNetworkType = this.state.selectedNetworkType!
    selectedNetworkType.baselines.forEach((baselineType) => {
      const layerId = generateLayerId(selectedNetworkType, baselineType)
      if (baselineType === "Network") {
        this.map.setPaintProperty(
          layerId,
          "line-color",
          selectedNetworkType.color
        )
      } else if (baselineType === "Stops") {
        this.map.setPaintProperty(
          layerId,
          "circle-stroke-color",
          selectedNetworkType.color
        )
      }
    })
    this.setState(
      {
        isEdit: false,
        selectedNetworkType: null,
        undoStack: [],
        redoStack: [],
        operationMode: "unknown",
        currentSelection: [],
        isChangesSaved: true,
        isExitEditorButtonClicked: false,
        isRouteSelected: false,
        selectedRoute: "",
        isHelpBtnClicked: false,
        routeFeatures: [],
      },
      () => {
        this.draw.deleteAll()
        this.map.removeControl(this.draw)
        this.toggleVisibilityForLayers()
        this.reorderLayers(this.state.networkTypes)
        popup.remove()
        getRouteSelected(false)
      }
    )
  }

  toggleVisibilityForNetwork(networkType: NetworkType) {
    const networkTypes = this.state.networkTypes.map((nt) => {
      if (nt.name === networkType.name) {
        nt.visible = !networkType.visible
      }
      return nt
    })
    this.setState({ networkTypes }, () => {
      this.toggleVisibilityForLayers()
    })
  }

  getFeaturesOfSelectedNetwork() {
    if (this.state.selectedNetworkType == null) {
      return []
    }
    let features: NetworkGeometry[] = []
    this.state.selectedNetworkType.baselines.forEach((baselineType) => {
      const source = this.state.networkData.find(
        (d) =>
          d.networkType === this.state.selectedNetworkType &&
          d.baselineType === baselineType
      )?.data.features
      features = features.concat(source as NetworkGeometry[])
    })
    return features
  }

  handleOperationModeSelection(operationMode: OperationMode) {
    this.setState(
      {
        operationMode: operationMode,
        isOperationModeModalOpen: false,
      },
      () => {
        if (!this.map.hasControl(this.draw)) {
          this.map.addControl(this.draw)
        }
        this.activateEditingForLayer()
        this.draw.changeMode(operationMode.toString(), {
          actionMode:
            operationMode === "add_mode"
              ? "add_mode"
              : operationMode === "edit_mode"
                ? "edit_mode"
                : "extend_existing_mode",
          init: true,
          selectedNetworkType: this.state.selectedNetworkType,
          draw: this.draw,
        })
      }
    )
  }

  addToUndoStack(stack: CRUDStack | SelectStack) {
    this.setState(
      {
        undoStack: [...this.state.undoStack, stack],
      },
      () => {
        if (this.state.isEdit) {
          this.addButtonsToNavbar()
        }
      }
    )
  }

  onListChange = ({ oldIndex, newIndex }) => {
    if (this.state.isEdit) {
      return
    }
    const updatedNetworkTypes = arrayMove(
      this.state.networkTypes,
      oldIndex,
      newIndex
    )

    this.reorderLayers(updatedNetworkTypes)
  }

  reorderLayers(updatedNetworkTypes: NetworkType[]) {
    const layerIds: string[] = []
    updatedNetworkTypes.forEach((nt) => {
      nt.baselines.forEach((baselineType) => {
        const layerId: string = generateLayerId(nt, baselineType)
        layerIds.push(layerId)
      })
    })

    const reorderedLayers: string[] = []
    const networkTypes = new Set()

    layerIds.forEach((layerName) => {
      const networkType = layerName.split("-")[0]
      if (!networkTypes.has(networkType)) {
        const stopsLayerName = `${networkType}-stops-layer`
        if (layerIds.includes(stopsLayerName)) {
          reorderedLayers.push(stopsLayerName as never)
          networkTypes.add(networkType)
        }
      }

      if (
        layerIds.includes(layerName) &&
        !reorderedLayers.includes(layerName)
      ) {
        reorderedLayers.push(layerName)
      }
    })

    for (let i = 0; i < reorderedLayers.length; i++) {
      const oldLayerId = reorderedLayers[i]
      const newLayerId = reorderedLayers[i + 1] // Get the next layer ID

      if (newLayerId) {
        this.map.moveLayer(newLayerId, oldLayerId)
      }
    }
    this.setState({ networkTypes: updatedNetworkTypes })
  }

  closeOperationModal() {
    this.setState({
      isEdit: false,
      isOperationModeModalOpen: false,
      selectedNetworkType: null,
    })
  }

  onRecenterMapButtonClick() {
    if (this.state.isEdit) {
      this.fitBounds(this.draw.getAll())
    } else {
      this.map.resetNorthPitch()
      this.fitMap()
    }
  }

  toggleRunSimulationBtn() {
    if (this.state.undoStack.length > 0) {
      this.setState({
        savingConfirmationModalOpen: true,
      })
    } else {
      this.setState({
        isRunStimulationModalOpen: true,
      })
    }
  }

  closeRunSimulationBtn() {
    this.setState(
      {
        isRunStimulationModalOpen: false,
      },
      () => {
        this.getAllUserProjects()
      }
    )
  }

  handleCallback(
    callbackType: PropertyEditorCallbackType,
    callbackData: PropertyEditorCallbackData
  ) {
    popup.remove()
    switch (callbackType) {
      case "delete":
        let currentSelection = this.state.currentSelection
        const featuresOnLine = getAllPointsAlongLine(
          this.state.currentSelection,
          this.draw,
          this.map
        )
        currentSelection = currentSelection.concat(featuresOnLine)
        this.addToUndoStack({
          mode: this.state.operationMode,
          operation: "deleted_feature",
          stackState: [
            {
              oldState: currentSelection,
              newState: [],
              action: "delete",
            },
          ],
        })
        this.deleteFeatures(currentSelection)
        const getAllExistingFeartures =
          this.state.operationMode === "add_mode"
            ? this.getFeaturesOfSelectedNetwork().filter(
                (f) => f.geometry.type === "LineString"
              )
            : this.getFeaturesOfSelectedNetwork().filter(
                (f) =>
                  f.geometry.type === "LineString" &&
                  f.properties.line !== currentSelection[0].properties.line
              )

        checkDisconnectedFeatures(
          this.draw,
          getAllExistingFeartures,
          this.state.operationMode,
          this.state.selectedNetworkType,
          this.map
        )
        const allFeatures = this.draw
          .getAll()
          .features.filter(
            (f) =>
              f.geometry.type === "LineString" &&
              f.properties.title !== "Incomplete"
          )
        if (
          (this.state.operationMode == "edit_mode" ||
            this.state.operationMode == "extend_existing_mode") &&
          this.state.selectedNetworkType?.name !== "bike"
        ) {
          this.setState({
            currentSelection: allFeatures as NetworkGeometry[],
            routeFeatures: allFeatures as NetworkGeometry[],
            isRouteLevelSelection: true,
          })
        } else {
          this.setState({
            isRouteSelected: false,
            selectedRoute: "",
            currentSelection: [],
            routeFeatures: [],
          })
        }
        if (this.state.operationMode == "extend_existing_mode") {
          ExtendExistingMode.getEndPoints()
        }
        break
      case "update":
        if (callbackData.data) {
          const crudStack = callbackData.data.stackItem as StackState
          this.draw.add({
            type: "FeatureCollection",
            features: crudStack.newState,
          })
          this.addToUndoStack({
            mode: this.state.operationMode,
            operation: "updated_properties",
            stackState: [crudStack],
          })

          const routeFeatures = this.state.routeFeatures
          crudStack.newState.forEach((newState) => {
            const idx = this.state.routeFeatures.findIndex(
              (route) => route.id === newState.id
            )
            routeFeatures[idx] = newState
          })
          this.setState({
            routeFeatures,
          })
        }
        break
      case "autogenerated_stop":
        if (callbackData.data && callbackData.mode === "preview") {
          this.dynamicStops.addStopsOnNetwork(
            this.state.currentSelection,
            callbackData.data.distance,
            this.state.selectedNetworkType
          )
        } else if (callbackData.mode === "clear") {
          this.dynamicStops.cleanAddedStops(this.state.currentSelection)
        } else if (callbackData.mode === "save") {
          const getStopsListForFeatures =
            this.dynamicStops.getStopsListForFeatures(
              this.state.currentSelection
            )
          const initialFeature = this.state.currentSelection[0]
          const lineSplitFeatures = getAutogenerateStopsLineSplitFeatures(
            initialFeature,
            getStopsListForFeatures
          )

          this.draw.add({
            type: "FeatureCollection",
            features: lineSplitFeatures,
          })

          setTimeout(() => {
            this.deleteFeatures([initialFeature])
          }, 100)

          const stackItem: CRUDStack = {
            mode: "edit_mode",
            operation: "added_stop",
            stackState: [
              {
                action: "update",
                oldState: [initialFeature],
                newState: lineSplitFeatures,
              },
              {
                action: "create",
                newState: getStopsListForFeatures as NetworkGeometry[],
                oldState: [],
              },
            ],
          }
          this.map.fire(MapEvents.CRUD, {
            stackItem,
          })

          this.setState({
            routeFeatures: lineSplitFeatures,
          })
        }
        break
      case "combine_routes":
        {
          const selectedRoutes = this.draw
            .getSelected()
            .features.map((f) => f as NetworkGeometry)

          const initialFeatures: NetworkGeometry[] = cloneDeep(selectedRoutes)

          const updatedFeatures: NetworkGeometry[] = []

          selectedRoutes.forEach((feature) => {
            feature.properties!.line = selectedRoutes[0].properties!.line
            updatedFeatures.push(feature as NetworkGeometry)
          })

          const stackItem: StackState = {
            action: "update",
            oldState: initialFeatures,
            newState: updatedFeatures,
            oldSelection: selectedRoutes,
            newSelection: initialFeatures,
          }
          this.draw.add({
            type: "FeatureCollection",
            features: updatedFeatures,
          })
          this.addToUndoStack({
            mode: this.state.operationMode,
            operation: "updated_properties",
            stackState: [stackItem],
          })
          this.setState({
            currentSelection: updatedFeatures,
            routeFeatures: updatedFeatures,
          })
        }
        break
      case "combine_features":
        {
          if (!checkCoordinateMatch.call(this)) {
            return
          } else {
            this.setState({ showCombineFeaturesWithStopAlert: true })
          }
        }
        break
    }
  }

  handleNavbarCallback(type: NavbarCallbackType) {
    switch (type) {
      case "undo":
        this.updateStackState("undo")
        break
      case "redo":
        this.updateStackState("redo")
        break
      case "save":
        this.saveChanges()
        break
      case "exit":
        if (!this.state.isEdit) {
          this.state.isOverlayOpen ? this.closeOverlay() : this.openOverlay()
        } else {
          this.setState({ isExitEditorButtonClicked: true })
        }
        break
      case "run-simulation":
        this.toggleRunSimulationBtn()
        break
    }
  }

  fitBounds(featureCollection: FeatureCollection) {
    if (featureCollection.features.length > 0) {
      const bounds: any = bbox(featureCollection)
      this.map.fitBounds(bounds, {
        padding: 100,
      })
    }
  }

  updateLocale() {
    this.forceUpdate()
  }

  getOperationButtons() {
    return [
      {
        label: intl.get("button.edit_existing_route_btn"),
        action: () => this.handleOperationModeSelection("edit_mode"),
        iconClass: "fa-pen",
      },
      {
        label: intl.get("button.draw_new_route_btn"),
        action: () => this.handleOperationModeSelection("add_mode"),
        iconClass: "fa-wave-square",
      },
      {
        label: intl.get("button.extend_existing_route_btn"),
        action: () => this.handleOperationModeSelection("extend_existing_mode"),
        iconClass: "fa-arrow-trend-up",
        condition: () => this.state.selectedNetworkType?.name !== "bike",
      },
    ]
  }

  getEditorOptionButtons() {
    return [
      {
        label: intl.get("button.no"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.setState({ isExitEditorButtonClicked: false })
        },
      },
      {
        label: intl.get("button.yes"),
        buttonClass: "submitModalButton",
        action: () => {
          this.exitEditMode()
        },
      },
    ]
  }

  getCloseProjectButtons() {
    return [
      {
        label: intl.get("button.no"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.setState({ closeProjectConfirmationModalOpen: false })
        },
      },
      {
        label: intl.get("button.yes"),
        buttonClass: "submitModalButton",
        action: () => {
          this.ProjectSelected = false
          this.ProjectSelectedName = ""
          window.location.assign("/editor")
        },
      },
    ]
  }

  getInvalidProjectIdButtons() {
    return [
      {
        label: intl.get("button.back_to_editor"),
        buttonClass: "submitModalButton",
        action: () => {
          this.setState({
            openInvalidProjectIdPopup: false,
          })
          window.location.assign("/editor")
        },
      },
    ]
  }

  getOrphanFeaturesPopupButtons() {
    return [
      {
        label: intl.get("button.ok"),
        buttonClass: "submitModalButton",
        action: () => {
          this.setState({
            isOrphanFeatures: false,
          })
        },
      },
    ]
  }

  getAllUserProjects() {
    const userId = this.appContext.user.id
    const data = {
      userId: userId,
    }
    getAllUserProjects(data)
      .then((res) => {
        this.setState(
          {
            userProjects: res.data.reverse(),
          },
          () => {
            const { setProjectSelectionBtn, setRunSimulationBtn } =
              this.appContext
            setProjectSelectionBtn(this.getProjectSelectionBtn())
            setRunSimulationBtn(this.getRunSimulationBtn())
          }
        )
      })
      .catch((err) => {
        console.error("Error:", err)
      })
  }

  validateProjectId(projectId) {
    const userId = this.appContext.user.id
    const data = {
      userId: userId,
    }
    return getAllUserProjects(data)
      .then((res) => {
        const isProjectExist = res.data.some(
          (project) => project.id === projectId
        )
        return isProjectExist
      })
      .catch((err) => {
        console.error("Error:", err)
        return false
      })
  }

  closeProjectSelectionModal() {
    this.setState({
      openProjectSelectionModal: false,
    })
  }

  getConfirmationSaveButtons() {
    return [
      {
        label: "Cancel & Procced",
        buttonClass: "cancelModalButton",
        action: () => {
          this.setState({
            savingConfirmationModalOpen: false,
            isRunStimulationModalOpen: true,
          })
        },
      },
      {
        label: "Save & Procced",
        buttonClass: "submitModalButton",
        action: () => {
          this.saveAndRunSimulation()
        },
      },
    ]
  }

  async saveAndRunSimulation() {
    await this.saveChanges()
    this.setState({
      savingConfirmationModalOpen: false,
      isRunStimulationModalOpen: true,
    })
  }

  openCloseProjectConfirmationPopup() {
    this.setState({
      closeProjectConfirmationModalOpen: true,
    })
  }

  render() {
    const {
      selectedNetworkType,
      isOperationModeModalOpen,
      mismatchedCoordinates,
      showCombineFeaturesWithStopAlert,
    } = this.state

    return (
      <div className="">
        <div ref={this.mapContainer} className="map-container"></div>
        {!this.state.mapRendered && !this.state.openInvalidProjectIdPopup ? (
          <Loader show={!this.state.mapRendered}></Loader>
        ) : (
          ""
        )}
        {this.ProjectSelected ? (
          <div className="projectNameBadge">
            <p className="projectName" title={this.ProjectSelectedName}>
              {this.ProjectSelectedName}
            </p>
            <i
              className="fa-regular fa-circle-xmark ms-2 cursor-pointer"
              onClick={this.openCloseProjectConfirmationPopup}
            ></i>
          </div>
        ) : (
          ""
        )}
        <div className="legendButtons">
          <List
            values={this.state.networkTypes}
            onChange={this.onListChange}
            renderList={({ children, props, isDragged }) => (
              <ul
                style={{
                  cursor: isDragged ? "grabbing" : "inherit",
                  pointerEvents: this.state.isEdit ? "none" : "inherit",
                }}
                {...props}
              >
                {children}
              </ul>
            )}
            renderItem={({ value, props, isDragged, index }) => (
              <li
                style={{
                  cursor: isDragged ? "grabbing" : "inherit",
                }}
                className="list"
                {...props}
              >
                {!this.state.isEdit ? (
                  <div className="iconDiv" data-movable-handle>
                    <i
                      style={{
                        cursor: isDragged ? "grabbing" : "grab",
                      }}
                      tabIndex={-1}
                      className={`fa-solid ${
                        index === 0
                          ? "fa-arrow-down"
                          : index === this.state.networkTypes.length - 1
                            ? "fa-arrow-up"
                            : "fa-arrows-up-down"
                      }`}
                    ></i>
                  </div>
                ) : null}
                <Tippy
                  key={value.name}
                  content={
                    <span className={"tooltipButtons"}>
                      <button
                        onClick={() => this.toggleVisibilityForNetwork(value)}
                      >
                        {value.visible ? (
                          <>
                            {" "}
                            <i className="fa-solid fa-eye-slash"></i>
                            {intl.get("common.hide")}
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-eye"></i>
                            {intl.get("common.show")}
                          </>
                        )}
                      </button>
                      {!this.state.isEdit ? (
                        <button onClick={() => this.handleToggleEditing(value)}>
                          <>
                            <i className="fa-solid fa-pen"></i>
                            {intl.get("common.edit")}
                          </>
                        </button>
                      ) : null}
                    </span>
                  }
                  placement={"right"}
                  trigger={"mouseenter click"}
                  interactive={true}
                  interactiveBorder={10}
                  className="legend-btn-tooltip"
                >
                  <button
                    key={`${value.name}-legend`}
                    id={`${value.name}-legend`}
                    style={{
                      borderColor:
                        value.name === this.state.selectedNetworkType?.name
                          ? value.color
                          : value.visible
                            ? value.color
                            : "#393939",
                    }}
                    className={`legendButton ${
                      value.name === this.state.selectedNetworkType?.name
                        ? "active"
                        : ""
                    }`}
                  >
                    <i
                      className={`fa-solid ${value.networkTypeIcon}`}
                      style={{ color: value.color, paddingBottom: "6px" }}
                    ></i>
                    {intl.get(`network_type.${value.name}`)}
                  </button>
                </Tippy>
              </li>
            )}
          />
        </div>

        <Modal
          open={isOperationModeModalOpen}
          onClose={this.closeOperationModal}
          center={true}
          closeOnEsc={true}
          closeOnOverlayClick={false}
          styles={{
            modal: {
              background: "#323232",
              color: "#fff",
              width: "25%",
              padding: 0,
              borderRadius: "4px",
            },
            closeButton: { top: 10, right: 5, fill: "white" },
          }}
        >
          <div className="modal-header">
            <span>{intl.get("editor.select_mode")}</span>
          </div>
          <div className="modal-body">
            {this.getOperationButtons()
              .filter((button) =>
                button.condition ? button.condition() : true
              )
              .map((button) => (
                <button
                  className="operation-modal-button"
                  name={button.label}
                  key={button.label}
                  onClick={button.action}
                >
                  <i className={`fa-solid ${button.iconClass}`}></i>
                  <div className="operation-modal-button-text">
                    <span>{button.label}</span>
                  </div>
                </button>
              ))}
          </div>
        </Modal>
        {this.state.operationMode && this.state.selectedNetworkType ? (
          <SideActionWindowView
            selectedNetworkType={this.state.selectedNetworkType}
            currentSelection={this.state.currentSelection}
            operationMode={this.state.operationMode}
            selectedRoute={this.state.selectedRoute}
            callback={this.handleCallback}
            isHelpBtnClicked={this.state.isHelpBtnClicked}
            isRouteLevelSelection={
              this.state.selectedNetworkType &&
              this.state.selectedNetworkType.name !== "bike"
                ? this.state.isRouteLevelSelection
                : false
            }
            draw={this.draw}
            routeFeatures={this.state.routeFeatures}
          />
        ) : (
          ""
        )}
        {(this.state.operationMode === "edit_mode" ||
          this.state.operationMode === "extend_existing_mode") &&
        !this.state.isRouteSelected &&
        selectedNetworkType?.name !== "bike" ? (
          <RouteSelector
            map={this.map}
            draw={this.draw}
            selectedNetworkType={selectedNetworkType}
            onSelection={this.handleRouteSelection}
            operationMode={this.state.operationMode}
          />
        ) : null}

        <ConfirmationModal
          isModalOpen={this.state.isExitEditorButtonClicked}
          buttons={this.getEditorOptionButtons()}
          message={intl.get("editor.confirm_exit")}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>

        {this.state.isEdit ? (
          // eslint-disable-next-line react/jsx-no-undef
          <MouseTooltip
            className={"mouseTooltip"}
            visible={false}
            offsetX={15}
            offsetY={10}
          >
            <span></span>
          </MouseTooltip>
        ) : null}

        <Tippy
          key={intl.get("button.recenter")}
          content={intl.get("button.recenter")}
          placement={"left"}
          trigger={"mouseenter"}
        >
          <button
            className="recenter-map-button"
            onClick={this.onRecenterMapButtonClick}
          >
            <i className="fa-regular fa-compass"></i>
          </button>
        </Tippy>

        <RunStimulationPopup
          isModalOpen={this.state.isRunStimulationModalOpen}
          header={intl.get("editor.run_simulation")}
          closeRunSimulationPopup={this.closeRunSimulationBtn}
          network={this.props.network}
          networkData={this.state.networkData}
          resetNetwork={this.fetchNetworkData}
        ></RunStimulationPopup>

        <ConfirmationModal
          isModalOpen={
            mismatchedCoordinates || showCombineFeaturesWithStopAlert
          }
          buttons={
            showCombineFeaturesWithStopAlert
              ? this.alertModalWithStopsButtons
              : this.alertModalButtons
          }
          message={
            showCombineFeaturesWithStopAlert
              ? intl.get("editor.combine_feat_confirmatn_msg")
              : intl.get("editor.combine_feat_error_msg")
          }
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
        <ConfirmationModal
          isModalOpen={this.state.isOrphanFeatures}
          buttons={this.getOrphanFeaturesPopupButtons()}
          message={intl.get("disconnected_features_msg")}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
        <ProjectSelectionModal
          isModalOpen={this.state.openProjectSelectionModal}
          closeModal={this.closeProjectSelectionModal}
          projectList={this.state.userProjects}
        ></ProjectSelectionModal>
        <ConfirmationModal
          isModalOpen={this.state.savingConfirmationModalOpen}
          buttons={this.getConfirmationSaveButtons()}
          message={"There are unsaved changes. Do you want to save them?"}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
        <ConfirmationModal
          isModalOpen={this.state.closeProjectConfirmationModalOpen}
          buttons={this.getCloseProjectButtons()}
          message={intl.get("editor.exit_project_confirmatn_msg")}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
        <ConfirmationModal
          isModalOpen={this.state.openInvalidProjectIdPopup}
          buttons={this.getInvalidProjectIdButtons()}
          message={intl.get("alert.invalid_url")}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
      </div>
    )
  }
}
