import React from "react"
import App from "../pages/App"
import Antwerp from "../specs/networks/antwerp_network_stop_bike"
import Editor from "../pages/Editor"
import { Route } from "react-router-dom"
import { Paths } from "./paths"
import MapboxCanvas from "../pages/MapboxCanvas"

export const EditorRoutes = (
  <>
    <Route path={Paths.editor} element={<App />}>
      <Route
        index
        element={
          <Editor
            network={new Antwerp()}
            lng={4.3972447973521}
            lat={51.233981603036945}
            zoom={9}
          />
        }
      ></Route>
    </Route>
    <Route
      path={"/editor/report"}
      element={
        <MapboxCanvas
          network={new Antwerp()}
          lng={4.3972447973521}
          lat={51.233981603036945}
          zoom={9}
        />
      }
    />
  </>
)
